import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import {
  faBuilding,
  faGlobe,
  faUser,
  faUsers,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const iconMapping = new Map();

iconMapping.set('Undefined', 'exclamation-triangle');
iconMapping.set('User', 'user');
iconMapping.set('Group', 'users');
iconMapping.set('Company', 'building');
iconMapping.set('Global', 'globe');

library.add(faExclamationTriangle, faBuilding, faGlobe, faUser, faUsers);

const styles = theme => ({
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 14,
    maxWidth: 480,
    minWidth: 100,
    minHeight: 50,
    padding: '15px',
  },
});

class ConfigDefinedComponent extends Component<any, {}> {
  state = {
    tooltipOpen: false,
  };

  handleTooltipClose = () => {
    this.setState({ tooltipOpen: false });
  };

  handleTooltipOpen = () => {
    this.setState({ tooltipOpen: true });
  };

  render() {
    const { definedIn, classes } = this.props;
    let iconUsed = iconMapping.get('Undefined');

    let actualTitle = 'Never defined';
    let colourString = 'orange';

    if (definedIn !== 'Undefined') {
      actualTitle = 'Already defined in the ' + `${definedIn}` + ' level';
      colourString = 'black';
    }

    if (iconMapping.has(definedIn)) {
      iconUsed = iconMapping.get(definedIn);
    }

    return (
      <ClickAwayListener onClickAway={this.handleTooltipClose}>
        <Tooltip
          classes={{
            tooltip: classes.lightTooltip,
          }}
          title={
            <>
              <span>{`${actualTitle}`}</span>
            </>
          }
          open={this.state.tooltipOpen}
          placement="right"
          disableFocusListener
          disableHoverListener
          disableTouchListener>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '10px',
            }}
            onClick={this.handleTooltipOpen}>
            <FontAwesomeIcon icon={iconUsed} color={colourString} />
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(ConfigDefinedComponent);
