import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const ErrorMessageContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const ErrorMessageText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  font-weight: bold;
`;

const ErrorMessage = function(props) {
  return (
    <ErrorMessageContainer>
      <img
        src="https://media.giphy.com/media/2JUwr3tnfiQJa/giphy.gif"
        alt="Tumbleweed"
      />
      <ErrorMessageText>
        <div>
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ fontSize: 30, marginRight: 15, color: '#bb0000' }}
          />
        </div>
        <div>{props.errorMessage}</div>
      </ErrorMessageText>
    </ErrorMessageContainer>
  );
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.errorMessage,
};

export default ErrorMessage;
