import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const ButtonSelectorStyle = styled.label`
  flex: 1;
  margin-right: 5px;

  ${props =>
    props.checked &&
    css`
      background-color: #337ab7;
      color: white;
    `};
`;

export default function ButtonSelector(props) {
  return (
    <ButtonSelectorStyle
      checked={props.checked}
      className="btn btn-default"
      htmlFor={props.title}>
      <div className="itemcontent">
        <input
          style={{ display: 'none' }}
          id={props.title}
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange}
        />
        {props.children}
      </div>
    </ButtonSelectorStyle>
  );
}

ButtonSelector.propTypes = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.any,
};
