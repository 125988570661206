import { createSelector } from 'reselect';

const getCompanyList = ({ configSelection }) => configSelection.companyList;

// Returns the companyList from the provided configuration, translated from company-id format into value-label format.
export default createSelector([getCompanyList], companyList => {
  return companyList.map((company, id) => ({
    value: company.companyId,
    label: company.companyName,
  }));
});
