import React from 'react';
import { connect } from 'react-redux';
import { uploadFile, uploadMultipleFiles } from '../_ducks/uploadFile';
import { deleteFile } from '../_ducks/deleteFile';
import { changeFileType } from '../_ducks/changeFileType';
import { Grid, Cell } from 'styled-css-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { _ } from 'lodash';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { filterS3ObjectsByFolder } from '../utils/filterS3ObjectsByFolder';

const s3UrlPrefix = configApp.configuration.assetsBucketUrl;
const s3Label = configApp.configuration.assetsBucketLabel;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export class UploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  async handleSingleFileUpload(fileInputRef, iressAssetsS3Objects) {
    const file = fileInputRef.files[0];
    const fileName = `${this.props.selectedFileType}/${
      fileInputRef.files[0].name
    }`;
    let overwriteFile = true;

    _.each(iressAssetsS3Objects, s3Object => {
      if (s3Object.Key === fileName) {
        if (!confirm(`Overwrite "${s3Object.Key}"?`)) {
          overwriteFile = false;
        }
      }
    });

    if (overwriteFile) {
      const fileAsBase64 = await getBase64(file);
      this.props.uploadFile({
        fileName,
        fileAsBase64,
      });
    } else {
      console.log('Did not upload.');
    }
  }

  async handleMultiFileUpload(fileInputRef, iressAssetsS3Objects) {
    const filesToUpload = [];
    const files = fileInputRef.files;
    for (let i = 0; i < files.length; i++) {
      filesToUpload.push({
        fileName: `${this.props.selectedFileType}/${files[i].name}`,
        file: files[i],
      });
    }
    let needToConfirmOverwrite = false;
    _.each(iressAssetsS3Objects, s3Object => {
      if (
        _.some(filesToUpload, fileToUpload => {
          return fileToUpload.name === s3Object.key;
        })
      ) {
        needToConfirmOverwrite = true;
      }
    });
    if (needToConfirmOverwrite) {
      if (
        !confirm(
          'Some of the files you have chosen will overwrite existing files. Continue?'
        )
      ) {
        console.log('Did not upload.');
        return;
      }
    }
    let filesAsBase64 = [];
    for (const fileToUpload of filesToUpload) {
      const fileAsBase64 = await getBase64(fileToUpload.file);
      filesAsBase64.push({ fileName: fileToUpload.fileName, fileAsBase64 });
    }

    this.props.uploadMultipleFiles(filesAsBase64);
  }

  async onSubmit(e, uploadFile, fileInputRef, iressAssetsS3Objects) {
    e.preventDefault();
    if (fileInputRef.files.length > 1) {
      this.handleMultiFileUpload(fileInputRef, iressAssetsS3Objects);
    } else if (fileInputRef.files.length === 1) {
      this.handleSingleFileUpload(fileInputRef, iressAssetsS3Objects);
    } else {
      alert('Please select a file first');
    }
  }

  render() {
    const cellWidths = {
      fileName: 2,
      size: 1,
      lastModified: 1,
      url: 7,
      delete: 1,
    };

    return (
      <div>
        <h3>{s3Label} Bucket</h3>
        <div>
          <InputLabel
            id="file-type-label"
            htmlFor="file-type"
            style={{ marginRight: '5px' }}>
            File Type:
          </InputLabel>
          <Select
            style={{ marginBottom: '5px' }}
            value={this.props.selectedFileType}
            onChange={event => {
              this.props.changeFileType(event.target.value);
            }}
            inputProps={{ id: 'file-type' }}>
            <MenuItem value={'CompanyLogos'}>Company Logos</MenuItem>
            <MenuItem value={'Flags'}>Flags</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </div>
        <form
          onSubmit={e => {
            this.onSubmit(
              e,
              this.props.uploadFile,
              this.fileInputRef.current,
              this.props.iressAssetsS3Objects
            );
          }}
          style={{ display: 'inline-block' }}>
          <input
            name="file"
            ref={this.fileInputRef}
            type="file"
            multiple="multiple"
          />

          <button style={{ marginTop: '10px' }}>Upload File</button>
        </form>

        {this.props.currentlyUploadingFile && (
          <span>
            <progress style={{ marginRight: '10px' }} />
            <span>{`Uploading File: ${
              this.props.currentlyUploadingFile
            }...`}</span>
          </span>
        )}
        <Grid columns={12} gap="2px" style={{ paddingTop: '10px' }}>
          <Cell width={cellWidths.fileName}>
            <b>File Name</b>
          </Cell>
          <Cell width={cellWidths.size}>
            <b>Size</b>
          </Cell>
          <Cell width={cellWidths.lastModified}>
            <b>Last modified</b>
          </Cell>
          <Cell width={cellWidths.url}>
            <b>URL</b>
          </Cell>
          <Cell />
          {filterS3ObjectsByFolder(
            this.props.iressAssetsS3Objects,
            this.props.selectedFileType
          ).map(s3Object => {
            const imageUrl = `${s3UrlPrefix}${s3Object.Key}`;
            return (
              <React.Fragment key={s3Object.Key}>
                <Cell
                  width={cellWidths.fileName}
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {s3Object.Key}
                </Cell>

                <Cell style={{ width: '10px' }} width={cellWidths.size}>
                  {s3Object.Size * 0.001}
                  KB
                </Cell>
                <Cell width={cellWidths.lastModified}>
                  {new Date(s3Object.LastModified).toLocaleString()}
                </Cell>
                <Cell
                  width={cellWidths.url}
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <a
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    href={imageUrl}>
                    {imageUrl}
                  </a>
                </Cell>
                <Cell width={cellWidths.delete}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => {
                      if (confirm(`Delete "${s3Object.Key}"?`)) {
                        this.props.deleteFile(s3Object.Key);
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Cell>
              </React.Fragment>
            );
          })}
        </Grid>
      </div>
    );
  }
}

UploadImage.propTypes = {
  currentlyUploadingFile: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  uploadMultipleFiles: PropTypes.func.isRequired,
  changeFileType: PropTypes.func.isRequired,
  iressAssetsS3Objects: PropTypes.array.isRequired,
  selectedFileType: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    currentlyUploadingFile: state.uploadFile.currentlyUploadingFile,
    iressAssetsS3Objects: state.uploadFile.iressAssetsS3Objects,
    selectedFileType: state.uploadFile.selectedFileType,
  };
};

const mapDispatchToProps = {
  uploadFile: uploadFile,
  uploadMultipleFiles: uploadMultipleFiles,
  deleteFile: deleteFile,
  changeFileType: changeFileType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadImage);
