import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormContainer } from '../Form';
import { configSelectionActions } from '../../_ducks/configSelection';
import ProgressDots from '../Common/ProgressDots';
import ErrorMessage from '../Common/ErrorMessage';
import { isNil } from 'lodash';
import configSingleton from '../../_ducks/configSingleton';

export class EditPage extends Component {
  constructor(props) {
    super(props);
    this.beforeUnload = this.beforeUnload.bind(this);
  }

  beforeUnload(event) {
    if (this.props.hasChanges) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  UNSAFE_componentDidMount() {
    window.addEventListener('beforeunload', this.beforeUnload);
  }

  UNSAFE_componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  componentWillUpdate(nextProps) {
    if (
      this.props.initialRequestsCompleted === false &&
      nextProps.initialRequestsCompleted === true
    ) {
      const { selectedCompanyId } = this.props;
      if (!selectedCompanyId) {
        const {
          companyId,
          companyName,
          groupId,
          groupName,
          userId,
          userName,
        } = this.props.match.params;
        if (!isNil(groupId)) {
          this.props.loadCompanyAndGroup({
            companyId,
            companyName:
              companyName !== undefined
                ? decodeURIComponent(companyName)
                : companyName,
            groupId,
            groupName:
              groupName !== undefined
                ? decodeURIComponent(groupName)
                : groupName,
          });
        } else if (!isNil(userId)) {
          this.props.loadCompanyAndUser({
            companyId,
            companyName:
              companyName !== undefined
                ? decodeURIComponent(companyName)
                : companyName,
            userId,
            userName:
              userName !== undefined ? decodeURIComponent(userName) : userName,
          });
        }
      }
    }
  }
  render() {
    const {
      errorMessage,
      selectedCompanyId,
      initialRequestsCompleted,
    } = this.props;
    const { groupName, userName } = this.props.match.params;
    if (errorMessage) {
      return <ErrorMessage errorMessage={errorMessage} />;
    }
    if (!initialRequestsCompleted || !selectedCompanyId) {
      return <ProgressDots />;
    }
    var selectedLevel = undefined;
    if (groupName === undefined && userName === undefined) {
      selectedLevel = 'Company';
    } else if (groupName !== undefined) {
      selectedLevel = 'Group';
    } else if (userName !== undefined) {
      selectedLevel = 'User';
    }
    return (
      <FormContainer
        selectedLevel={selectedLevel}
        selectedChildEntityName={groupName || userName}
      />
    );
  }
}

EditPage.propTypes = {
  errorMessage: PropTypes.string,
  initialRequestsCompleted: PropTypes.bool,
  selectedCompanyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  match: PropTypes.object,
  hasChanges: PropTypes.bool,
  loadCompanyAndGroup: PropTypes.func,
  loadCompanyAndUser: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    selectedCompanyId,
    selectedGroupId,
    selectedUserId,
  } = state.configSelection;
  const errorMessage = configSingleton.getErrorMessage();
  const { initialRequestsCompleted } = state.app;
  const { hasChanges } = state.configData;
  return {
    errorMessage,
    initialRequestsCompleted,
    selectedCompanyId,
    selectedGroupId,
    selectedUserId,
    hasChanges,
  };
}

const mapDispatchToProps = {
  loadCompanyAndGroup: configSelectionActions.loadCompanyAndGroup,
  loadCompanyAndUser: configSelectionActions.loadCompanyAndUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPage);
