import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  resize: {
    fontSize: 16,
  },
  textField: {
    width: 250,
  },
});

export class Number extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
    };
  }

  onChange = event => {
    const { propKey, onConfigChanged, properties } = this.props;
    const { numberPrecision } = properties[propKey];

    // setting the value or null to clear out the field
    if (event.target.value === '') {
      onConfigChanged({ [propKey]: null });
    } else {
      let value = 0;
      if (numberPrecision !== undefined) {
        value = parseFloat(
          parseFloat(event.target.value).toFixed(numberPrecision)
        );
      } else {
        value = parseInt(event.target.value, 10);
      }

      onConfigChanged({ [propKey]: value });
    }

    this.setState({ changed: true });
  };

  validateInput = input => {
    const { propKey, properties } = this.props;
    const { minimum, maximum } = properties[propKey];
    const { changed } = this.state;
    const error =
      input < minimum ||
      input > maximum ||
      ((minimum != undefined || maximum != undefined) &&
        (changed && input === null)); // if input is out of range or there's no input
    const errorMessage = error
      ? minimum != undefined && maximum != undefined
        ? 'Please enter a value between ' + minimum + ' and ' + maximum
        : minimum != undefined
          ? 'Input must be no smaller than ' + minimum
          : 'Input must be no greater than ' + maximum
      : null;

    return { error, errorMessage };
  };

  render() {
    const { classes, mergedConfig, propKey, properties } = this.props;
    const { minimum, maximum } = properties[propKey];

    const { error, errorMessage } = this.validateInput(mergedConfig[propKey]);
    // ask Form to display the error message
    if (error) {
      this.props.addError(propKey, errorMessage);
    } else {
      this.props.clearError(propKey);
    }

    return (
      <div style={{ marginLeft: 10 }}>
        <TextField
          error={error}
          type="number"
          className={classes.textField}
          InputProps={{
            inputProps: { min: minimum || 0, max: maximum, step: 'any' },
            classes: {
              input: classes.resize,
            },
          }}
          value={mergedConfig[propKey] === null ? '' : mergedConfig[propKey]}
          onChange={this.onChange}
          helperText={errorMessage}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Number);
