import { createSelector } from 'reselect';

const getUserList = ({ configSelection }) => configSelection.userList;

// Returns the userList from the provided configuration, translated from user-id format into value-label format.
export default createSelector([getUserList], userList => {
  return userList.map((user, id) => ({
    value: user.userId,
    label: user.userName,
  }));
});
