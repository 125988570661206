import { createSelector } from 'reselect';

interface Item {
  id: number;
  name?: string;
  add?: any;
  del?: any;
  groups?: Array<Item>;
  users?: Array<Item>;
}

const toItem = (obj): Item => ({
  id: parseInt(obj.value, 10),
  name: obj.label,
});

export default createSelector(
  [({ configSelection }) => configSelection, ({ configData }) => configData],
  (configSelection, configData) => {
    const {
      selectedCompanyId,
      selectedCompanyName,
      selectedGroupId,
      selectedGroupName,
      selectedUserId,
      selectedUserName,
    } = configSelection;

    const { modifiedConfig, sideEffectConfig, deletedConfig } = configData;

    const additionalCompaniesGroupsOrUsers = configSelection.additionalCompaniesGroupsOrUsers.map(
      toItem
    );

    const add = { ...modifiedConfig, ...sideEffectConfig };
    const del = Object.keys(deletedConfig);

    const company: Item = {
      id: parseInt(selectedCompanyId, 10),
      name: selectedCompanyName,
    };

    // if a group/user is selected, additionalCompaniesGroupsOrUsers
    // will only have a list of groups/users
    if (selectedUserId > 0) {
      company.users = [
        ...additionalCompaniesGroupsOrUsers.map(x => {
          return { id: x.id };
        }),
        {
          id: parseInt(selectedUserId, 10),
        },
      ].map(comp => ({ ...comp, add, del }));
      return [company];
    } else if (selectedGroupId && selectedGroupName) {
      company.groups = [
        ...additionalCompaniesGroupsOrUsers,
        {
          id: parseInt(selectedGroupId, 10),
          name: selectedGroupName,
        },
      ].map(comp => ({ ...comp, add, del }));
      return [company];
    } else {
      const allCompanies = [company].concat(additionalCompaniesGroupsOrUsers);
      return allCompanies.map(comp => ({ ...comp, add, del }));
    }
  }
);
