import styled from 'styled-components';

export const ConfigRow: any = styled.div`
  padding: 5px 20px;
  background-color: ${(props: any) =>
    props.isModified ? '#f6e58d' : 'inherit'}

  &:hover {
    background-color: ${(props: any) => (props.isModified ? '#ffeaa7' : '#eee')}
  }
`;

export const ConfigRowContainer: any = styled(ConfigRow)`
  display: flex;
  align-items: center;
`;

export const RowLabel = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
`;
