import React, { Fragment } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme =>
  createStyles({
    selectBox: {
      fontSize: 12,
      minWidth: 250,
      textAlign: 'right',
    },
    inputLabel: {
      fontSize: 14,
    },
  });

export function SelectComponent(props) {
  const { classes, inputLabel } = props;
  const menuItems =
    props.menuItems ||
    props.list.map(item => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  if (props.emptyOption) {
    menuItems.unshift(
      <MenuItem key="__none" value="">
        <em>None</em>
      </MenuItem>
    );
  }
  const FormControlWrapper = inputLabel ? FormControl : Fragment;
  return (
    <FormControlWrapper>
      {inputLabel ? (
        <InputLabel htmlFor={inputLabel.htmlFor} className={classes.inputLabel}>
          {inputLabel.text}
        </InputLabel>
      ) : null}
      <Select
        className={classes.selectBox}
        value={props.value}
        onChange={props.onChange}
        inputProps={
          inputLabel
            ? {
                name: inputLabel.inputProps.name,
                id: inputLabel.inputProps.id,
              }
            : undefined
        }>
        {menuItems}
      </Select>
    </FormControlWrapper>
  );
}

SelectComponent['defaultProps'] = {
  list: [],
};

export default withStyles(styles)(SelectComponent);
