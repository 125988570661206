import React from 'react';
import ReviewSettingsTableContainer from './ReviewSettingsTableContainer';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CompanyGroupHeading from '../Common/CompanyGroupHeading';
import AddCompanyGroupOrUser from './AddCompanyGroupOrUser';
import AdditionalReviewDetails from './AdditionalReviewDetails';

const ReviewPage = ({
  history,
  match,
  applyConfig,
  selectedCompanyName,
  selectedCompanyId,
  selectedGroupName,
  selectedGroupId,
  selectedUserName,
  selectedUserId,
}) => {
  const { companyId, companyName, groupId, groupName } = match.params;
  let urlParams = `${companyId}/${companyName}`;
  if (groupId !== undefined && groupName !== undefined) {
    urlParams += `/${groupId}/${groupName}`;
  }
  if (!selectedCompanyId) {
    return <Redirect to={`/edit/${urlParams}`} />;
  }
  return (
    <div className="container">
      <CompanyGroupHeading
        selectedCompanyName={selectedCompanyName}
        selectedGroupName={selectedGroupName || selectedUserName}
      />
      <AdditionalReviewDetails />
      <ReviewSettingsTableContainer />
      <AddCompanyGroupOrUser />
      <div style={{ textAlign: 'right' }}>
        <button
          className="btn btn-basic"
          data-testid="back-to-edit-button"
          onClick={() => {
            navigateToEdit(history, urlParams);
          }}>
          Back to Edit
        </button>
        <button
          className="btn btn-primary"
          style={{ marginLeft: 10 }}
          onClick={() => confirm(history, applyConfig)}>
          Update
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { configSelection } = state;
  const {
    selectedApp,
    selectedCompanyName,
    selectedCompanyId,
    selectedGroupName,
    selectedGroupId,
    selectedUserName,
    selectedUserId,
  } = configSelection;

  return {
    selectedApp,
    selectedCompanyId,
    selectedCompanyName,
    selectedGroupId,
    selectedGroupName,
    selectedUserId,
    selectedUserName,
  };
};

export default connect(mapStateToProps)(ReviewPage);

function navigateToEdit(history, urlParams) {
  history.push(`/edit/${urlParams}`);
}

function confirm(history, applyConfig) {
  const confirmResult = window.confirm(
    'Are you sure you want to apply this configuration?'
  );
  if (confirmResult === true) {
    applyConfig();
    history.push('/track-progress');
    alert(
      'Your config change is being processed, and should be available for tracking in 15 seconds.'
    );
  }
}
