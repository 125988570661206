import { Component } from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { actions } from '../_ducks/auth';

class HandleAuth extends Component {
  async componentDidMount() {
    const { auth, onAuth } = this.props;

    if (await auth.isAuthenticated()) {
      const idToken = await auth._oktaAuth.tokenManager.get('idToken');
      onAuth({ idToken });
    }
  }

  render() {
    return null;
  }
}

HandleAuth.propTypes = {
  auth: PropTypes.object.isRequired,
  onAuth: PropTypes.func.isRequired,
};

export default connect(
  null,
  { onAuth: actions.authResult }
)(withAuth(HandleAuth));
