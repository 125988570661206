import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';

import { configModalActions } from '../../_ducks/configModal';

const FileUploadContainer = styled.div`
  width: 250px;
  display: flex;
  margin-left: 20px;
`;

export class FileUpload extends React.Component {
  onEditClick() {
    const {
      cachedImages,
      modifiedConfig,
      configName,
      toggleConfigModal,
    } = this.props;
    const formData = { id: configName, imageData: cachedImages[configName] };
    formData.fileUrl = modifiedConfig[configName];
    toggleConfigModal({
      showConfigModal: true,
      modalContent: 'File Upload',
      formData,
    });
  }
  render() {
    const { classes, name } = this.props;
    let fileName = name;
    if (name) {
      const urlComponents = name.split('/');
      fileName = urlComponents[urlComponents.length - 1];
    }
    return (
      <FileUploadContainer>
        <div className="file-upload-filename" style={{ flex: 1, fontSize: 16 }}>
          {fileName}
        </div>
        <EditIcon className={classes.size} onClick={() => this.onEditClick()} />
      </FileUploadContainer>
    );
  }
}

FileUpload.propTypes = {
  cachedImages: PropTypes.object,
  modifiedConfig: PropTypes.object,
  configName: PropTypes.string,
  classes: PropTypes.object,
  name: PropTypes.string,
  toggleConfigModal: PropTypes.func,
};

const styles = theme => ({
  size: {
    height: 20,
    width: 25,
    cursor: 'pointer',
  },
});

const mapStateToProps = state => {
  const { cachedImages } = state.uploadFile;
  const { modifiedConfig } = state.configData;
  return { cachedImages, modifiedConfig };
};

const mapDispatchToProps = {
  toggleConfigModal: configModalActions.toggleConfigModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FileUpload));
