import React from 'react';
import Form from 'react-jsonschema-form';
import { connect } from 'react-redux';
import Category from './Category';
import { Search } from '../Common/Search';
import styled from 'styled-components';
import { includes, isEqual, isEmpty, remove, throttle, get } from 'lodash';
import EntityHeading from '../Common/CompanyGroupHeading';

import { configDataActions } from '../../_ducks/configData';
import schemaSelector from '../../_selectors/schemaSelector';
import ExpandCollapseButton from './ExpandCollapseButton';
import ResetAllButton from './ResetAllButton';
import ImportButton from './ImportButton';
import ExportButton from './ExportButton';

const apps = require('../../../../../products.json');

const SubmitButton = styled.button`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: 20px;
`;

const SubmitButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 20px;
  z-index: 1;

  background-color: rgb(248, 249, 250);
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 50px;
  left: 0px;
  background-color: rgb(248, 249, 250);
  width: 100%;
  z-index: 1;
  padding-bottom: 20px;
`;

const fields: any = { custom: Category };
const accessDeniedMessage = (
  <p>
    This company or group cannot be modified because it is used as an Investor
    or HTMLIress replacement. Please contact a ViewPoint product owner to
    discuss changes to this company or group.
  </p>
);

export class FormComponent extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      categoryKeys: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const categories = props.schema.properties;

    // Bump promotedCategories to the top
    const promotedCategories = get(
      apps,
      [props.selectedApp, 'ui', 'promotedCategories'],
      []
    );
    return {
      ...state,
      categoryKeys: Array.from(
        new Set([...promotedCategories, ...Object.keys(categories)])
      ),
    };
  }

  addError = (setting, errorMessage) => {
    // only call setState if error changes
    if (this.state.errors[setting] !== errorMessage) {
      const newErrors = Object.assign({}, this.state.errors);
      newErrors[setting] = errorMessage;
      this.setState({ errors: newErrors });
    }
  };

  clearError = setting => {
    if (this.state.errors[setting]) {
      const newErrors = { ...this.state.errors };
      delete newErrors[setting];
      this.setState({ errors: newErrors });
    }
  };

  hasErrors = () => {
    return (
      Object.keys({ ...this.state.errors, ...this.props.errors }).filter(
        key => this.props.flatSchema[key]
      ).length > 0
    );
  };

  searchChanged = throttle(value => {
    this.props.onSearchTextChanged(value);
  }, 100);

  resetConfig = () => {
    this.props.onResetConfig();
  };

  collapseCategories = () => {
    const categories = Object.keys(this.props.schema.properties);
    this.props.onCollapseAll(categories);
  };

  expandCategories = () => {
    const categories = Object.keys(this.props.schema.properties);
    this.props.onExpandAll(categories);
  };

  render() {
    // When switching the product selector in the edit page,
    // show a loading indicator if schema is not available
    if (!this.props.schema) {
      return <div>Loading...</div>;
    }

    const categories = this.props.schema.properties;
    const { categoryKeys } = this.state;

    const hasError = this.hasErrors();
    const isReadonly =
      ['HTMLIress', 'Investor'].includes(
        this.props.mergedConfig['Default/siteType']
      ) && !this.props.isTechUser;
    return isReadonly ? (
      accessDeniedMessage
    ) : (
      <div className="container">
        <HeaderContainer>
          <div className="container">
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ flex: 1 }}>
                <EntityHeading
                  selectedCompanyName={this.props.selectedCompanyName}
                  selectedGroupName={this.props.selectedChildEntityName}
                />
              </div>
              <div style={{ textAlign: 'right', display: 'flex' }}>
                <ImportButton
                  style={{ margin: '10px 5px' }}
                  configData={this.props}
                />
                <ExportButton
                  style={{ margin: '10px 5px' }}
                  configData={this.props}
                />
                <ResetAllButton
                  style={{ margin: '10px 5px' }}
                  onResetAll={this.resetConfig.bind(this)}
                />
                <ExpandCollapseButton
                  style={{ margin: '10px 5px' }}
                  onExpand={this.expandCategories.bind(this)}
                  onCollapse={this.collapseCategories.bind(this)}
                  expanded={true}
                />
              </div>
            </div>
            <Search
              value={this.props.searchText}
              onChange={event => this.searchChanged(event.target.value)}
            />
          </div>
        </HeaderContainer>
        <div style={{ marginBottom: 100, marginTop: 135 }}>
          {categoryKeys.map(categoryKey => (
            <Category
              key={categoryKey}
              {...categories[categoryKey]}
              addError={this.addError.bind(this)}
              clearError={this.clearError.bind(this)}
            />
          ))}
        </div>
        <SubmitButtonContainer>
          <div className="container">
            <div style={{ color: 'red' }}>
              {Object.keys({ ...this.state.errors, ...this.props.errors })
                .filter(key => this.state.errors[key] || this.props.errors[key])
                .filter(key => this.props.flatSchema[key])
                .map(key => (
                  <div>
                    {this.props.flatSchema[key].description +
                      ' --- ' +
                      (this.state.errors[key] || this.props.errors[key])}
                  </div>
                ))}
            </div>
            <SubmitButton
              className={
                hasError
                  ? 'btn btn-danger btn-lg btn-block'
                  : 'btn btn-primary btn-lg btn-block'
              }
              type="submit"
              data-testid="edit-page-review-button"
              disabled={!this.props.hasChanges || hasError}
              onClick={() => {
                if (
                  isEmpty(this.props.modifiedConfig) &&
                  isEmpty(this.props.deletedConfig)
                ) {
                  alert(
                    'You must modify at least one setting before reviewing your changes.'
                  );
                } else {
                  this.props.onSubmitConfig();
                }
              }}>
              Review
            </SubmitButton>
          </div>
        </SubmitButtonContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { configData, configSelection } = state;
  const { selectedCompanyName, selectedApp } = configSelection;
  const {
    defaultValues,
    errors,
    formData,
    defaultConfig,
    deletedConfig,
    modifiedConfig,
    searchText,
    hasChanges,
  } = configData;

  return {
    ...schemaSelector(state),
    defaultValues,
    defaultConfig,
    deletedConfig,
    errors,
    formData,
    hasChanges,
    modifiedConfig,
    searchText,
    selectedCompanyName,
    isTechUser: state.auth.isTechUser,
    selectedApp,
  };
};

const mapDispatchToProps = {
  onSubmitConfig: configDataActions.submitConfigSelection,
  onConfigChanged: configDataActions.modifyConfig,
  onResetConfig: configDataActions.resetConfig,
  onSearchTextChanged: configDataActions.changeSearchText,
  onExpandAll: configDataActions.expandAll,
  onCollapseAll: configDataActions.collapseAll,
};

export const FormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default props => <FormContainer {...props} />;
