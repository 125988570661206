import React from 'react';
import Select from 'react-virtualized-select';
import lunr from 'lunr';

import OfflineSearch from '../Common/OfflineSearch';

export interface SelectOption {
  value: string;
  label: string;
}

export default class CompanyGroupSelectComponent extends React.Component<
  Partial<{
    completeData: SelectOption[];
    valueChanged: (value: any) => void;
    name: string;
    isDisabled: boolean;
    selectedName: string;
    selectedId: string;
    placeholder?: string;
    submit?: () => void;
    clearable: boolean;
  }>,
  { isOpen: boolean }
> {
  searcher: OfflineSearch;

  static defaultProps = {
    searchable: true,
    clearable: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.searcher = new OfflineSearch(
      {
        id: 'value',
        fields: ['label'],
        builderOverride: lunrOverride,
        customSort: customSort,
      },
      props.completeData
    );

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidUpdate(nextProps) {
    const entries = nextProps.completeData;
    this.searcher.rebuild(entries);
  }

  onSearch(entries: SelectOption[], text: string): SelectOption[] {
    if (!text.trim()) {
      return entries;
    }

    const results: SelectOption[] = this.searcher.search(text + '*');
    return results;
  }

  onKeyDown(event) {
    if (event.key === 'Enter' && !this.state.isOpen) {
      this.props.submit && this.props.submit();
    }
  }

  toggleOpen = flag => {
    this.setState({ isOpen: flag });
  };

  render() {
    const {
      name,
      isDisabled,
      completeData,
      valueChanged,
      placeholder,
      selectedId,
      selectedName,
      ...restProps
    } = this.props;

    return (
      <Select
        name={name}
        disabled={isDisabled}
        searchable={true}
        clearable={false}
        options={completeData}
        onChange={valueChanged}
        value={
          selectedId
            ? {
                value: selectedId,
                label: selectedName,
              }
            : ''
        }
        placeholder={placeholder}
        onInputKeyDown={this.onKeyDown}
        onOpen={() => this.toggleOpen(true)}
        onClose={() => this.toggleOpen(false)}
        filterOptions={this.onSearch}
        {...restProps}
      />
    );
  }
}

// Stemming is useful when searching for text in general, but
// not in cases where exact matches are needed (like company/group names)
// https://lunrjs.com/guides/core_concepts.html
export function lunrOverride(lunr, builder) {
  builder.pipeline.add(lunr.trimmer);
  builder.searchPipeline.add(lunr.trimmer);
}

// boost exact match's score by 5
export function customSort(text, results, _config) {
  const canonical = text
    .replace(/[^\w\s]/, '')
    .toLowerCase()
    .split(/\s/);

  results.map(result => {
    const keys = Object.keys(result.matchData.metadata);
    if (canonical.every(c => keys.indexOf(c) !== -1)) {
      result.score += 5;
    }
    return result;
  });

  results.sort((a, b) => {
    return b.score - a.score;
  });

  return results;
}

function searchResultBooster(text, config) {
  const positionCache = {};
  const textLength = text.length;

  return function(result) {
    if (!positionCache[result.ref]) {
      const metadata = result.matchData.metadata;
      const key = Object.keys(metadata)[0];
      const pos = metadata[key][config.fields[0]].position[0];
      positionCache[result.ref] = pos[1] - textLength;
    }
    return positionCache[result.ref];
  };
}
