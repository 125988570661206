import { takeEvery, put, select } from 'redux-saga/effects';
import { configDataActions, MODIFY_CONFIG } from '../_ducks/configData';

export default function() {
  return [
    takeEvery(MODIFY_CONFIG, excludedExchangesSaga),
    takeEvery(MODIFY_CONFIG, orderTicketSaga),
  ];
}

function* excludedExchangesSaga(action) {
  const excludedExchangesToggle = [
    'Default/singleCOEnabled',
    'Default/allowVolumeAmendUp',
    'Default/allowPriceAmend',
    'Default/coWithStopLossTakeProfitEnabled',
    'Default/stopLossTakeProfitEnabled',
    'Default/allowLifetimeAmend',
    'Default/allowOrderTypeAmend',
  ];
  const excludedExchangesToggleMap = {
    'Default/singleCOEnabled': 'Default/singleCOExcludedExchanges',
    'Default/allowVolumeAmendUp': 'Default/allowVolumeAmendUpExcludedExchanges',
    'Default/allowPriceAmend': 'Default/allowPriceAmendExcludedExchanges',
    'Default/coWithStopLossTakeProfitEnabled':
      'Default/coWithStopLossTakeProfitExcludedExchanges',
    'Default/stopLossTakeProfitEnabled':
      'Default/stopLossTakeProfitExcludedExchanges',
    'Default/allowLifetimeAmend': 'Default/allowLifetimeAmendExcludedExchanges',
    'Default/allowOrderTypeAmend':
      'Default/allowOrderTypeAmendExcludedExchanges',
  };
  const modifedConfigKeys = Object.keys(action.payload);
  for (let i = 0; i < modifedConfigKeys.length; i++) {
    const config = modifedConfigKeys[i];
    if (excludedExchangesToggle.includes(config)) {
      if (action.payload[config] === false) {
        yield put(
          configDataActions.modifyConfig({
            [excludedExchangesToggleMap[config]]: [],
          })
        );
      }
    }
  }
}

// change "Enable Order Ticket widget" to false if "Enable Order Curtain" is false
function* orderTicketSaga(action) {
  const { modifiedConfig, defaultValues } = yield select(
    state => state.configData
  );
  const ocKey = 'Default/features/orderTicketWidget';
  const ocConfig =
    modifiedConfig[ocKey] !== undefined
      ? modifiedConfig[ocKey]
      : defaultValues[ocKey];
  if (ocConfig === false) {
    const aotKey = 'Default/allowOrderTicketInWidget';
    if (
      modifiedConfig.hasOwnProperty(aotKey) &&
      modifiedConfig[aotKey] === true
    ) {
      yield put(
        configDataActions.modifyConfig({
          'Default/allowOrderTicketInWidget': undefined,
        })
      );
    } else if (
      !modifiedConfig.hasOwnProperty(aotKey) &&
      defaultValues[aotKey] === true
    ) {
      yield put(
        configDataActions.modifyConfig({
          'Default/allowOrderTicketInWidget': false,
        })
      );
    }
  }
}
