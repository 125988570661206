import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { differenceWith } from 'lodash';

import companyListSelector from '../../_selectors/companyListSelector';
import groupListSelector from '../../_selectors/groupListSelector';
import userListSelector from '../../_selectors/userListSelector';
import { configSelectionActions } from '../../_ducks/configSelection';
import CompanyGroupSelectComponent from '../ConfigSelection/CompanyGroupSelectComponent';

const Container = styled.div`
  margin: 25px 0;
`;

class AddCompanyGroupOrUsers extends React.PureComponent {
  render() {
    const { kind, entries, value, onChange } = this.props;
    return (
      <Container>
        <h4>Optionally, choose more {kind} to apply this change to:</h4>
        <CompanyGroupSelectComponent
          id="add-company-or-group"
          name={kind}
          clearable
          completeData={entries}
          onChange={value => onChange(value)}
          placeholder={`Add more ${kind}`}
          multi
          value={value}
          style={{ margin: '20px 0' }}
        />
      </Container>
    );
  }
}

const valuesType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })
);

AddCompanyGroupOrUsers.propTypes = {
  kind: PropTypes.oneOf(['companies', 'groups', 'users']),
  entries: valuesType,
  value: valuesType,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    selectedUserId,
    selectedGroupId,
    selectedCompanyId,
    additionalCompaniesGroupsOrUsers,
  } = state.configSelection;
  let entries, kind;

  if (selectedUserId) {
    kind = 'users';
    // filter out already selected user and any additional users
    // to avoid duplicates
    entries = differenceWith(
      userListSelector(state),
      additionalCompaniesGroupsOrUsers,
      [{ value: selectedUserId }],
      (a, b) => a.value == b.value // eslint-disable-line eqeqeq
    );
  } else if (selectedGroupId) {
    kind = 'groups';
    // filter out already selected group and any additional groups
    // to avoid duplicates
    entries = differenceWith(
      groupListSelector(state),
      additionalCompaniesGroupsOrUsers,
      [{ value: selectedGroupId }],
      (a, b) => a.value == b.value // eslint-disable-line eqeqeq
    );
  } else {
    kind = 'companies';
    entries = differenceWith(
      companyListSelector(state),
      additionalCompaniesGroupsOrUsers,
      [{ value: selectedCompanyId }],
      (a, b) => a.value == b.value // eslint-disable-line eqeqeq
    );
  }

  return {
    entries,
    kind,
    value: additionalCompaniesGroupsOrUsers || [],
  };
};

export default connect(
  mapStateToProps,
  {
    onChange: configSelectionActions.addCompaniesOrGroups,
  }
)(AddCompanyGroupOrUsers);
