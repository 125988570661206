import React, { Component } from 'react';
import Form from 'react-jsonschema-form';

export default class TemplateModal extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        ...{ title: '', data: '', groupId: 'All' },
        ...this.props.formData,
      },
    };
  }

  onFormDataChanged = ({ formData }) => {
    this.setState({ formData });
  };

  onSubmit({ formData }) {
    this.props.onApply(formData);
  }

  schema = {
    properties: {
      title: { title: 'Template Name', type: 'string' },
      data: { title: 'Template', type: 'string' },
      groupId: {
        title: 'Group',
        type: 'string',
        enum: ['All', 'Trd', 'Oth', 'Anl'],
        enumNames: ['All', 'Trading', 'Other', 'Analysis'],
      },
    },
    required: ['title', 'data', 'groupId'],
    type: 'object',
  } as any;

  uiSchema = { data: { 'ui:widget': 'textarea' } } as any;

  validate(formData, errors) {
    try {
      JSON.parse(formData.data);
    } catch (e) {
      errors.data.addError('Invalid JSON object');
    }
    return errors;
  }

  render() {
    return (
      <Form
        schema={this.schema}
        uiSchema={this.uiSchema}
        formData={this.state.formData}
        onChange={this.onFormDataChanged.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        validate={this.validate}>
        <div style={{ display: 'flex' }}>
          <button className="btn btn-primary pull-right" type="submit">
            {this.props.mode}
          </button>
        </div>
      </Form>
    );
  }
}
