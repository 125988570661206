import { get } from 'lodash';

export const initiallySelectedAppName =
  window.localStorage.getItem('previouslySelectedApp') || 'viewpoint';

export const apps = require('../../../../products.json');

export const initiallySelectedApp = apps[initiallySelectedAppName];

export function getAppProperty(appName, property, defaultValue) {
  return get(apps, [appName, property], defaultValue);
}
