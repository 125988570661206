import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';

const DEFAULT_SHOW_SIZE = 5;
function ListEntry({ value }) {
  return (
    <li
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '300px',
      }}
      title={value}>
      {value}
    </li>
  );
}

ListEntry.propTypes = {
  value: PropTypes.string.isRequired,
};

const UnderlineButton = withStyles({
  root: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})(Button);

/**
 *
 * @param {object} props
 * @param {any[]} props.entries
 * @returns
 */
export default function ExpandableListCell({ entries }) {
  const [expanded, setExpanded] = useState(false);
  if (entries.length > DEFAULT_SHOW_SIZE && !expanded) {
    const visibleEntries = entries.slice(0, DEFAULT_SHOW_SIZE);
    return (
      <>
        {visibleEntries.map(entry => (
          <ListEntry value={entry} key={entry} />
        ))}

        <UnderlineButton variant="outlined" onClick={() => setExpanded(true)}>
          ●●●
        </UnderlineButton>
      </>
    );
  } else {
    return (
      <>
        {entries.map(entry => (
          <ListEntry value={entry} key={entry} />
        ))}
      </>
    );
  }
}
