import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import blue from '@material-ui/core/colors/blue';

const styles = theme =>
  createStyles({
    colorSwitchBase: {
      // height: 25,
      '&$colorChecked': {
        color: blue[500],
        '& + $colorBar': {
          backgroundColor: blue[500],
        },
      },
    },
    colorBar: {},
    colorChecked: {},
  });

type Props = WithStyles<typeof styles> & {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

export function SwitchComponent(props: Props) {
  const { classes } = props;
  return (
    <Switch
      classes={{
        switchBase: classes.colorSwitchBase,
        checked: classes.colorChecked,
        track: classes.colorBar,
      }}
      checked={props.checked}
      onChange={props.onChange}
      color="primary"
    />
  );
}

export default withStyles(styles)(SwitchComponent);
