import React, { Component } from 'react';

import InputField from './InputField';

export default class String extends Component<any, {}> {
  onChange(value) {
    const { propKey, onConfigChanged } = this.props;
    onConfigChanged({ [propKey]: value });
  }

  render() {
    const { classes, mergedConfig, propKey } = this.props;
    return (
      <div style={{ marginLeft: 10 }}>
        <InputField
          value={mergedConfig[propKey]}
          onChange={this.onChange.bind(this)}
        />
      </div>
    );
  }
}
