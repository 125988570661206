import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';
import ExpandableListCell from './ExpandableListCell';

export function GroupCell(props) {
  const { extra } = props;
  if (Array.isArray(extra.companies)) {
    const flattenedGroups = flatten(
      extra.companies.map(company =>
        (company.groups || []).map(
          group => group.name && decodeURIComponent(group.name)
        )
      )
    ).filter(g => g);
    if (flattenedGroups.length) {
      return <ExpandableListCell entries={flattenedGroups} />;
    }
  } else if (extra.groupName) {
    return <li title={extra.groupName}>{extra.groupName}</li>;
  }
  return <span>N/A</span>;
}

GroupCell.propTypes = {
  extra: PropTypes.object.isRequired,
};
