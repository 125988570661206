import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import { ConfigRowContainer, RowLabel } from './form-styles';
import ConfigTooltip from './ConfigTooltip';
import ConfigDefinedComponent from './ConfigDefinedComponent';

import styled from 'styled-components';

const LinkStyle = styled.div`
  font-size: 12px;
  margin-top: 5px;
`;

export default class ConfigRow extends Component<any, {}> {
  revertConfig = () => {
    this.props.onConfigChanged({ [this.props.propKey]: undefined });
  };

  configIsModified() {
    const { deletedConfig, modifiedConfig, propKey } = this.props;
    const isModified = modifiedConfig.hasOwnProperty(propKey);
    if (propKey === 'Default/availableColorSets') {
      const modifiedConfigKeys = Object.keys(modifiedConfig);
      return (
        isModified ||
        modifiedConfigKeys.filter(mcKey => mcKey.includes('Templates/colors/'))
          .length > 0
      );
    } else if (/IOSPlus\/Filters\/Available.*FiltersSet/.test(propKey)) {
      const modifiedConfigKeys = Object.keys(modifiedConfig);
      return (
        isModified ||
        modifiedConfigKeys.filter(mcKey =>
          /IOSPlus\/Filters\/.*Filters\//.test(mcKey)
        ).length > 0
      );
    }
    return isModified || deletedConfig.hasOwnProperty(propKey);
  }

  render() {
    const { deletedConfig, modifiedConfig, propKey, properties } = this.props;
    const {
      configType,
      description,
      helpLinkTitle,
      helpLink,
      conditionallyShow,
      longDescription,
      configDefinedIn,
    } = properties[propKey];

    const configIsModified = this.configIsModified();

    return (
      <ConfigRowContainer isModified={configIsModified}>
        <RowLabel>
          <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {conditionallyShow && (
                <div style={{ marginRight: 15, marginLeft: 10, fontSize: 32 }}>
                  -
                </div>
              )}
              <div>{description}</div>
              <ConfigTooltip title={longDescription} />
              <ConfigDefinedComponent definedIn={configDefinedIn} />
            </div>
            {helpLink && (
              <LinkStyle>
                <a href={helpLink} target="_blank">
                  {helpLinkTitle || helpLink}
                </a>
              </LinkStyle>
            )}
          </div>
        </RowLabel>
        {configIsModified && (
          <div
            style={{ cursor: 'pointer' }}
            title="Reset"
            onClick={this.revertConfig}>
            <FontAwesomeIcon icon={faUndo} />
          </div>
        )}
        {this.props.children}
      </ConfigRowContainer>
    );
  }
}
