import React from 'react';
import { flatten } from 'lodash';
import ExpandableListCell from './ExpandableListCell';

export function UserCell(props) {
  const { extra } = props;
  if (Array.isArray(extra.companies)) {
    const flattenedUsers = flatten(
      extra.companies.map(company =>
        (company.users || []).map(
          user => user.name && decodeURIComponent(user.name)
        )
      )
    ).filter(g => g);
    if (flattenedUsers.length) {
      return <ExpandableListCell entries={flattenedUsers} />;
    } else {
      return <span>N/A</span>;
    }
  }
}
