import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ModalBackdrop, ModalContent } from './common-styles';
import { configModalActions } from '../_ducks/configModal';
import FileUploadModal from './FileUploadModal';
import ColorConfigModal from './ColorConfig';

import ExternalWebPageModal from './Modals/ExternalWebPageModal';
import AddEditExternalWebPageModal from './Modals/AddEditExternalWebPageModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import TemplateModal from './TemplateConfig/TemplateModal';
import IOSPlusFilterModal from './IOSPlusFilter';
import IOSPlusOrderFillDestinationModal from './IOSPlusFillDestination';

export class ConfigModal extends Component<any, any> {
  render() {
    const { modalContent, modalProps, modalType } = this.props;
    let ConfigModalContent = null;
    if (modalContent === 'File Upload') {
      ConfigModalContent = <FileUploadModal />;
    }

    // if (modalType === 'Selectable List') {
    //   ConfigModalContent = <SelectableListModal {...modalProps} />;
    // } else
    // if (modalType === 'File Upload') {
    //   ConfigModalContent = <FileUploadModal {...modalProps} />;
    // } else
    if (modalType === 'Color Config') {
      ConfigModalContent = <ColorConfigModal {...modalProps} />;
    } else if (modalType === 'External Web Page') {
      ConfigModalContent = <ExternalWebPageModal {...modalProps} />;
    } else if (modalType === 'Add/Edit External Web Page') {
      ConfigModalContent = <AddEditExternalWebPageModal {...modalProps} />;
    } else if (modalType === 'Template') {
      ConfigModalContent = <TemplateModal {...modalProps} />;
    } else if (modalType === 'IOSPlus Filter') {
      ConfigModalContent = <IOSPlusFilterModal {...modalProps} />;
    } else if (modalType === 'IOSPlus Order Fill Destination') {
      ConfigModalContent = <IOSPlusOrderFillDestinationModal {...modalProps} />;
    }

    return (
      <Fragment>
        {this.props.showConfigModal && (
          <ModalBackdrop
            onClick={event =>
              this.props.toggleConfigModal({ showConfigModal: false })
            }>
            <ModalContent onClick={event => event.stopPropagation()}>
              <div style={{ textAlign: 'right' }}>
                <div
                  onClick={() =>
                    this.props.toggleConfigModal({ showConfigModal: false })
                  }>
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    icon={faTimes}
                  />
                </div>
              </div>
              {ConfigModalContent}
            </ModalContent>
          </ModalBackdrop>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.configModal };
};

const mapDispatchToProps = {
  ...configModalActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigModal);
