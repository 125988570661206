import React, { Component } from 'react';
import { camelCase, upperFirst, filter } from 'lodash';
import { ConfigRow } from '../Form/form-styles';

import Chips from '../Form/Chips';
import Chip from '../Form/Chip';

export default class IOSPlusFillDestinationRow extends Component<any, {}> {
  onEditIosOrderDestination(data) {
    event.preventDefault();
    const {
      onConfigChanged,
      onHideConfigModal,
      onShowConfigModal,
      mergedConfig,
      propKey,
    } = this.props;

    onShowConfigModal({
      modalType: 'IOSPlus Order Fill Destination',
      modalProps: {
        mode: 'Edit',
        formData: {
          serverName: data.serverName,
          destination: data.destination,
        },
        onApply: newFormData => {
          const fillDestinations = mergedConfig[propKey];
          let updatedFillDestination = fillDestinations.filter(
            item =>
              !(
                item.serverName === data.serverName &&
                item.destination === data.destination
              )
          );
          updatedFillDestination.push(newFormData);
          onConfigChanged({ [propKey]: updatedFillDestination });
          onHideConfigModal();
        },
      },
    });
  }

  handleDelete(data) {
    const { mergedConfig, propKey, onConfigChanged } = this.props;
    const fillDestinations = mergedConfig[propKey];
    let updatedFillDestination = fillDestinations.filter(
      item =>
        !(
          item.serverName === data.serverName &&
          item.destination === data.destination
        )
    );
    onConfigChanged({ [propKey]: updatedFillDestination });
  }

  onAddIosOrderDestination = event => {
    event.preventDefault();
    const {
      onConfigChanged,
      onHideConfigModal,
      onShowConfigModal,
      mergedConfig,
      propKey,
    } = this.props;

    onShowConfigModal({
      modalType: 'IOSPlus Order Fill Destination',
      modalProps: {
        mode: 'Add',
        onApply: newFormData => {
          const updatedFillDestination = [...mergedConfig[propKey]];
          updatedFillDestination.push(newFormData);
          onConfigChanged({ [propKey]: updatedFillDestination });
          onHideConfigModal();
        },
      },
    });
  };

  customChip = (data, index) => {
    return (
      <Chip
        data={`${data.serverName} : ${data.destination}`}
        index={index}
        handleDelete={this.handleDelete.bind(this, data)}
        onClick={this.onEditIosOrderDestination.bind(this, data)}
      />
    );
  };

  render() {
    const { mergedConfig, properties, title, propKey } = this.props;
    return (
      <ConfigRow style={{ padding: 20 }}>
        <div>
          <Chips
            customChip={this.customChip}
            list={mergedConfig[propKey] || []}
            reorderable={!!properties[propKey].reorderable}
          />
        </div>
        <div style={{ textAlign: 'right' }}>
          <button
            className="btn btn-primary"
            style={{ marginTop: 5 }}
            onClick={this.onAddIosOrderDestination}>
            Add Order Fill Destination
          </button>
        </div>
      </ConfigRow>
    );
  }
}
