import React, { Component, Fragment } from 'react';
import Form from 'react-jsonschema-form';

export default class IOSPlusFilterModal extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: '',
        filterConfig: '',
        ...this.props.formData,
      },
    };
  }

  onFormDataChanged = ({ formData }) => {
    this.setState({ formData });
  };

  onSubmit({ formData }) {
    this.props.onApply(formData);
  }

  schema = {
    properties: {
      name: {
        title: 'Name',
        type: 'string',
      },
      filterConfig: {
        title: 'Filter Config',
        type: 'string',
      },
    },
    required: ['filterConfig', 'name'],
    type: 'object',
  } as any;

  uiSchema = {
    filterConfig: {
      'ui:widget': 'textarea',
      'ui:options': {
        rows: 6,
      },
    },
  } as any;

  validate(formData, errors) {
    try {
      JSON.parse(formData.filterConfig);
    } catch (e) {
      errors.filterConfig.addError('Invalid JSON config');
    }
    return errors;
  }

  render() {
    return (
      <Form
        schema={this.schema}
        uiSchema={this.uiSchema}
        formData={this.state.formData}
        onChange={this.onFormDataChanged.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        validate={this.validate}>
        <div style={{ display: 'flex', marginBottom: '0.5em' }}>
          <a
            href="https://iress.atlassian.net/wiki/spaces/TRAD/pages/591561693/Hot+to+configure+IOS+Plus+Filters"
            target="_blank">
            How to configure IosPlus filters
          </a>
        </div>

        <div style={{ display: 'flex' }}>
          <button className="btn btn-primary pull-right" type="submit">
            {this.props.mode}
          </button>
        </div>
      </Form>
    );
  }
}
