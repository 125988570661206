import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

export default function UndoButton(props) {
  return (
    <div
      style={{ cursor: 'pointer' }}
      title="Reset"
      role="button"
      tabIndex="-1"
      onKeyPress={() => {}}
      onClick={() => props.onConfigChanged()}>
      <FontAwesomeIcon icon={faUndo} />
    </div>
  );
}

UndoButton.propTypes = {
  onConfigChanged: PropTypes.func,
};
