import { isEmpty, isEqual, get, pick } from 'lodash';
import lunr from 'lunr';
import getTemplateSettingValue from '../_selectors/getTemplateSettingValue';
import { sanitise } from '../components/Common/OfflineSearch';
import { initialState as configSelectionState } from './configSelection';

export const ADD_COLOR_CONFIG = 'ConfigData/ADD_COLOR_CONFIG';
export const APPLY_CONFIG = 'ConfigData/APPLY_CONFIG';
export const APPLY_TRANSLATION = 'ConfigData/APPLY_TRANSLATION';
export const CHANGE_SEARCH_TEXT = 'ConfigData/CHANGE_SEARCH_TEXT';
export const CLEAR_CONFIG = 'ConfigData/CLEAR_CONFIG';
export const COLLAPSE_ALL = 'ConfigData/COLLAPSE_ALL';
export const DELETE_CONFIG = 'ConfigData/DELETE_CONFIG';
export const EDIT_COLOR_CONFIG = 'ConfigData/EDIT_COLOR_CONFIG';
export const EXPAND_ALL = 'ConfigData/EXPAND_ALL';
export const EXPAND_COLLAPSE = 'ConfigData/EXPAND_COLLAPSE';
export const REQUEST_CONFIG = 'ConfigData/REQUEST_CONFIG';
export const REQUEST_SCHEMA = 'ConfigData/REQUEST_SCHEMA';
export const RESET_CONFIG = 'ConfigData/RESET_CONFIG';
export const MODIFY_CONFIG = 'ConfigData/MODIFY_CONFIG';
export const SET_CONFIG_SCHEMA = 'ConfigData/SET_CONFIG_SCHEMA';
export const SET_FORM_DATA = 'ConfigData/SET_FORM_DATA';
export const SET_COMPANY_CONFIG = 'ConfigData/SET_COMPANY_CONFIG';
export const SET_DEFAULT_CONFIG = 'ConfigData/SET_DEFAULT_CONFIG';
export const SET_GROUP_CONFIG = 'ConfigData/SET_GROUP_CONFIG';
export const SET_USER_CONFIG = 'ConfigData/SET_USER_CONFIG';
export const SET_REGIONAL_CONFIG = 'ConfigData/SET_REGIONAL_CONFIG';
export const SIDE_EFFECT_CONFIG = 'ConfigData/SIDE_EFFECT_CONFIG';
export const SUBMIT_CONFIG_SELECTION = 'ConfigData/SUBMIT_CONFIG_SELECTION';
export const POST_PROCESS_CONFIG = 'ConfigData/POST_PROCESS_CONFIG';
export const SET_ERRORS = 'ConfigData/SET_ERRORS';
export const SET_EXPANDED_CATEGORIES = 'ConfigData/SET_EXPANDED_CATEGORIES';

export const configDataActions = {
  addColorConfig: value => ({ type: ADD_COLOR_CONFIG, payload: value }),
  applyConfig: value => ({ type: APPLY_CONFIG, payload: value }),
  applyTranslation: value => ({ type: APPLY_TRANSLATION, payload: value }),
  clearConfig: value => ({ type: CLEAR_CONFIG, payload: value }),
  changeSearchText: value => ({ type: CHANGE_SEARCH_TEXT, payload: value }),
  collapseAll: value => ({ type: COLLAPSE_ALL, payload: value }),
  deleteConfig: value => ({ type: DELETE_CONFIG, payload: value }),
  editColorConfig: value => ({ type: EDIT_COLOR_CONFIG, payload: value }),
  expandAll: value => ({ type: EXPAND_ALL, payload: value }),
  expandCollapse: value => ({ type: EXPAND_COLLAPSE, payload: value }),
  modifyConfig: value => ({ type: MODIFY_CONFIG, payload: value }),
  resetConfig: value => ({ type: RESET_CONFIG, payload: value }),
  requestConfig: value => ({ type: REQUEST_CONFIG, payload: value }),
  requestSchema: value => ({ type: REQUEST_SCHEMA, payload: value }),
  setConfigSchema: value => ({ type: SET_CONFIG_SCHEMA, payload: value }),
  setCompanyConfig: value => ({ type: SET_COMPANY_CONFIG, payload: value }),
  setDefaultConfig: value => ({ type: SET_DEFAULT_CONFIG, payload: value }),
  setGroupConfig: value => ({ type: SET_GROUP_CONFIG, payload: value }),
  setUserConfig: value => ({ type: SET_USER_CONFIG, payload: value }),
  setRegionalConfig: value => ({ type: SET_REGIONAL_CONFIG, payload: value }),
  submitConfigSelection: value => ({
    type: SUBMIT_CONFIG_SELECTION,
    payload: value,
  }),
  onSideEffectConfigChanged: payload => ({
    type: SIDE_EFFECT_CONFIG,
    payload,
  }),
  postProcessConfig: payload => ({
    type: POST_PROCESS_CONFIG,
    payload,
  }),
  setErrors: payload => ({ type: SET_ERRORS, payload }),
  setExpandedCategories: payload => ({
    type: SET_EXPANDED_CATEGORIES,
    payload,
  }),
};

const apps = require('../../../../products.json');

// the lunr search index is not stored in the state
// as it's quiet a large object. This can be put in the
// state if all mapStateToProps use the minimal needed state.
let searchIndex = null;

const initialState = {
  configSchema: null,
  expandedCategories: getExpandedCategories(configSelectionState.selectedApp),

  defaultConfig: {},
  companyConfig: {},
  groupConfig: {},
  userConfig: {},

  defaultValues: {},
  deletedConfig: {},
  modifiedConfig: {},
  postProcessConfig: {},
  postProcessConfigMap: {},
  sideEffectConfig: {},
  sideEffectConfigMap: {},
  errors: {},

  searchText: '',
  hasChanges: false,
  // filtered configSchema properties
  searchResults: [],
};

function getExpandedCategories(app) {
  return get(apps, [app, 'ui', 'expandedCategories'], []).reduce((acc, cat) => {
    acc[cat] = true;
    return acc;
  }, {});
}

function configIsTheSame(state, configKey, value) {
  if (state.configSchema) {
    const configDef = state.configSchema.properties[configKey];
    const { defaultValues, sideEffectConfigMap } = state;

    let defaultValue = defaultValues[configKey];
    defaultValue = getTemplateSettingValue(defaultValue, defaultValues);

    const hasSideEffect = Object.keys(sideEffectConfigMap).find(
      key => sideEffectConfigMap[key] === configKey
    );

    if (defaultValue === undefined) return false;

    if (
      configDef.type === 'string' ||
      configDef.type === 'number' ||
      configDef.type === 'boolean'
    ) {
      return value === defaultValue && !hasSideEffect;
    } else if (configDef.type === 'array') {
      if (configDef.reorderable) {
        return isEqual(value, defaultValue) && !hasSideEffect;
      } else {
        return isEqual(value.sort(), defaultValue.sort()) && !hasSideEffect;
      }
    }
  }
  return false;
}

function assignTemplate(newDefaultValues) {
  Object.keys(newDefaultValues).forEach(key => {
    const setting = newDefaultValues[key];
    newDefaultValues[key] = getTemplateSettingValue(setting, newDefaultValues);
  });
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_COLOR_CONFIG: {
      const newModifiedConfig = { ...state.modifiedConfig, ...action.payload };
      return { ...state, modifiedConfig: newModifiedConfig };
    }
    case CHANGE_SEARCH_TEXT: {
      const searchText = action.payload;
      const searchResults = filterSearchResults(searchText, state.configSchema);
      return { ...state, searchText, searchResults };
    }
    case CLEAR_CONFIG: {
      return { ...state, modifiedConfig: {} };
    }
    case DELETE_CONFIG: {
      const newDeletedConfig = { ...state.deletedConfig };
      Object.keys(action.payload).forEach(dConfig => {
        if (action.payload[dConfig] === undefined) {
          delete newDeletedConfig[dConfig];
        } else if (action.payload[dConfig] === true) {
          newDeletedConfig[dConfig] = true;
        }
      });
      return {
        ...state,
        deletedConfig: newDeletedConfig,
      };
    }
    case EDIT_COLOR_CONFIG: {
      return { ...state, ...action.payload };
    }
    case EXPAND_ALL: {
      const { expandedCategories } = state;
      const newCategories = { ...expandedCategories };
      action.payload.forEach(key => (newCategories[key] = true));
      return { ...state, expandedCategories: newCategories };
    }
    case EXPAND_COLLAPSE: {
      const { expandedCategories } = state;
      const newCategories = { ...expandedCategories };
      if (newCategories.hasOwnProperty(action.payload)) {
        delete newCategories[action.payload];
      } else {
        newCategories[action.payload] = true;
      }
      return { ...state, expandedCategories: newCategories };
    }
    case MODIFY_CONFIG: {
      const newModifiedConfig = { ...state.modifiedConfig };
      const newDeletedConfig = { ...state.deletedConfig };
      const newPostProcessConfig = { ...state.postProcessConfig };
      const newPostProcessConfigMap = { ...state.postProcessConfigMap };
      const newSideEffectConfig = { ...state.sideEffectConfig };
      const newSideEffectConfigMap = { ...state.sideEffectConfigMap };
      const newActionPayload = { ...action.payload };
      Object.keys(newActionPayload).forEach(mConfig => {
        const config = newActionPayload[mConfig];
        if (
          config === undefined ||
          config === '' ||
          configIsTheSame(state, mConfig, config)
        ) {
          delete newModifiedConfig[mConfig];
          delete newActionPayload[mConfig];

          // Revert select option dependancy
          if (state.configSchema) {
            const configDef = state.configSchema.properties[mConfig];
            if (configDef.selectOptionList) {
              delete newModifiedConfig[configDef.selectOptionList];
            }
          }

          // Sanitize side effect config
          Object.keys(newSideEffectConfigMap).forEach(seConfig => {
            if (newSideEffectConfigMap[seConfig] === mConfig) {
              delete newSideEffectConfig[seConfig];
              delete newSideEffectConfigMap[seConfig];
            }
          });

          // Sanitize post process config
          Object.keys(newPostProcessConfig).forEach(ppConfig => {
            if (newPostProcessConfigMap[ppConfig] === mConfig) {
              delete newPostProcessConfig[ppConfig];
              delete newPostProcessConfigMap[ppConfig];
            }
          });
        }
      });

      const modifiedConfig = { ...newModifiedConfig, ...newActionPayload };
      return {
        ...state,
        modifiedConfig,
        deletedConfig: newDeletedConfig,
        postProcessConfig: newPostProcessConfig,
        postProcessConfigMap: newPostProcessConfigMap,
        sideEffectConfig: newSideEffectConfig,
        sideEffectConfigMap: newSideEffectConfigMap,
        hasChanges: !(isEmpty(modifiedConfig) && isEmpty(newDeletedConfig)),
      };
    }
    case REQUEST_CONFIG: {
      const defaultConfig = {
        defaultValues: {},
        companyConfig: {},
        groupConfig: {},
        userConfig: {},
        deletedConfig: {},
        modifiedConfig: {},
        postProcessConfig: {},
        postProcessConfigMap: {},
        sideEffectConfig: {},
        sideEffectConfigMap: {},
        errors: {},

        searchText: '',
        hasChanges: false,
        searchResults: [],
      };
      return { ...state, ...defaultConfig };
    }
    case POST_PROCESS_CONFIG: {
      const { configId, postProcessConfig } = action.payload;
      const newPostProcessConfig = {
        ...state.postProcessConfig,
        ...postProcessConfig,
      };
      const newPostProcessConfigMap = { ...state.postProcessConfigMap };
      Object.keys(postProcessConfig).forEach(ppConfig => {
        if (postProcessConfig[ppConfig] === undefined) {
          delete newPostProcessConfigMap[ppConfig];
          delete newPostProcessConfig[ppConfig];
        } else {
          newPostProcessConfigMap[ppConfig] = configId;
        }
      });
      return {
        ...state,
        postProcessConfig: newPostProcessConfig,
        postProcessConfigMap: newPostProcessConfigMap,
      };
    }
    case RESET_CONFIG: {
      return {
        ...state,
        modifiedConfig: {},
        deletedConfig: {},
        sideEffectConfig: {},
      };
    }
    case SET_COMPANY_CONFIG: {
      const { defaultConfig, groupConfig, userConfig, regionalConfig } = state;
      const newDefaultValues = {
        ...defaultConfig,
        ...regionalConfig,
        ...action.payload,
        ...groupConfig,
        ...userConfig,
      };
      assignTemplate(newDefaultValues);
      return {
        ...state,
        companyConfig: action.payload,
        defaultValues: newDefaultValues,
      };
    }
    case SET_CONFIG_SCHEMA: {
      const newState = { ...state, configSchema: action.payload };
      searchIndex = buildSearchIndex(newState);
      return newState;
    }
    case SET_DEFAULT_CONFIG: {
      const { companyConfig, groupConfig, userConfig } = state;
      const newDefaultValues = {
        ...action.payload,
        ...companyConfig,
        ...groupConfig,
        ...userConfig,
      };
      assignTemplate(newDefaultValues);
      return {
        ...state,
        defaultConfig: action.payload,
        defaultValues: newDefaultValues,
      };
    }
    case SET_GROUP_CONFIG: {
      const {
        defaultConfig,
        companyConfig,
        regionalConfig,
        userConfig,
      } = state;
      const newDefaultValues = {
        ...defaultConfig,
        ...regionalConfig,
        ...companyConfig,
        ...userConfig,
        ...action.payload,
      };
      assignTemplate(newDefaultValues);
      return {
        ...state,
        groupConfig: action.payload,
        defaultValues: newDefaultValues,
      };
    }
    case SET_USER_CONFIG: {
      const {
        defaultConfig,
        companyConfig,
        regionalConfig,
        groupConfig,
      } = state;
      const newDefaultValues = {
        ...defaultConfig,
        ...regionalConfig,
        ...companyConfig,
        ...groupConfig,
        ...action.payload,
      };
      assignTemplate(newDefaultValues);
      return {
        ...state,
        userConfig: action.payload,
        defaultValues: newDefaultValues,
      };
    }
    case SET_REGIONAL_CONFIG: {
      const { defaultConfig, companyConfig, groupConfig, userConfig } = state;
      const newDefaultValues = {
        ...defaultConfig,
        ...action.payload,
        ...companyConfig,
        ...groupConfig,
        ...userConfig,
      };
      assignTemplate(newDefaultValues);
      return {
        ...state,
        regionalConfig: action.payload,
        defaultValues: newDefaultValues,
      };
    }
    case SET_EXPANDED_CATEGORIES: {
      const { app } = action.payload;
      return {
        ...state,
        expandedCategories: getExpandedCategories(app),
      };
    }
    case COLLAPSE_ALL: {
      const { expandedCategories } = state;
      const newCategories = { ...expandedCategories };
      action.payload.forEach(key => delete newCategories[key]);
      return { ...state, expandedCategories: newCategories };
    }
    case SIDE_EFFECT_CONFIG: {
      const { configId, sideEffectConfig } = action.payload;
      const newSideEffectConfig = {
        ...state.sideEffectConfig,
        ...sideEffectConfig,
      };
      const newSideEffectConfigMap = { ...state.sideEffectConfigMap };
      Object.keys(sideEffectConfig).forEach(seConfig => {
        if (sideEffectConfig[seConfig] === undefined) {
          delete newSideEffectConfigMap[seConfig];
          delete newSideEffectConfig[seConfig];
        } else {
          newSideEffectConfigMap[seConfig] = configId;
        }
      });
      return {
        ...state,
        sideEffectConfig: newSideEffectConfig,
        sideEffectConfigMap: newSideEffectConfigMap,
      };
    }
    case SET_ERRORS: {
      return { ...state, errors: action.payload };
    }
    default:
      return state;
  }
}

function buildSearchIndex(state) {
  const builder = new lunr.Builder();
  builder.ref('_id');
  builder.field('_id');
  builder.field('category');
  builder.field('description');

  Object.entries(get(state, ['configSchema', 'properties'], {})).forEach(
    ([key, value]) => {
      builder.add({ ...value, _id: key });
    }
  );

  return builder.build();
}

// search for all values starting with searchText (hence the trailing *)
function filterSearchResults(searchText, configSchema) {
  const properties = (configSchema && configSchema.properties) || {};
  const searchKeys = searchText
    ? searchIndex.search(sanitise(searchText + '*')).map(result => result.ref)
    : [];

  return searchKeys.length > 0 ? pick(properties, searchKeys) : {};
}
