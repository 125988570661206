import 'regenerator-runtime/runtime';

import './assets/style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import createBrowserHistory from 'history/createBrowserHistory';

import AppComponent from './components/AppComponent';

import configureStore from './store/configureStore';
import { actions } from './_ducks/auth';
import { initAuth } from './api/okta-auth';

const history = createBrowserHistory();
const store = configureStore(history);

const auth = initAuth({ history });

store.dispatch(
  actions.setIssuer({
    issuer: auth._config.issuer,
    clientId: auth._config.client_id,
  })
);

window.__disableSecurity = !!process.env.DISABLE_SECURITY;
window.__noVerifyToken = true;

if (window.__disableSecurity) {
  store.dispatch({
    type: 'AUTH_RESULT',
    payload: {
      idToken: {
        idToken: 'blah',
        claims: {
          name: 'Automation',
          email: 'automation@iress.com',
        },
      },
    },
  });
}

const app = <AppComponent />;

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      {window.__disableSecurity ? app : <Security auth={auth}>{app}</Security>}
    </Router>
  </Provider>,
  document.getElementById('root')
);
