import React, { Component } from 'react';
import styled from 'styled-components';
import { isEmpty, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faUsers,
  faUser,
  faLanguage,
} from '@fortawesome/free-solid-svg-icons';

import CompanyGroupSelectComponent from './CompanyGroupSelectComponent';
import { SelectOption } from './CompanyGroupSelectComponent';
import ProgressDots from '../Common/ProgressDots';
import ButtonSelector from '../Common/ButtonSelector';
import { Label } from '../common-styles';
import { Link } from 'react-router-dom';

export interface CompanySelectPayload {
  selectedCompanyId: string;
  selectedCompanyName: string;
}
export interface GroupSelectPayload {
  selectedGroupId: string;
  selectedGroupName: string;
}

export interface UserSelectPayload {
  selectedUserId: string;
  selectedUserName: string;
}

const Row = styled.div`
  margin-bottom: 15px;
`;

const ButtonContentContainer = styled.div`
  padding: 20px 50px;
  margin-right: 10px;
  font-size: 24px;
`;

interface ConfigSelectionComponentProps {
  selectedApp: string;
  selectedCompanyId: string;
  selectedCompanyName: string;
  selectedGroupId: string;
  selectedGroupName: string;
  selectedUserId: string;
  selectedUserName: string;
  showLoadingDots: string;
  companyList: SelectOption[];
  groupList: SelectOption[];
  userList: SelectOption[];
  userConfigEnabled: boolean;
  configureState?: 'company' | 'group' | 'user' | 'translation';
  onCompanySelected: (company: CompanySelectPayload) => void;
  onGroupSelected: (group: GroupSelectPayload) => void;
  onUserSelected: (user: UserSelectPayload) => void;
  onConfigSelectionSubmited: () => void;
  onClearCompanyAndGroup?: () => void;
  onLoad: () => void;
  onConfigureState: (
    mode: 'company' | 'group' | 'user' | 'translation'
  ) => void;
}

interface ConfigSelectionComponentState {
  retrievingCompaniesText: string;
  retrievingEntitiesText: string;
}

export default class ConfigSelectionComponent extends Component<
  ConfigSelectionComponentProps,
  ConfigSelectionComponentState
> {
  constructor(props) {
    super(props);

    this.state = {
      retrievingCompaniesText: '',
      retrievingEntitiesText: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }
  retrievingListTimer = null;
  loadButton: any;

  componentDidMount() {
    if (!this.props.companyList || this.props.companyList.length === 0) {
      this.changeCompanyPlaceholder();
      this.retrievingListTimer = setInterval(
        this.changeCompanyPlaceholder,
        500
      );
    }
  }
  changeCompanyPlaceholder = () => {
    if (this.props.companyList && this.props.companyList.length !== 0) {
      clearInterval(this.retrievingListTimer);
      this.retrievingListTimer = null;
      this.setState({ retrievingCompaniesText: 'Select Company' });
    }
    if (this.state.retrievingCompaniesText === 'Retrieving Companies') {
      this.setState({ retrievingCompaniesText: 'Retrieving Companies.' });
    } else if (this.state.retrievingCompaniesText === 'Retrieving Companies.') {
      this.setState({ retrievingCompaniesText: 'Retrieving Companies..' });
    } else if (
      this.state.retrievingCompaniesText === 'Retrieving Companies..'
    ) {
      this.setState({ retrievingCompaniesText: 'Retrieving Companies...' });
    } else if (
      !this.state.retrievingCompaniesText ||
      this.state.retrievingCompaniesText === 'Retrieving Companies...'
    ) {
      this.setState({ retrievingCompaniesText: 'Retrieving Companies' });
    }
  };

  startRetrievingEntities = (
    entityListName,
    entitySingularText,
    entityPluralText
  ) => {
    clearInterval(this.retrievingListTimer);
    this.retrievingListTimer = null;
    this.changeEntityPlaceholder(
      entityListName,
      entitySingularText,
      entityPluralText
    );
    this.retrievingListTimer = setInterval(
      () =>
        this.changeEntityPlaceholder(
          entityListName,
          entitySingularText,
          entityPluralText
        ),
      500
    );
  };

  changeEntityPlaceholder = (
    entityListName,
    entitySingularText,
    entityPluralText
  ) => {
    if (this.props[entityListName].length !== 0) {
      clearInterval(this.retrievingListTimer);
      this.retrievingListTimer = null;
      this.setState({ retrievingEntitiesText: `Select ${entitySingularText}` });
      return;
    }
    if (
      this.state.retrievingEntitiesText === `Retrieving ${entityPluralText}`
    ) {
      this.setState({
        retrievingEntitiesText: `Retrieving ${entityPluralText}.`,
      });
    } else if (
      this.state.retrievingEntitiesText === `Retrieving ${entityPluralText}.`
    ) {
      this.setState({
        retrievingEntitiesText: `Retrieving ${entityPluralText}..`,
      });
    } else if (
      this.state.retrievingEntitiesText === `Retrieving ${entityPluralText}..`
    ) {
      this.setState({
        retrievingEntitiesText: `Retrieving ${entityPluralText}...`,
      });
    } else {
      this.setState({
        retrievingEntitiesText: `Retrieving ${entityPluralText}`,
      });
    }
  };

  onSubmit(event) {
    event.preventDefault();
    this.props.onLoad();
  }

  onConfigureForMode(mode) {
    this.props.onConfigureState(mode);
    this.props.onCompanySelected({
      selectedCompanyName: '',
      selectedCompanyId: '',
    });
    this.props.onGroupSelected({
      selectedGroupName: '',
      selectedGroupId: '',
    });
    this.props.onUserSelected({
      selectedUserName: '',
      selectedUserId: '',
    });

    clearInterval(this.retrievingListTimer);
    this.retrievingListTimer = null;

    if (mode === 'group') {
      this.setState({ retrievingEntitiesText: 'Select Group' });
    } else if (mode === 'user') {
      this.setState({ retrievingEntitiesText: 'Select User' });
    } else {
      this.setState({ retrievingEntitiesText: '' });
    }
  }

  render() {
    const {
      props,
      props: { configureState },
    } = this;
    return (
      <div className="container">
        <form name="config-selection" onSubmit={this.onSubmit}>
          {!props.showLoadingDots && (
            <div>
              <Label style={{ textAlign: 'center' }}>
                I want to configure a
              </Label>
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  marginBottom: 20,
                }}>
                <ButtonSelector
                  title="Company"
                  checked={configureState === 'company'}
                  onChange={() => this.onConfigureForMode('company')}>
                  <ButtonContentContainer>
                    <span>Company</span>
                    <FontAwesomeIcon
                      style={{ marginLeft: 25 }}
                      icon={faBuilding}
                    />
                  </ButtonContentContainer>
                </ButtonSelector>
                <ButtonSelector
                  title="Group"
                  checked={configureState === 'group'}
                  onChange={() => this.onConfigureForMode('group')}>
                  <ButtonContentContainer>
                    <span>Group</span>
                    <FontAwesomeIcon
                      style={{ marginLeft: 25 }}
                      icon={faUsers}
                    />
                  </ButtonContentContainer>
                </ButtonSelector>
                {props.selectedApp === 'viewpoint' && (
                  <Link to="/translation">
                    <ButtonSelector
                      title="Translation"
                      checked={configureState === 'translation'}
                      onChange={() => this.onConfigureForMode('translation')}>
                      <ButtonContentContainer>
                        <span>Translation</span>
                        <FontAwesomeIcon
                          style={{ marginLeft: 25 }}
                          icon={faLanguage}
                        />
                      </ButtonContentContainer>
                    </ButtonSelector>
                  </Link>
                )}
                {props.userConfigEnabled && (
                  <ButtonSelector
                    title="User"
                    checked={configureState === 'user'}
                    onChange={() => this.onConfigureForMode('user')}>
                    <ButtonContentContainer>
                      <span>User</span>
                      <FontAwesomeIcon
                        style={{ marginLeft: 25 }}
                        icon={faUser}
                      />
                    </ButtonContentContainer>
                  </ButtonSelector>
                )}
              </div>
              {configureState !== null &&
                !(configureState === 'user' && !props.userConfigEnabled) && (
                  <>
                    <Label className="company_label">Company</Label>
                    <Row>
                      <CompanyGroupSelectComponent
                        name="company"
                        isDisabled={
                          !props.companyList || props.companyList.length <= 0
                        }
                        completeData={props.companyList}
                        valueChanged={args => {
                          if (isEmpty(args)) {
                            this.props.onClearCompanyAndGroup();
                          } else {
                            props.onCompanySelected({
                              selectedCompanyName: get(args, 'label', ''),
                              selectedCompanyId: get(args, 'value', ''),
                            });
                            if (props.configureState === 'group') {
                              this.startRetrievingEntities(
                                'groupList',
                                'Group',
                                'Groups'
                              );
                            } else if (props.configureState === 'user') {
                              this.startRetrievingEntities(
                                'userList',
                                'User',
                                'Users'
                              );
                            }
                          }
                        }}
                        placeholder={this.state.retrievingCompaniesText}
                        selectedName={props.selectedCompanyName}
                        selectedId={props.selectedCompanyId}
                        submit={() => {
                          this.loadButton.click();
                        }}
                        clearable
                      />
                    </Row>
                  </>
                )}
              {configureState === 'group' && (
                <>
                  <Label className="group_label">Group</Label>
                  <Row>
                    <CompanyGroupSelectComponent
                      name="group"
                      isDisabled={
                        !props.groupList || props.groupList.length <= 0
                      }
                      completeData={props.groupList}
                      valueChanged={args =>
                        props.onGroupSelected({
                          selectedGroupName: get(args, 'label', ''),
                          selectedGroupId: get(args, 'value', ''),
                        })
                      }
                      placeholder={this.state.retrievingEntitiesText}
                      selectedName={props.selectedGroupName}
                      selectedId={props.selectedGroupId}
                      submit={() => {
                        this.loadButton.click();
                      }}
                      clearable
                    />
                  </Row>
                </>
              )}
              {configureState === 'user' &&
                props.userConfigEnabled === true && (
                  <>
                    <Label className="user_label">User</Label>
                    <Row>
                      <CompanyGroupSelectComponent
                        name="user"
                        isDisabled={
                          !props.userList || props.userList.length <= 0
                        }
                        completeData={props.userList}
                        valueChanged={args =>
                          props.onUserSelected({
                            selectedUserName: get(args, 'label', ''),
                            selectedUserId: get(args, 'value', ''),
                          })
                        }
                        placeholder={this.state.retrievingEntitiesText}
                        selectedName={props.selectedUserName}
                        selectedId={props.selectedUserId}
                        submit={() => {
                          this.loadButton.click();
                        }}
                        clearable
                      />
                    </Row>
                  </>
                )}
              {configureState === 'user' &&
                !props.userConfigEnabled && (
                  <>
                    <Label
                      className="user_config_unsupported_error_label"
                      style={{ textAlign: 'center' }}>
                      User-level configuration is not currently supported for
                      the selected app.
                    </Label>
                  </>
                )}
              {configureState !== null && (
                <button
                  ref={elem => (this.loadButton = elem)}
                  className="btn btn-primary btn-lg btn-block"
                  data-testid="go-button"
                  type="submit"
                  disabled={loadDisabled(
                    configureState,
                    props.userConfigEnabled,
                    props.selectedCompanyId,
                    props.selectedGroupId,
                    props.selectedUserId
                  )}
                  name="load">
                  Load
                </button>
              )}
            </div>
          )}
          {props.showLoadingDots && <ProgressDots />}
        </form>
      </div>
    );
  }
}

function loadDisabled(
  configureState,
  userConfigEnabled,
  selectedCompanyId,
  selectedGroupId,
  selectedUserId
) {
  if (configureState === 'user' && userConfigEnabled === true) {
    return !(selectedCompanyId && selectedUserId);
  } else if (configureState === 'group') {
    return !(selectedCompanyId && selectedGroupId);
  } else if (configureState === 'company') {
    return !selectedCompanyId;
  }
  return true;
}
