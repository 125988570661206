interface Diff {
  name: string;
  value: {
    old: any;
    new: any;
  };
}

interface DiffProps {
  modifiedConfig: object;
  deletedConfig: object;
  defaultValues: any;
  flatSchema: any;
  isDeleted?: boolean;
}

export default function(props: DiffProps): Diff[] {
  const { modifiedConfig, deletedConfig } = props;

  const modified: Diff[] = Object.keys(modifiedConfig).map(key => {
    return diff(key, modifiedConfig[key], props);
  });
  const deleted: Diff[] = Object.keys(deletedConfig).map(key => {
    return diff(key, deletedConfig[key], { ...props, isDeleted: true });
  });

  return modified.concat(deleted);
}

export function diff(
  key: string,
  newValue: any,
  { defaultValues, flatSchema, isDeleted = false }: DiffProps
): Diff {
  let oldValue = defaultValues[key];

  if (isDeleted) {
    newValue = undefined;
  } else {
    const config = flatSchema[key];
    // overrides for complex types
    if (config.baseType === 'select' && config.selectMapping) {
      oldValue = config.selectMapping[defaultValues[key]];
      newValue = config.selectMapping[newValue];
    }
  }

  return {
    name: key,
    value: {
      old: oldValue,
      new: newValue,
    },
  } as Diff;
}
