import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { transform } from '../../common';

const styles = theme => ({
  resize: {
    fontSize: 16,
  },
  textField: {
    width: 250,
  },
});

class InputField extends React.Component<any, any> {
  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.props.value;
  }

  onChange = event => {
    const { onChange, transform: transformation } = this.props;
    const value = event.target.value;
    const transformedValue = transformation
      ? transform(transformation, value)
      : value;

    onChange(transformedValue);
  };

  render() {
    const { classes, onKeyPress, placeholder, value } = this.props;

    return (
      <div style={{ marginLeft: 10 }}>
        <TextField
          className={classes.textField}
          placeholder={placeholder}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={value}
          onChange={this.onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    );
  }
}

export default withStyles(styles)(InputField);
