import React from 'react';
import PropTypes from 'prop-types';

export function ModalCheckboxes({
  checkboxItems,
  title,
  currentValue,
  onChange,
}) {
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      {checkboxItems.map(({ label, value, id }) => (
        <div key={value} style={{ marginRight: 20 }}>
          <label htmlFor={id} style={{ cursor: 'pointer' }}>
            <input
              type="radio"
              id={id}
              name={title}
              checked={currentValue === value}
              onChange={event => {
                onChange(value);
              }}
            />
            <span style={{ marginLeft: 5 }}>{label}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

ModalCheckboxes.propTypes = {
  checkboxItems: PropTypes.array,
  title: PropTypes.string,
  currentValue: PropTypes.any,
  onChange: PropTypes.func,
};
