import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import TranslationBlock from './TranslationBlock';
import { TranslationsRequest, Translations } from '../Common/TranslationTypes';

const SubmitButton = styled.button`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: 20px;
`;

const SubmitButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 20px;
  z-index: 1;

  background-color: rgb(248, 249, 250);
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 50px;
  left: 0px;
  background-color: rgb(248, 249, 250);
  width: 100%;
  z-index: 1;
  padding-bottom: 20px;
`;

const langMap: Record<string, string> = {
  fr: 'French',
  'fr-CA': 'French Canadian',
  zh: 'Chinese',
};

const menuItems = Object.keys(langMap).map(item => (
  <MenuItem key={item} value={item}>
    {langMap[item]}
  </MenuItem>
));

export const fetchTranslations = async (language: string) => {
  let baseLangauageResponse;
  const LanguageResponse = await fetch(
    `https://cdn-staging.iress.com/viewpoint/locales/${language}/translation.json`
  );

  //check if language has - in it
  if (language.includes('-')) {
    baseLangauageResponse = await fetch(
      `https://cdn-staging.iress.com/viewpoint/locales/${
        language.split('-')[0]
      }/translation.json`
    );
  }

  let translations = await LanguageResponse.json();

  if (baseLangauageResponse) {
    const baseTranslations = await baseLangauageResponse.json();

    for (const key in translations) {
      if (
        key in baseTranslations &&
        Object.keys(baseTranslations[key]).length !==
          Object.keys(translations[key]).length
      ) {
        translations[key] = {
          ...baseTranslations[key],
          ...translations[key],
        };
      }
    }

    translations = { ...baseTranslations, ...translations };
  }

  return translations;
};

export const fetchEnglishTranslations = async () => {
  const EnglishLanguageResponse = await fetch(
    `https://cdn-staging.iress.com/viewpoint/locales/en/translation.json`
  );
  let englishTranslations = await EnglishLanguageResponse.json();

  return englishTranslations;
};

export const removeLanguageSection = (
  translations: Translations
): Translations => {
  if (translations.Language !== undefined) {
    const { Language, ...filteredTranslations } = translations;

    return filteredTranslations;
  }

  return translations;
};

interface TranslationPageProps {
  location: {
    state: {
      translations: Translations;
      translationsEnglish: {};
      translationsRequest: TranslationsRequest;
      formedTranslationsRequest: TranslationsRequest;
      language: string;
    };
  };
}

const isEmpty = (obj: any): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const getDefaultLanguage = state => {
  if (state && state.language) {
    return state.language;
  }

  return 'fr-CA';
};

function TranslationConfigPage(props: TranslationPageProps) {
  const { state } = useLocation();
  const defaultLanguage = getDefaultLanguage(state);
  const [language, setLanguage] = React.useState<string>(defaultLanguage);
  const [loading, setLoading] = React.useState(false);
  const [translations, setTranslations] = React.useState<Translations>({});
  const [translationsEnglish, setTranslationsEnglish] = React.useState({});
  const [translationsRequest, setTranslationsRequest] = React.useState<
    Translations
  >({});

  const setTranslationsRequestElement = (
    element: string,
    key: string,
    value: string
  ) => {
    setTranslationsRequest(prev => ({
      ...prev,
      [element]: {
        ...prev[element],
        [key]: value,
      },
    }));
  };

  const confirmChangeLanguageModal = (e, newLanguage): void => {
    const confirmResult = window.confirm(
      'Your unsaved changes will be lost once you change language. Do you want to proceed?'
    );

    if (confirmResult) {
      setTranslationsRequest({}); // clear user changes
      setLanguage(newLanguage);
    } else {
      e.preventDefault();
    }
  };

  React.useEffect(
    () => {
      if (state) {
        const { language, translations } = state;
        setLanguage(language); //so the language updated to previously selected when user returns back from review page
        setTranslations(translations);
      }
    },
    [state]
  );

  const copyDefaultEnglish = (localeLang, enLang) => {
    const formattedTranslations = Object.keys(enLang).map(mfKey => {
      const enObjCleared = Object.keys(enLang[mfKey])
        .map(enKey => {
          return { [enKey]: '' };
        })
        .reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {});
      if (localeLang[mfKey]) {
        return {
          ...enObjCleared,
          ...localeLang[mfKey],
          key: mfKey,
        };
      } else {
        return { ...enObjCleared, key: mfKey };
      }
    });
    const formatTranslations = formattedTranslations.reduce(
      (previousObject, currentObject) => {
        const { key } = currentObject;
        delete currentObject.key;
        return {
          ...previousObject,
          ...{
            [key]: currentObject,
          },
        };
      },
      {}
    );
    return formatTranslations;
  };

  React.useEffect(
    () => {
      (async () => {
        setLoading(true);
        const localeTranslation = await fetchTranslations(language);
        const enTranslation = await fetchEnglishTranslations();
        const formattedLocale = copyDefaultEnglish(
          localeTranslation,
          enTranslation
        );
        setTranslations(formattedLocale);
        setTranslationsEnglish(enTranslation);
        setLoading(false);
      })();
    },
    [language]
  );

  if (loading) {
    return (
      <div className="loader-wrapper">
        <CircularProgress />
      </div>
    );
  }
  const languageText = langMap[language];
  const languageChangeHandler = e => {
    const newLanguage = e.target.value;
    if (isEmpty(translationsRequest)) {
      // no changes were made yet, just change language straight away
      setLanguage(newLanguage);
    } else {
      confirmChangeLanguageModal(e, newLanguage);
    }
  };

  return (
    <>
      <div className="container translation">
        <HeaderContainer>
          <div className="container" style={{ marginTop: 10 }}>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: 15 }}>
                  <div style={{ fontSize: 35 }}>{'ViewPoint Translations'}</div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Language
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={language}
                    onChange={languageChangeHandler}
                    label="Language">
                    {...menuItems}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </HeaderContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '8rem',
            marginBottom: '8rem',
          }}>
          {Object.keys(removeLanguageSection(translations)).map(key => (
            <TranslationBlock
              key={key}
              title={key}
              translationsElement={translations[key]}
              englishTranslations={
                language !== 'en' && translationsEnglish[key]
              }
              translationsRequestElement={
                translationsRequest.hasOwnProperty(key) &&
                translationsRequest[key]
              }
              setTranslationsRequestElement={setTranslationsRequestElement}
            />
          ))}
        </div>
        <SubmitButtonContainer>
          <div className="container">
            <Link
              to={{
                pathname: `/reviewTranslation/${language}`,
                state: {
                  translations,
                  translationsEnglish,
                  translationsRequest,
                  language,
                  languageText,
                },
              }}>
              <SubmitButton
                type="submit"
                data-testid="edit-page-review-button"
                className="btn btn-primary btn-lg btn-block"
                disabled={Object.keys(translationsRequest).length === 0}>
                Review
              </SubmitButton>
            </Link>
          </div>
        </SubmitButtonContainer>
      </div>
    </>
  );
}

export default TranslationConfigPage;
