import { connect } from 'react-redux';

import { configDataActions } from '../../_ducks/configData';
import ReviewPage from './ReviewPage';

const mapDispatchToProps = {
  applyConfig: configDataActions.applyConfig,
};

export default connect(
  undefined,
  mapDispatchToProps
)(ReviewPage);
