import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { camelCase, includes } from 'lodash';
import { ModalCheckboxes } from './ModalCheckboxes';
import { ModalSelectBox } from './ModalSelectBox';

export const ErrorContainer = styled.ul`
  background-color: #ffcccc;
  padding: 10px;
`;

export const ErrorRow = styled.li`
  color: #c0392b;
`;

const TextInput = styled.input`
  width: 100%;
  border: 0.5px solid black;
  padding: 2px 5px;
`;

const groupItems = [
  { label: 'All', value: 'All' },
  { label: 'Analysis', value: 'Anl' },
  { label: 'Colour Widgets', value: 'Clr' },
  { label: 'Derivatives', value: 'Der' },
  { label: 'Market Data', value: 'MD' },
  { label: 'Media', value: 'Med' },
  { label: 'Paper Trading', value: 'Gam' },
  { label: 'Partners', value: 'Prt' },
  { label: 'Security Analysis', value: 'SA' },
  { label: 'Showcase', value: 'Shw' },
  { label: 'Trading', value: 'Trd' },
  { label: 'Training', value: 'Tra' },
  { label: 'Other', value: 'Oth' },
];

const iconTypes = [
  { label: 'Url', value: 'iconUrl' },
  { label: 'Font Awesome Icon', value: 'icon' },
];

const postSessionTicketOptions = [
  { label: 'False', value: false, id: 'postSessionFalse' },
  { label: 'True', value: true, id: 'postSessionTrue' },
];

const showRefreshOptions = [
  { label: 'False', value: false, id: 'showRefreshFalse' },
  { label: 'True', value: true, id: 'showRefreshTrue' },
];

const icons = [
  { label: 'Bar Chart', value: 'fa-bar-chart-o' },
  { label: 'Globe', value: 'fa-globe' },
  { label: 'Graduation Cap', value: 'fa-graduation-cap' },
  { label: 'List', value: 'fa-th-list' },
  { label: 'Signal', value: 'fa-signal' },
  { label: 'YouTube', value: 'fa-youtube-play' },
];

const form = [
  { id: 'name', title: 'Name', type: 'text', required: true },
  { id: 'desc', title: 'Description', type: 'text' },
  { id: 'url', title: 'URL', type: 'text', required: true },
  { id: 'acsUrl', title: 'ACS URL (optional)', type: 'text', required: false },
  { id: 'acsUri', title: 'ACS URI (optional)', type: 'text', required: false },
  { id: 'groupId', title: 'Grouping', type: 'select', items: groupItems },
  { id: 'iconType', title: 'Icon Type', type: 'radio', items: iconTypes },
  {
    id: 'displayRefreshButton',
    title: 'Show refresh button (for IPS Reports widget only)',
    type: 'radio',
    items: showRefreshOptions,
  },
  {
    id: 'postSessionTicket',
    title: 'Post session ticket (for IPS Reports widget only)',
    type: 'radio',
    items: postSessionTicketOptions,
  },
];

const defaultFormData = {
  name: '',
  desc: '',
  url: '',
  groupId: 'All',
  iconUrl: '',
  openMode: 'iframe',
  icon: 'fa-bar-chart-o',
  acsUrl: '',
  acsUri: '',
};

export default class AddEditExternalWebPageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iconType: 'icon',
      postSessionTicket: false,
      displayRefreshButton: false,
      formData: defaultFormData,
      errorData: {},
    };
  }

  componentDidMount() {
    const { formData } = this.props;
    if (formData) {
      const displayRefreshButton = formData.displayRefreshButton === true;
      const postSessionTicket = formData.displayRefreshButton === true;
      this.setState({
        formData: { ...defaultFormData, ...formData },
        displayRefreshButton: displayRefreshButton,
        postSessionTicket: postSessionTicket,
      });
    }
  }

  onCheckboxChanged(id, newValue) {
    this.setState({ [id]: newValue });
  }

  onInputChanged(value, keyId) {
    const newFormData = { ...this.state.formData };
    newFormData[keyId] = value;
    this.setState({ formData: newFormData });
  }

  validate(newID) {
    const { formData } = this.state;
    const newErrorData = {};
    let isValid = true;
    form.forEach(formRow => {
      if (formRow.required && !formData[formRow.id]) {
        newErrorData[formRow.id] = `${formRow.title} cannot be blank`;
        isValid = false;
      }
    });
    if (includes(this.props.value, newID)) {
      newErrorData.duplicate = `${formData.name} is duplicated, please rename`;
      isValid = false;
    }
    this.setState({ errorData: newErrorData });
    return isValid;
  }

  onApply() {
    const { formData, iconType } = this.state;
    const newID = camelCase(formData.name);
    if (this.validate(newID)) {
      const {
        postSessionTicket,
        displayRefreshButton,
        iconUrl,
        icon,
        ...configData
      } = formData;
      if (iconType === 'icon') {
        configData.icon = icon;
      } else if (iconType === 'iconUrl') {
        configData.iconUrl = iconUrl;
      }
      if (configData.acsUri || configData.acsUrl) {
        configData.acsRequired = true;
      } else {
        configData.acsRequired = undefined;
      }
      if (configData.acsUrl === '') {
        configData.acsUrl = undefined;
      }
      if (configData.acsUri === '') {
        configData.acsUri = undefined;
      }
      configData.displayRefreshButton = this.state.displayRefreshButton;
      configData.postSessionTicket = this.state.postSessionTicket;
      this.props.onApply(configData, newID);
    }
  }

  render() {
    const { errorData, formData } = this.state;
    const errors = Object.keys(errorData);
    return (
      <div>
        {errors.length > 0 && (
          <ErrorContainer>
            <ul>
              {errors.map(errKey => (
                <ErrorRow>{errorData[errKey]}</ErrorRow>
              ))}
            </ul>
          </ErrorContainer>
        )}
        {form.map((row, key) => {
          const id = `aeewpm_${row.id}`;
          const formValue = formData[row.id];
          const onChange = event =>
            this.onInputChanged(event.target.value, row.id);
          return (
            <div key={key} style={{ width: '100%', padding: '5px 0' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: 120 }}>
                  {row.title} {row.required && '*'}
                </div>
                {row.type === 'text' && (
                  <div style={{ flex: 1 }}>
                    <TextInput
                      id={id}
                      type={row.type}
                      onChange={onChange}
                      value={formValue}
                    />
                  </div>
                )}
                {row.type === 'checkbox' && (
                  <div style={{ flex: 1, cursor: 'pointer' }}>
                    <input
                      id={row.id}
                      type={row.type}
                      checked={!!formValue}
                      onChange={event =>
                        this.onInputChanged(event.target.checked, row.id)
                      }
                    />
                  </div>
                )}
                {row.type === 'select' && (
                  <ModalSelectBox
                    id={row.id}
                    onChange={onChange}
                    formValue={formValue}
                    selectBoxItems={row.items}
                  />
                )}
                {row.type === 'radio' && (
                  <ModalCheckboxes
                    checkboxItems={row.items}
                    title={row.title}
                    currentValue={this.state[row.id]}
                    onChange={newValue => {
                      this.onCheckboxChanged(row.id, newValue);
                    }}
                  />
                )}
              </div>
              {row.type === 'radio' &&
                row.id === 'iconType' && (
                  <div style={{ marginLeft: 120 }}>
                    {this.state.iconType === 'iconUrl' && (
                      <input
                        style={{ width: '100%' }}
                        type="text"
                        value={formValue}
                        onChange={event =>
                          this.onInputChanged(
                            event.target.value,
                            this.state.iconType
                          )
                        }
                      />
                    )}
                    {this.state.iconType === 'icon' && (
                      <select
                        id={id}
                        name={id}
                        value={formValue}
                        onBlur={event =>
                          this.onInputChanged(
                            event.target.value,
                            this.state.iconType
                          )
                        }
                        onChange={event =>
                          this.onInputChanged(
                            event.target.value,
                            this.state.iconType
                          )
                        }>
                        {icons.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                )}
            </div>
          );
        })}
        <div style={{ textAlign: 'right', marginTop: 10 }}>
          <button className="apply_button btn" onClick={() => this.onApply()}>
            Apply
          </button>
        </div>
      </div>
    );
  }
}

AddEditExternalWebPageModal.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.array,
  formData: PropTypes.object,
};
