import React, { Component } from 'react';
import { PositionProperty } from 'csstype';
import { withStyles } from '@material-ui/core/styles';

import TooltipIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const styles = theme => ({
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 14,
    maxWidth: 480,
    minWidth: 200,
    minHeight: 50,
    padding: '15px',
  },
  arrowPopper: {
    zIndex: 0,
    ...arrowGenerator(theme.palette.grey[300]),
  },
  arrow: {
    position: 'absolute' as PositionProperty,
    fontSize: 6,
    width: '2em',
    height: '2em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
});

class ConfigTooltip extends Component<any, {}> {
  state = {
    arrowRef: null,
    tooltipOpen: false,
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  handleTooltipClose = () => {
    this.setState({ tooltipOpen: false });
  };

  handleTooltipOpen = () => {
    this.setState({ tooltipOpen: true });
  };

  render() {
    const { title, classes } = this.props;
    if (!title) {
      return null;
    } else {
      return (
        <ClickAwayListener onClickAway={this.handleTooltipClose}>
          <Tooltip
            classes={{
              tooltip: classes.lightTooltip,
              popper: classes.arrowPopper,
            }}
            title={
              <>
                {/*
                // @ts-ignore */}
                <span style={{ whiteSpace: 'break-spaces' }}>{`${title}`}</span>
                <span className={classes.arrow} ref={this.handleArrowRef} />
              </>
            }
            PopperProps={{
              popperOptions: {
                modifiers: {
                  arrow: {
                    enabled: Boolean(this.state.arrowRef),
                    element: this.state.arrowRef,
                  },
                },
              },
            }}
            open={this.state.tooltipOpen}
            placement="right"
            disableFocusListener
            disableHoverListener
            disableTouchListener>
            <TooltipIcon
              style={{
                marginLeft: 10,
                width: 20,
                height: 20,
                color: '#888',
                cursor: 'pointer',
              }}
              onClick={this.handleTooltipOpen}
            />
          </Tooltip>
        </ClickAwayListener>
      );
    }
  }
}

export default withStyles(styles)(ConfigTooltip);
