import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputField from './InputField';
import Select from './Select';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import Chips from './Chips';

const styles = theme =>
  createStyles({
    paper: {
      width: '100%',
      maxWidth: 400,
      display: 'flex',
      justifyContent: 'flex-end',
      flexFlow: 'column',
      padding: theme.spacing(0.5),
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  });

interface Props extends WithStyles<typeof styles> {
  propKey: any;
  mergedConfig: any;
  onConfigChanged: (any) => any;
  properties: any;
  collection: any;
}

export class ArrayComponent extends Component<Props, any> {
  constructor(props) {
    super(props);

    this.onKeyPress = this.onKeyPress.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.state = {
      selectedValue: '',
      inputValue: '',
    };
  }

  handleDelete(index) {
    const { propKey, mergedConfig, onConfigChanged } = this.props;
    const config = mergedConfig[propKey].slice(0);
    if (index > -1 && index < config.length) {
      config.splice(index, 1);
      onConfigChanged({ [propKey]: config });
    }
  }

  handleAdd(data) {
    if (!_.isEmpty(data)) {
      const { propKey, mergedConfig, onConfigChanged } = this.props;
      let config = mergedConfig[propKey];
      if (config !== undefined && Array.isArray(config)) {
        config = mergedConfig[propKey].slice(0);
      } else {
        config = [];
      }
      config.push(data);
      onConfigChanged({ [propKey]: config });
      this.setState({ inputValue: '' });
    }
  }

  handleMove(index, indexBefore) {
    const { propKey, mergedConfig, onConfigChanged } = this.props;
    const config = mergedConfig[propKey].slice(0);
    index >= indexBefore
      ? config.splice(indexBefore, 0, config.splice(index, 1)[0])
      : config.splice(indexBefore - 1, 0, config.splice(index, 1)[0]);
    onConfigChanged({ [propKey]: config });
  }

  onChange(inputValue) {
    this.setState({ inputValue });
  }

  onKeyPress = event => {
    const inputTextValue = event.target.value.trim();
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleAdd(inputTextValue);
    }
  };

  onSelectChange = event => {
    const selectedValue = event.target.value.trim();
    const { propKey, mergedConfig } = this.props;
    if (selectedValue && !_.includes(mergedConfig[propKey], selectedValue)) {
      this.handleAdd(selectedValue);
    }
    this.setState({ selectedValue: null });
  };

  render() {
    const {
      classes,
      mergedConfig,
      propKey,
      properties,
      collection,
    } = this.props;
    let config = mergedConfig[propKey];

    if (config === undefined) {
      console.warn(`Setting ${propKey} is not defined, assumed an empty list`);
      config = [];
    }

    if (!Array.isArray(config)) {
      console.error(`Setting ${propKey} is not an array`);
      return null;
    }

    const selectId = propKey.replace(/[\s\\\/]/g, '').toLowerCase();
    let menuItems;
    if (collection) {
      menuItems = collection
        // filter out already selected items
        .filter(item => config.indexOf(item) === -1)
        .map(item => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        });
      menuItems.unshift(
        <MenuItem key="select_to_add" value="">
          <em>Select to add</em>
        </MenuItem>
      );
    }

    const ListItemChildren = (
      <Paper className={classes.paper}>
        <div style={{ textAlign: 'right' }}>
          {config.length === 0 ? (
            <span style={{ color: '#777' }}>(Empty List)</span>
          ) : (
            <Chips
              list={config}
              handleDelete={this.handleDelete.bind(this)}
              handleMove={this.handleMove.bind(this)}
              dndType={propKey}
              reorderable={!!properties[propKey].reorderable}
            />
          )}
        </div>
        {collection ? null : (
          <div style={{ display: 'flex', marginTop: 10 }}>
            <div style={{ flex: 1 }} />
            <InputField
              value={this.state.inputValue}
              placeholder="Enter value to add"
              onKeyPress={this.onKeyPress}
              onChange={value => this.onChange(value)}
              transform={properties[propKey].transform}
            />
            <button
              onClick={event => this.handleAdd(this.state.inputValue)}
              className="btn btn-primary"
              style={{ marginLeft: 5 }}>
              Add
            </button>
          </div>
        )}
      </Paper>
    );

    const ListItemComponent = collection ? (
      <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
        {ListItemChildren}
      </ListItem>
    ) : (
      <>{ListItemChildren}</>
    );

    const SelectionList = collection ? (
      <ListItem style={{ marginLeft: 150, paddingTop: 0, paddingBottom: 0 }}>
        <Select
          inputLabel={{
            htmlFor: selectId,
            text: 'Select to add',
            inputProps: { name: 'selectedValue', id: selectId },
          }}
          menuItems={menuItems}
          onChange={this.onSelectChange}
          value={this.state.selectedValue}
        />
      </ListItem>
    ) : null;

    const ListWrapperComponent = collection ? (
      <List style={{ padding: 0 }}>
        {SelectionList}
        {ListItemComponent}
      </List>
    ) : (
      <>
        {SelectionList}
        {ListItemComponent}
      </>
    );

    return ListWrapperComponent;
  }
}

export default withStyles(styles)(ArrayComponent);
