import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from '../common-styles';
import NativeSelect from '@material-ui/core/NativeSelect';

import { cacheImageLocally } from '../../_ducks/uploadFile';
import { configModalActions } from '../../_ducks/configModal';
import { configDataActions } from '../../_ducks/configData';
import { filterS3ObjectsByFolder } from '../../utils/filterS3ObjectsByFolder';

const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
const s3UrlPrefix = configApp.configuration.assetsBucketUrl;

const TransparentImageBackground = styled.img`
  background-image: linear-gradient(45deg, #efefef 25%, transparent 25%),
    linear-gradient(-45deg, #efefef 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #efefef 75%),
    linear-gradient(-45deg, transparent 75%, #efefef 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`;

export class FileUploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageSrc: null,
      invalidFile: false,
      invalidMessage: '',
      file: null,
      fileUrl: null,
      fileUploadMode: null,
    };
  }

  componentDidMount() {
    const { imageData, fileUrl } = this.props.formData;
    if (imageData) {
      this.setState({
        fileUploadMode: 'new',
        imageSrc: imageData.fileAsBase64,
        file: imageData.file,
      });
    } else if (fileUrl) {
      this.setState({
        fileUploadMode: 'existing',
        fileUrl,
      });
    }
  }

  showImage({ currentTarget }) {
    if (currentTarget.files && currentTarget.files[0]) {
      const file = currentTarget.files[0];
      const { size, type } = file;
      if (!validImageTypes.includes(type)) {
        this.setState({
          imageSrc: null,
          invalidFile: true,
          invalidMessage: 'File must be an image',
          file: null,
        });
      } else if (size > 2000000) {
        // Greater than 2MB
        this.setState({
          imageSrc: null,
          invalidFile: true,
          invalidMessage: 'File must smaller than 2MB',
          file: null,
        });
      } else {
        const reader = new FileReader();

        reader.onload = event => {
          this.setState({
            fileUploadMode: 'new',
            imageSrc: event.target.result,
            invalidFile: false,
            invalidMessage: '',
            file: currentTarget.files[0],
          });
        };

        reader.readAsDataURL(currentTarget.files[0]);
      }
    }
  }

  onFileChosen(value) {
    if (!value) {
      this.setState({ fileUploadMode: null, fileUrl: null });
    } else {
      this.setState({ fileUploadMode: 'existing', fileUrl: value });
    }
  }

  onOk() {
    const { id } = this.props.formData;
    const { file, imageSrc } = this.state;
    this.props.cacheImageLocally({
      id,
      file,
      fileAsBase64: imageSrc,
    });
    this.props.onConfigChanged({ [id]: file.name });
    this.props.toggleConfigModal({ showConfigModal: false });
  }

  onChooseExistingImage() {
    const { id } = this.props.formData;
    // Clear cached image
    this.props.cacheImageLocally({ id });
    this.props.onConfigChanged({ [id]: s3UrlPrefix + this.state.fileUrl });
    this.props.toggleConfigModal({ showConfigModal: false });
  }

  render() {
    const imageItems = filterS3ObjectsByFolder(
      this.props.imageItems,
      'CompanyLogos'
    ).map(item => {
      const imageUrlSplit = item.Key.split(`/`);
      const imageName = imageUrlSplit[imageUrlSplit.length - 1];
      return (
        <option value={item.Key} key={item.Key}>
          {imageName}
        </option>
      );
    });
    imageItems.unshift(<option value="" key="__empty" />);
    return (
      <div>
        <Label>Add a new image</Label>
        <input
          style={{ marginBottom: 10 }}
          type="file"
          onChange={input => this.showImage(input)}
        />
        <Label style={{ textAlign: 'center', padding: '5px 0px' }}>OR</Label>
        <Label>Choose from an existing image</Label>
        <NativeSelect
          style={{ width: '100%', fontSize: 12 }}
          onChange={event => {
            this.onFileChosen(event.target.value);
          }}
          inputProps={{
            name: 'select',
            id: 'select',
          }}
          value={this.state.fileUrl || ''}>
          {imageItems}
        </NativeSelect>
        {this.state.invalidFile && (
          <div style={{ marginTop: 15, color: 'red' }}>
            {this.state.invalidMessage}
          </div>
        )}
        {this.state.imageSrc &&
          this.state.fileUploadMode === 'new' && (
            <div style={{ marginTop: 15 }}>
              <img
                alt="file"
                style={{ width: '100%' }}
                src={this.state.imageSrc}
              />
              <button
                className="btn btn-primary"
                style={{ marginTop: 5, float: 'right' }}
                onClick={() => this.onOk()}>
                Ok
              </button>
            </div>
          )}
        {this.state.fileUploadMode === 'existing' && (
          <div style={{ marginTop: 15 }}>
            <TransparentImageBackground
              alt="file"
              style={{ width: '100%' }}
              src={s3UrlPrefix + this.state.fileUrl}
            />
            <button
              className="btn btn-primary"
              style={{ marginTop: 5, float: 'right' }}
              onClick={() => this.onChooseExistingImage()}>
              Ok
            </button>
          </div>
        )}
      </div>
    );
  }
}

FileUploadModal.propTypes = {
  formData: PropTypes.object,
  cacheImageLocally: PropTypes.func,
  toggleConfigModal: PropTypes.func,
  onConfigChanged: PropTypes.func,
  imageItems: PropTypes.array,
};

FileUploadModal.defaultProps = {
  imageItems: [],
};

const mapStateToProps = state => {
  return {
    ...state.configModal,
    imageItems: state.uploadFile.iressAssetsS3Objects,
  };
};

const mapDispatchToProps = {
  cacheImageLocally,
  onConfigChanged: configDataActions.modifyConfig,
  toggleConfigModal: configModalActions.toggleConfigModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadModal);
