import React, { Component } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBoxContainer = styled.div`
  width: 100%;
  position: relative;
`;

const SearchBox = styled.input`
  width: 100%;
  border-radius: 2px;
  outline: none;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  text-indent: 20px;
  position: relative;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  font-size: 18px;
  transform: translate(0, -50%);
`;

export class Search extends Component<
  { value: string; onChange: Function },
  { value: string }
> {
  onChangeDebounced;
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.onChangeDebounced = debounce(props.onChange, 200);
  }
  onChangeValue(event) {
    this.setState({ value: event.target.value });
    this.onChangeDebounced(event.nativeEvent);
  }
  render() {
    return (
      <SearchBoxContainer>
        <SearchBox
          value={this.state.value}
          placeholder="Search"
          onChange={event => this.onChangeValue(event)}
        />
        <SearchIconContainer>
          <FontAwesomeIcon style={{ fontSize: 18 }} icon={faSearch} />
        </SearchIconContainer>
      </SearchBoxContainer>
    );
  }
}
