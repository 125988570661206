import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '../common-styles';
import styled from 'styled-components';

export const ValueDiv = styled.div`
  margin-right: 20px;
`;

export default function EditButtonConfig(props) {
  const { onClick, value } = props;
  return (
    <div style={{ display: 'flex' }}>
      {value && <ValueDiv>{value}</ValueDiv>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <div>
          <IconButton onClick={onClick} />
        </div>
      </div>
    </div>
  );
}

EditButtonConfig.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.any,
};
