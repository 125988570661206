import { CHANGE_FILE_TYPE } from './changeFileType';
import { CLEAR_CONFIG } from './configData';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const SET_IRESS_ASSETS_S3_OBJECTS = 'SET_IRESS_ASSETS_S3_OBJECTS';
export const SET_SELECTED_BUCKET = 'SET_SELECTED_BUCKET';
export const CACHE_IMAGE_LOCALLY = 'CACHE_IMAGE_LOCALLY';
export const UPLOAD_MULTIPLE_FILES = 'UPLOAD_MULTIPLE_FILES';
export const SET_CURRENTLY_UPLOADING_FILE = 'SET_CURRENTLY_UPLOADING_FILE';

const initialState = {
  uploadFileFormData: null,
  iressAssetsS3Objects: [],
  cachedImages: {},
  selectedBucket: configApp.configuration.assetsBucketName,
  selectedFileType: 'CompanyLogos',
  multiUploadFormData: [],
  currentlyUploadingFile: '',
};

export function uploadFile(formData) {
  return { type: UPLOAD_FILE, payload: formData };
}

export function uploadMultipleFiles(formData) {
  return { type: UPLOAD_MULTIPLE_FILES, payload: formData };
}

export function setIressAssetsS3Objects(objects) {
  return { type: SET_IRESS_ASSETS_S3_OBJECTS, payload: objects };
}

export function setCurrentlyUploadingFile(fileName) {
  return { type: SET_CURRENTLY_UPLOADING_FILE, payload: fileName };
}

export function setSelectedBucket(bucketName) {
  return { type: SET_SELECTED_BUCKET, payload: bucketName };
}

export function cacheImageLocally(payload) {
  return { type: CACHE_IMAGE_LOCALLY, payload };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CACHE_IMAGE_LOCALLY: {
      const newCachedImages = { ...state.cachedImages };
      const { id, file, fileAsBase64 } = action.payload;
      if (!fileAsBase64 && !file) {
        delete newCachedImages[id];
        return { ...state, cachedImages: newCachedImages };
      }
      newCachedImages[id] = { id, file, fileAsBase64 };
      return { ...state, cachedImages: newCachedImages };
    }
    case CLEAR_CONFIG: {
      return { ...state, cachedImages: {} };
    }
    case UPLOAD_FILE: {
      return { ...state, uploadFileFormData: action.payload };
    }
    case SET_IRESS_ASSETS_S3_OBJECTS: {
      return { ...state, iressAssetsS3Objects: action.payload };
    }
    case SET_SELECTED_BUCKET: {
      return { ...state, selectedBucket: action.payload };
    }
    case CHANGE_FILE_TYPE: {
      return { ...state, selectedFileType: action.payload };
    }
    case UPLOAD_MULTIPLE_FILES: {
      return { ...state, multiUploadFormData: action.payload };
    }
    case SET_CURRENTLY_UPLOADING_FILE: {
      return { ...state, currentlyUploadingFile: action.payload };
    }
    default:
      return state;
  }
}
