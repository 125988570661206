import React from 'react';
import PropTypes from 'prop-types';
import ExpandableListCell from './ExpandableListCell';

export function CompanyCell(props) {
  const { extra } = props;
  if (Array.isArray(extra.companies)) {
    const entries = extra.companies.map(company =>
      decodeURIComponent(company.name)
    );
    return (
      <span>
        <ExpandableListCell entries={entries} />
      </span>
    );
  }
  return <li>{decodeURIComponent(extra.companyName)}</li>;
}

CompanyCell.propTypes = {
  extra: PropTypes.object.isRequired,
};
