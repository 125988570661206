import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Redo';
import ViewIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { isString } from 'lodash';

import ProgressBar from './ProgressBar';
import { GroupCell } from './GroupCell';
import { UserCell } from './UserCell';
import FuzzyTimeStamp from './Common/FuzzyTimeStamp';
import { configDataActions } from '../_ducks/configData';
import { actions, FILTER } from '../_ducks/pipelineStatus';
import { CompanyCell } from './CompanyCell';
import { getAppProperty } from '../utils/apps';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    fontSize: 55,
  },
  progressbar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 1,
  },
  redoIcon: {
    fontSize: 10,
  },
  tableCellRedoButton: {
    fontSize: 20,
  },
  redoButton: {
    maxWidth: '30px',
    maxHeight: '30px',
    minWidth: '30px',
    minHeight: '30px',
    marginLeft: '10px',
  },
  tableCell: {
    position: 'relative',
    fontSize: 12,
  },
  tableCellNoWrap: {
    position: 'relative',
    fontSize: 12,
  },
  tableHeader: {
    fontWeight: 700,
    fontSize: 14,
    color: '#333',
  },
  toggleButton: {
    fontWeight: 700,
    fontSize: '0.9em',
  },
});

const REFRESH_RATE = 15000;
function extractExtraInfo(row) {
  let extra = {};
  try {
    extra = JSON.parse(row.changeContent);
  } catch (e) {
    console.log('content is not a valid json:' + e);
  }
  return extra;
}

class ProgressTable extends React.Component {
  constructor(props) {
    super(props);
    this.props.requestUpdates();
    this.timer = setInterval(() => {
      if (document.visibilityState === 'visible') {
        this.props.requestUpdates.bind(this);
      }
    }, REFRESH_RATE);
    this.state = {
      selectedRow: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initialRequestsCompleted && !this.props.configSchema) {
      this.props.requestSchema();
    }

    // Request the pipeline config if we have the environment
    // and if either the app or environment changed.
    if (this.props.environment) {
      const appChanged = this.props.selectedApp !== prevProps.selectedApp;
      const filterEnvironment = getAppProperty(
        this.props.selectedApp,
        'filterEnvironment',
        false
      );
      const environmentChanged =
        filterEnvironment &&
        (!prevProps.environment ||
          this.props.environment.key !== prevProps.environment.key);

      if (appChanged || environmentChanged) {
        this.props.requestUpdates();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  reApplyChange(change) {
    const confirmResult = window.confirm(
      'Are you sure you want to re-apply this configuration?'
    );
    change = JSON.parse(change);
    ['add', 'del'].forEach(field => {
      if (isString(change[field])) change[field] = JSON.parse(change[field]);
    });
    if (confirmResult === true) {
      this.props.reapplyChange(JSON.stringify(change));
    }
  }

  getFilteredRecords() {
    let { userEmail, filter, records, selectedApp } = this.props;
    let userEmailAU = userEmail.replace(/\.com$/, '.com.au');

    records = records
      .map(record => {
        if (!record?.extra) {
          // record.extra should have already have been set previously,
          // but this will create it now if it wasn't for some reason...
          record.extra = extractExtraInfo(record);
        }
        return record;
      })
      .filter(record => record.extra.app === selectedApp);

    const recordsToShow =
      filter === FILTER.MINE
        ? records.filter(
            ({ author }) => author === userEmailAU || author === userEmail
          )
        : records;

    return recordsToShow;
  }

  showDetails(row) {
    this.setState({
      selectedRow: row,
    });
  }

  render() {
    const { classes, filter, configSchema } = this.props;
    if (!configSchema) {
      return null;
    }

    const { selectedRow } = this.state;
    const recordsToShow = this.getFilteredRecords();
    const progressPageMessage = getAppProperty(
      this.props.selectedApp,
      'progressPageMessage',
      ''
    );

    const supportSlackChannel = getAppProperty(
      this.props.selectedApp,
      'progressPageSupportSlackChannel',
      {}
    );
    return (
      <React.Fragment>
        {progressPageMessage !== '' && <h5>{progressPageMessage}</h5>}
        {supportSlackChannel &&
          supportSlackChannel.name &&
          supportSlackChannel.link && (
            <h5>
              Contact us on slack if you encounter issues or your request takes
              longer than 30 minutes at{' '}
              <a target="_blank" href={supportSlackChannel.link}>
                {supportSlackChannel.name}
              </a>
            </h5>
          )}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Company</TableCell>
                <TableCell className={classes.tableHeader}>Group</TableCell>
                <TableCell className={classes.tableHeader}>User</TableCell>
                <TableCell className={classes.tableHeader}>Created</TableCell>
                <TableCell className={classes.tableHeader}>
                  Last Updated
                </TableCell>
                {filter !== FILTER.MINE && (
                  <TableCell className={classes.tableHeader}>Author</TableCell>
                )}
                <TableCell className={classes.tableHeader}>
                  Tracking ID
                </TableCell>
                <TableCell className={classes.tableHeader}>Progress</TableCell>
                <TableCell className={classes.tableHeader}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordsToShow.map((row, index) => {
                const extra = row.extra;
                const companies = <CompanyCell extra={extra} />;

                let groups = <GroupCell extra={extra} />;
                let users = <UserCell extra={extra} />;

                const changeContentText = JSON.stringify(extra, undefined, 4);
                const isError = /error/i.test(row.status);
                const splitTrackingId = row.pipelineId.split('_');
                const trackingId = splitTrackingId[splitTrackingId.length - 1];

                return (
                  <TableRow
                    key={row.pipelineId}
                    className={
                      row.statusSequence === '100'
                        ? classes.tableRowCompleted
                        : undefined
                    }>
                    <TableCell className={classes.tableCellNoWrap}>
                      {companies}
                    </TableCell>
                    <TableCell className={classes.tableCellNoWrap}>
                      {groups}
                    </TableCell>
                    <TableCell className={classes.tableCellNoWrap}>
                      {users}
                    </TableCell>
                    <TableCell scope="row" className={classes.tableCell}>
                      <FuzzyTimeStamp time={row.created} />
                    </TableCell>

                    <TableCell scope="row" className={classes.tableCell}>
                      <FuzzyTimeStamp time={row.lastModified} />
                    </TableCell>

                    {filter !== FILTER.MINE && (
                      <TableCell className={classes.tableCell}>
                        {row.author}
                      </TableCell>
                    )}
                    <TableCell scope="row" className={classes.tableCell}>
                      {trackingId}
                    </TableCell>
                    <TableCell
                      title={changeContentText}
                      className={classes.tableCell}>
                      <span
                        style={{
                          position: 'relative',
                          fontSize: 12,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '270px',
                        }}>
                        <ProgressBar isError={isError} status={row.status} />
                      </span>
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {Array.isArray(extra.diff) && (
                        <IconButton
                          aria-label="View change"
                          title="View change"
                          size="small"
                          onClick={() => this.showDetails(extra)}>
                          <ViewIcon />
                        </IconButton>
                      )}
                      {this.props.isTechUser && (
                        <IconButton
                          aria-label="Edit"
                          title="Re-apply this configuration"
                          size="small"
                          onClick={() => this.reApplyChange(row.changeContent)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        {selectedRow &&
          Array.isArray(selectedRow.diff) && (
            <Dialog
              open={!!selectedRow}
              onClose={() => this.setState({ selectedRow: null })}
              aria-labelledby="View change details"
              maxWidth="md">
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        Setting
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Old Value
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        New Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedRow.diff.map(entry => (
                      <TableRow key={entry.name}>
                        <TableCell className={classes.tableCell}>
                          {configSchema.properties[entry.name].description}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {JSON.stringify(
                            entry.value.old !== undefined
                              ? entry.value.old
                              : '-'
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {JSON.stringify(
                            entry.value.new !== undefined
                              ? entry.value.new
                              : '-'
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>
            </Dialog>
          )}
      </React.Fragment>
    );
  }
}

ProgressTable.propTypes = {
  classes: PropTypes.object.isRequired,
  requestUpdates: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  reapplyChange: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  isTechUser: PropTypes.bool.isRequired,
};

const FilterToggle = props => {
  const { value, onChange, options, btnClass } = props;

  return (
    <ToggleButtonGroup
      className="pull-right"
      value={value}
      exclusive
      onChange={(event, filter) => onChange(filter)}>
      {options.map(option => {
        return (
          <ToggleButton
            className={btnClass}
            disableRipple
            value={option}
            key={option}>
            {option}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

FilterToggle.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  btnClass: PropTypes.string,
};

export default connect(
  state => ({
    userEmail: state.auth.user ? state.auth.user.email : '',
    records: state.pipelineStatus.records,
    filter: state.pipelineStatus.filter,
    isTechUser: state.auth.isTechUser,
    configSchema: state.configData.configSchema,
    initialRequestsCompleted: state.app.initialRequestsCompleted,
    selectedApp: state.configSelection.selectedApp,
    environment: state.configSelection.environment,
  }),
  {
    requestUpdates: actions.requestUpdates,
    updateFilter: actions.updateFilter,
    reapplyChange: actions.reapplyChange,
    requestSchema: configDataActions.requestSchema,
  }
)(withStyles(styles)(ProgressTable));
