import { noop } from 'lodash';
import { CLEAR_CONFIG } from './configData';

export const SHOW_CONFIG_MODAL = 'configModal/SHOW_CONFIG_MODAL';
export const TOGGLE_CONFIG_MODAL = 'configModal/TOGGLE_CONFIG_MODAL';
export const FORM_DATA_CHANGED = 'configModal/FORM_DATA_CHANGED';
export const HIDE_CONFIG_MODAL = 'configModal/HIDE_CONFIG_MODAL';
export const SUBMIT = 'configModal/SUBMIT';

export const configModalActions = {
  formDataChanged: value => ({ type: FORM_DATA_CHANGED, payload: value }),
  toggleConfigModal: value => ({ type: TOGGLE_CONFIG_MODAL, payload: value }),
  onSubmit: value => ({ type: SUBMIT, payload: value }),
  onHideConfigModal: payload => ({ type: HIDE_CONFIG_MODAL, payload }),
  onShowConfigModal: payload => ({ type: SHOW_CONFIG_MODAL, payload }),
};

const initialState = {
  showConfigModal: false,
  modalContent: null,
  configModalCallback: noop,
  externalWebPageId: null,
  mode: 'Add',
  formData: {},

  modalProps: {},
  modalType: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FORM_DATA_CHANGED: {
      return { ...state, formData: action.payload };
    }
    case CLEAR_CONFIG: {
      return { ...state, formData: null };
    }
    case TOGGLE_CONFIG_MODAL: {
      return { ...state, ...initialState, ...action.payload };
    }
    case HIDE_CONFIG_MODAL: {
      return {
        ...state,
        showConfigModal: false,
        modalProps: {},
        modalType: null,
      };
    }
    case SHOW_CONFIG_MODAL: {
      const { modalProps, modalType } = action.payload;
      return { ...state, showConfigModal: true, modalProps, modalType };
    }
    default:
      return state;
  }
}
