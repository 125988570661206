import React from 'react';
import PropTypes from 'prop-types';

const CompanyGroupHeading = function(props) {
  return (
    <div style={{ marginBottom: 15 }}>
      <div style={{ fontSize: 35 }}>{props.selectedCompanyName}</div>
      <div style={{ fontSize: 16 }}>{props.selectedGroupName}</div>
    </div>
  );
};

CompanyGroupHeading.propTypes = {
  selectedCompanyName: PropTypes.string,
  selectedGroupName: PropTypes.string,
};

export default CompanyGroupHeading;
