import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import rootSaga from '../sagas';
import rootReducer from '../_ducks';

export default function configureStore(history) {
  const saga = createSagaMiddleware({
    context: { history },
  });

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(saga))
  );

  saga.run(rootSaga);
  return store;
}
