import React, { Component } from 'react';
import { difference, includes, some, camelCase } from 'lodash';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ConfigRow } from './form-styles';
import EditButtonConfig from '../Edit/EditButtonConfig';
import UndoButton from '../Edit/UndoButton';
import { alphaSort } from '../common';
import { CenteredFlex, IconButton } from '../common-styles';
import ConfigTooltip from './ConfigTooltip';

export default class ExternalContent extends Component<any, {}> {
  externalPagesTitleMap = {
    'Default/externalPagesInContext': 'External pages in context',
    'Default/externalPagesInLibrary': 'External pages in library',
  };

  render() {
    const {
      mergedConfig,
      modifiedConfig,
      sideEffectConfig,
      sideEffectConfigMap,
      properties,
      title,
      onHideConfigModal,
      onShowConfigModal,
      onSideEffectConfigChanged,
    } = this.props;

    const identifier = 'Default/externalWebPageInfos/';

    const externalWebPages = Object.keys(properties);
    return externalWebPages.map((configId, index) => {
      const { modalType, baseType, longDescription } = properties[configId];
      const configValue = mergedConfig[configId];

      const onEditExternalWebPage = (newValue, externalPageId) => {
        onSideEffectConfigChanged({
          configId,
          sideEffectConfig: { [externalPageId]: newValue },
        });
      };

      const items = {};
      const identifier = 'Default/externalWebPageInfos/';

      Object.keys(mergedConfig).forEach(config => {
        if (config.includes(identifier)) {
          items[config.replace(identifier, '')] = mergedConfig[config];
        }
      });

      const newConfigValues = [];
      configValue.forEach(id => {
        const configId = `${identifier}${id}`;
        const confVal = sideEffectConfig[configId] || mergedConfig[configId];
        confVal && newConfigValues.push({ id: configId, name: confVal.name });
      });
      const configValues = newConfigValues
        .sort((a, b) => alphaSort(a.name, b.name))
        .map(({ id, name }, index) => (
          <li key={index}>
            {name} {sideEffectConfig[id] && '(edited)'}
          </li>
        ));

      const DetailComponent =
        configValues.length > 0 ? (
          <ul>{configValues}</ul>
        ) : (
          <div>
            <span style={{ color: '#777' }}>(Empty List)</span>
          </div>
        );

      const isModified = !!(
        modifiedConfig.hasOwnProperty(configId) ||
        Object.keys(sideEffectConfigMap).find(
          key => sideEffectConfigMap[key] === configId
        )
      );

      const onAddExternalWebPage = (newValue, newID) => {
        newValue.namespace = `Widgets.Iframe.${camelCase(newValue.name)}`;
        const newConfigValue = configValue.slice(0);
        const newConfigID = `${identifier}${newID}`;
        newConfigValue.push(newID);
        this.props.onSideEffectConfigChanged({
          configId,
          sideEffectConfig: { [newConfigID]: newValue },
        });
        this.props.onConfigChanged({ [configId]: newConfigValue });
        onHideConfigModal();
      };

      return (
        <ConfigRow key={index} style={{ padding: 20 }} isModified={isModified}>
          <div style={{ display: 'flex' }}>
            <h4 style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              {this.externalPagesTitleMap[configId]}
              <ConfigTooltip title={longDescription} />
            </h4>
            <CenteredFlex style={{ marginRight: 20 }}>
              {isModified && (
                <UndoButton
                  onConfigChanged={config =>
                    this.props.onConfigChanged({ [configId]: undefined })
                  }
                />
              )}
            </CenteredFlex>
            <CenteredFlex>
              <div>
                <IconButton
                  icon={faPlus}
                  onClick={() =>
                    onShowConfigModal({
                      modalType: 'Add/Edit External Web Page',
                      modalProps: {
                        value: modifiedConfig[configId],
                        onApply: onAddExternalWebPage,
                      },
                    })
                  }
                />
              </div>
            </CenteredFlex>
            <CenteredFlex>
              <EditButtonConfig
                configId={configId}
                onClick={() => {
                  const showConfigModalProps = {
                    modalType,
                    modalProps: {
                      items,
                      value: configValue,
                      identifier,
                      onApply: newValue => {
                        this.props.onConfigChanged({ [configId]: newValue });
                        onHideConfigModal();
                      },
                      onEditClicked: externalPageId => {
                        const externalPageConfigId = `${identifier}${externalPageId}`;
                        const formData = mergedConfig[externalPageConfigId];
                        onShowConfigModal({
                          modalType: 'Add/Edit External Web Page',
                          modalProps: {
                            formData,
                            onApply: args => {
                              onEditExternalWebPage(args, externalPageConfigId);
                              onShowConfigModal(showConfigModalProps);
                            },
                          },
                        });
                      },
                    },
                  };
                  onShowConfigModal(showConfigModalProps);
                }}
              />
            </CenteredFlex>
          </div>
          {DetailComponent}
        </ConfigRow>
      );
    });
  }
}
