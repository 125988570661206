import React from 'react';
import PropTypes from 'prop-types';

export function ModalSelectBox({ id, onChange, formValue, selectBoxItems }) {
  return (
    <div style={{ flex: 1 }}>
      <select
        id={id}
        name={id}
        onBlur={onChange}
        onChange={onChange}
        value={formValue}>
        {selectBoxItems.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}

ModalSelectBox.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  formValue: PropTypes.any,
  selectBoxItems: PropTypes.any,
};
