import React from 'react';
import SelectBoxForStringSettings from './SelectBoxForStringSettings';
import String from './String';

export default function getStringComponent(key, props) {
  if (props.properties[key].selectOptionList) {
    const menuItems =
      props.mergedConfig[props.properties[key].selectOptionList];
    const currentValue = props.mergedConfig[key];
    return (
      <SelectBoxForStringSettings
        menuItems={menuItems.map(value => ({ label: value, value }))}
        propKey={key}
        currentValue={currentValue}
        schemaDefinition={props.properties[key]}
        {...props}
      />
    );
  } else {
    return <String propKey={key} {...props} />;
  }
}
