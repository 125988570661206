import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { PropTypes } from 'prop-types';

class ExpandCollapseButton extends React.Component {
  constructor(props) {
    super(props);
    this.expanded = props.expanded ? props.expanded : false;
  }

  render() {
    if (!this.expanded) {
      return (
        <Fab
          style={this.props.style}
          size="small"
          color="primary"
          aria-label="Expand"
          title="Expand"
          onClick={() => {
            this.expanded = true;
            this.props.onExpand();
          }}>
          <AddIcon />
        </Fab>
      );
    } else {
      return (
        <Fab
          style={this.props.style}
          size="small"
          color="primary"
          aria-label="Collapse"
          title="Collapse"
          onClick={() => {
            this.expanded = false;
            this.props.onCollapse();
          }}>
          <RemoveIcon />
        </Fab>
      );
    }
  }
}

ExpandCollapseButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
};

export default ExpandCollapseButton;
