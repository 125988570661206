import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

export default class TextArea extends Component<any, any> {
  errorMessage = 'Please enter a valid layout template.';

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      input: '',
    };
  }

  onChange(event) {
    const { onConfigChanged, propKey } = this.props;
    switch (propKey) {
      case 'PcFull/viewStates|C:masterFrame':
        // default layout configuration
        if (this.validateDefaultLayoutInput(event.target.value)) {
          // modify config if input is valid, otherwise display an error message
          onConfigChanged({
            [propKey]: !event.target.value
              ? ''
              : JSON.parse(event.target.value),
          });
          this.setState({ error: false });
          this.props.clearError(propKey);
        } else {
          this.setState({ error: true });
          this.props.addError(propKey, this.errorMessage);
        }
        this.setState({ input: event.target.value });
        break;
      default:
    }
  }

  validateDefaultLayoutInput(str) {
    // has to be valid JSON and an array
    try {
      const jsonInput = JSON.parse(str);
      return (
        Array.isArray(jsonInput) &&
        jsonInput[0].hasOwnProperty('name') &&
        jsonInput[0].hasOwnProperty('svg') &&
        jsonInput[0].hasOwnProperty('viewData')
      );
    } catch (e) {
      return false;
    }
  }

  render() {
    const { mergedConfig, propKey } = this.props;
    const { error, input } = this.state;
    return (
      <div style={{ flex: 1 }}>
        <TextField
          multiline
          rows="11"
          variant="outlined"
          margin="normal"
          error={error}
          style={{ width: '100%' }}
          value={
            error
              ? input
              : mergedConfig[propKey]
                ? JSON.stringify(mergedConfig[propKey])
                : ''
          }
          onChange={this.onChange.bind(this)}
          helperText={error ? this.errorMessage : ''}
        />
      </div>
    );
  }
}
