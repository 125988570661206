import _ from 'lodash';
import React from 'react';
import ChipArray from './Array';

export default function getArrayComponent(
  key,
  defaultConfig,
  props,
  settingProperties
) {
  let ConfigComponent;
  if (settingProperties.selectOptionIdentifier) {
    const collection = _.filter(
      Object.keys(props.mergedConfig),
      name => name.indexOf(settingProperties.selectOptionIdentifier) === 0
    ).map(item =>
      item.slice(settingProperties.selectOptionIdentifier.length, item.length)
    );

    ConfigComponent = (
      <ChipArray propKey={key} {...props} collection={collection} />
    );
  } else if (settingProperties.items) {
    // Array setting with enum listing possible values
    ConfigComponent = (
      <ChipArray
        propKey={key}
        collection={settingProperties.items}
        {...props}
      />
    );
  } else {
    ConfigComponent = <ChipArray propKey={key} {...props} />;
  }
  return ConfigComponent;
}
