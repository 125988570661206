import React from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

const FuzzyTimeStamp = ({ time }) => (
  <abbr title={time}>
    {/* Why aren't we using ISO format? */}
    {DateTime.fromSQL(time.replace(/\//g, '-')).toRelative()}
  </abbr>
);

FuzzyTimeStamp.propTypes = {
  time: PropTypes.string.isRequired,
};

export default FuzzyTimeStamp;
