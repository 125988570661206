import { getAppProperty, initiallySelectedAppName } from '../utils/apps';

const ns = 'status';

export const UPDATE_FILTER = `${ns}/UPDATE_FILTER`;
export const FETCH_RECORDS = `${ns}/FETCH_RECORDS`;
export const UPDATE_RECORDS = `${ns}/UPDATE_RECORDS`;
export const REAPPLY_CHANGE = `${ns}/REAPPLY_CHANGE`;

export const FILTER = {
  MINE: 'mine',
  ALL: 'all',
};

const initialState = {
  records: [],
  filter: getAppProperty(initiallySelectedAppName, 'filter', FILTER.MINE),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILTER: {
      return { ...state, filter: action.payload };
    }

    case UPDATE_RECORDS: {
      return { ...state, records: action.payload };
    }

    default:
      return state;
  }
}

export const actions = {
  requestUpdates: () => ({ type: FETCH_RECORDS }),
  updateRecords: payload => ({ type: UPDATE_RECORDS, payload }),
  updateFilter: payload => ({ type: UPDATE_FILTER, payload }),
  reapplyChange: payload => ({ type: REAPPLY_CHANGE, payload }),
};
