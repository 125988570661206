import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ModalBackdrop, ModalContent } from '../common-styles';
import { configModalActions } from '../../_ducks/configModal';
import Form from 'react-jsonschema-form';

export default class ColorConfigModal extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        ...{
          name: '',
          displayName: '',
          colorConfig: '',
        },
        ...this.props.formData,
      },
    };
  }

  onFormDataChanged = ({ formData }) => {
    this.setState({ formData });
  };

  onSubmit({ formData }) {
    this.props.onApply(formData);
  }

  schema = {
    properties: {
      name: {
        title: 'Name',
        type: 'string',
      },
      displayName: {
        title: 'Display Name',
        type: 'string',
      },
      colorConfig: {
        title: 'Color Config',
        type: 'string',
      },
    },
    required: ['colorConfig', 'displayName', 'name'],
    type: 'object',
  } as any;

  uiSchema = {
    colorConfig: {
      'ui:widget': 'textarea',
    },
  } as any;

  validate(formData, errors) {
    try {
      JSON.parse(formData.colorConfig);
    } catch (e) {
      errors.colorConfig.addError('Invalid JSON object');
    }
    return errors;
  }

  render() {
    return (
      <Form
        schema={this.schema}
        uiSchema={this.uiSchema}
        formData={this.state.formData}
        onChange={this.onFormDataChanged.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        validate={this.validate}>
        <div style={{ display: 'flex', marginBottom: '0.5em' }}>
          <a
            href="https://hq.iress.com/display/VIEW/How+to+configure+Viewpoint+colours"
            target="_blank">
            How to configure Viewpoint color themes
          </a>
        </div>

        <div style={{ display: 'flex' }}>
          <button className="btn btn-primary pull-right" type="submit">
            {this.props.mode}
          </button>
        </div>
      </Form>
    );
  }
}
