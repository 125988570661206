import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from './Switch';
import blue from '@material-ui/core/colors/blue';

export default class Boolean extends React.Component<any, {}> {
  onChange() {
    const { mergedConfig, propKey, defaultConfig } = this.props;
    const isChecked = !!mergedConfig[propKey];
    const defaultValue = defaultConfig[propKey];
    // If unchecked and defaultValue is null, reset to defaultValue (FMA-982)
    const newValue =
      isChecked && defaultValue === null
        ? defaultValue
        : !mergedConfig[propKey];

    if (defaultValue === undefined && !newValue) {
      this.props.onConfigChanged({ [propKey]: undefined }); // if new value is default value, we just revert the change.
    } else {
      this.props.onConfigChanged({
        [propKey]: newValue,
      });
    }
  }

  render() {
    const { mergedConfig, propKey, classes } = this.props;
    return (
      <Switch
        checked={!!mergedConfig[propKey]}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}
