import qs from 'querystring';
import { getAuth } from './okta-auth';

const RETRY_DELAY = 5000;
const RETRY_ATTEMPTS = 20;
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

function fetchRetry(url, options, n) {
  return fetch(url, options)
    .then(function(res) {
      if (res.ok) return res;
      else {
        if (n === 1) return res;
        return delay(RETRY_DELAY).then(() => fetchRetry(url, options, n - 1));
      }
    })
    .catch(function(error) {
      if (n === 1) throw error;
      return delay(RETRY_DELAY).then(() => fetchRetry(url, options, n - 1));
    });
}

const apiBaseUrl = configApp.configuration.dougApiUrl;

async function getAccessToken() {
  const token = await getAuth().getAccessToken();
  if (!token) {
    window.localStorage.clear();
    window.location.reload();
  }
  return token;
}

export async function get({ path, query = {} }) {
  const accessToken = await getAccessToken();
  const response = await fetchRetry(
    `${apiBaseUrl}/${path}${
      Object.keys(query).length ? '?' + qs.stringify(query) : ''
    }`,
    {
      mode: 'cors',
      headers: { Authorization: `${accessToken}` },
    },
    RETRY_ATTEMPTS
  );
  return response.json();
}

export async function post({
  path,
  query = {},
  stringify = true,
  jsonResponse = false,
}) {
  const accessToken = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/${path}`, {
    mode: 'cors',
    headers: {
      Authorization: `${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: stringify ? JSON.stringify(query) : query,
  });
  if (jsonResponse) {
    return response.json();
  }
  return response.text();
}
