import { createSelector } from 'reselect';

const getGroupList = ({ configSelection }) => configSelection.groupList;

// Returns the groupList from the provided configuration, translated from group-id format into value-label format.
export default createSelector([getGroupList], groupList => {
  return groupList.map((group, id) => ({
    value: group.groupId,
    label: group.groupName,
  }));
});
