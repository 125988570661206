import { get, post } from './http';

export function adminCompanyGetList(args) {
  return get({
    path: 'AdminCompanyGetList',
    query: args,
  });
}

export function adminGroupDeptGetList(args) {
  return get({
    path: 'AdminGroupDeptGetList',
    query: args,
  });
}

export function adminUserGetList(args) {
  return get({
    path: 'AdminUserGetList',
    query: args,
  });
}

export function adminUserGetNames(args) {
  return post({
    path: 'AdminUserGetNames',
    query: args,
    stringify: true,
    jsonResponse: true,
  });
}

export function queueConfigChanges(args) {
  return get({
    path: 'QueueConfigChanges',
    query: args,
  });
}

export function fetchConfig(args) {
  return get({
    path: 'FetchConfig',
    query: args,
  });
}

export function fetchRegionalConfig(args) {
  return get({
    path: 'FetchRegionalConfig',
    query: args,
  });
}

export function fetchSchema(args) {
  return get({
    path: 'FetchSchema',
    query: args,
  });
}

export function applyConfig(args) {
  return post({
    path: 'ApplyConfigChanges',
    query: args,
  });
}

export function applyTranslation(args) {
  return post({
    path: 'ApplyTranslation',
    query: args,
  });
}

export function uploadToIressAssetsS3(args) {
  return post({
    path: 'UploadToIressAssetsS3',
    query: args,
    stringify: true,
  });
}

export function deleteFileIressAssetsS3(args) {
  return post({
    path: 'DeleteFileIressAssetsS3',
    query: args,
    stringify: true,
  });
}

export function listObjectsIressAssetsS3(args) {
  return get({
    query: args,
    path: 'ListObjectsIressAssetsS3',
  });
}

export function fetchAllPipelineStatus(args) {
  return get({
    path: 'FetchAllPipelineStatus',
    query: args,
  });
}
