import React from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TableContent = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0px;
`;

const SettingRow = props => {
  return (
    <div style={{ display: 'flex' }}>
      {props.settingName + ':' + props.settingValue}
    </div>
  );
};

SettingRow.propTypes = {
  settingName: PropTypes.string,
  settingValue: PropTypes.object,
};

const ReviewSettingsTableComponent = props => {
  const { flatSchema } = props;
  let count = 0;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '1em 0 1em 0',
        padding: '0',
      }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Setting Name</th>
            <th scope="col">Previous setting value</th>
            <th scope="col">New setting value</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(props.modifiedConfig).map(key => {
            const newSetting = props.mergedConfig[key];
            let defaultConfigValue = props.defaultValues[key];
            if (isNil(defaultConfigValue)) {
              defaultConfigValue = '<Not Set>';
            } else {
              defaultConfigValue = JSON.stringify(defaultConfigValue);
            }
            let settingName = '';
            if (props.configSchema.properties[key]) {
              settingName = props.configSchema.properties[key].description;
            }

            const style = {};
            if (count % 2 === 0) {
              style.backgroundColor = 'rgba(0,0,0,.05)';
            }
            count++;

            let newSettingValue = JSON.stringify(newSetting);

            const config = flatSchema[key];

            if (config.baseType === 'select' && config.selectMapping) {
              defaultConfigValue =
                config.selectMapping[props.defaultValues[key]];
              newSettingValue = config.selectMapping[newSetting];
            }

            return (
              <tr key={count} style={style}>
                <td>
                  {settingName}
                  {props.configSchema.properties[key].conditionallyShow && (
                    <div style={{ fontSize: '0.8em' }}>
                      {props.configSchema.properties[key].longDescription}
                    </div>
                  )}
                </td>
                <TableContent title={defaultConfigValue}>
                  {defaultConfigValue}
                </TableContent>
                <TableContent title={newSettingValue}>
                  {newSettingValue}
                </TableContent>
              </tr>
            );
          })}
          {Object.keys(props.deletedConfig).map(key => {
            const configValue = props.mergedConfig[key];

            let defaultConfigValue = JSON.stringify(props.defaultValues[key]);

            let settingName = '';
            if (props.configSchema.properties[key]) {
              settingName = props.configSchema.properties[key].description;
            }

            const style = {};
            if (count % 2 === 0) {
              style.backgroundColor = 'rgba(0,0,0,.05)';
            }
            count++;

            return (
              <tr key={count} style={style}>
                <td>{settingName}</td>
                <TableContent title={defaultConfigValue}>
                  {defaultConfigValue}
                </TableContent>
                <TableContent>{`${configValue}`}</TableContent>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
ReviewSettingsTableComponent.propTypes = {
  deletedConfig: PropTypes.object,
  modifiedConfig: PropTypes.object,
  mergedConfig: PropTypes.object,
  defaultValues: PropTypes.object,
};

export default ReviewSettingsTableComponent;
