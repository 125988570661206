import React, { Component } from 'react';
import { TimePicker } from 'react-time-picker';
import { withStyles } from '@material-ui/core/styles';
import 'react-time-picker/dist/TimePicker.css';

const styles = theme => ({
  resize: {
    fontSize: 16,
  },
});

export class Time extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
    };
  }

  onChange = value => {
    const { propKey, onConfigChanged } = this.props;

    onConfigChanged({ [propKey]: value === '' ? null : value });

    this.setState({ changed: true });
  };

  render() {
    const { mergedConfig, propKey } = this.props;

    return (
      <div style={{ marginLeft: 10 }}>
        <TimePicker
          disableClock={true}
          className={'timePicker'}
          maxDetail={'second'}
          hourPlaceholder={'00'}
          minutePlaceholder={'00'}
          secondPlaceholder={'00'}
          format={'HH:mm:ss'}
          value={mergedConfig[propKey]}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Time);
