import React from 'react';
import styled, { keyframes } from 'styled-components';

const ProgressContainer = styled.div`
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
`;
const wave = keyframes`
  0%, 40%, 100% {
    transform: translate(0, 0);
    background-color: #4A72DA;
  }
  10% { 
    transform: translate(0, -15px);
  }
`;

const Dot: any = styled.span`
  transition: all 500ms ease;
  background: #4a72da;
  box-shadow: 1px 2px 3px #999;
  height: 10px;
  width: 10px;
  margin-left: 5px;
  display: inline-block;
  border-radius: 10px;
  animation: ${wave} 2s ease infinite;
  animation-delay: ${(props: any) => props.delay || 0}ms;
`;

export default function ProgressDots() {
  return (
    <ProgressContainer>
      <Dot />
      <Dot delay={100} />
      <Dot delay={200} />
    </ProgressContainer>
  );
}
