import React from 'react';
import { connect } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';

const CafDetails = props => {
  const showWidgetDeletionWarning =
    Object.keys(props.modifiedConfig).filter(
      key =>
        key.startsWith('Permission/Widget/') &&
        props.modifiedConfig[key] === 'none'
    ).length > 0;
  return showWidgetDeletionWarning ? (
    <div style={{ margin: '15px 0' }}>
      <Alert severity="warning" style={{ fontSize: '1.1em' }}>
        <AlertTitle style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
          Removing permissioned widget
        </AlertTitle>
        A previously permissioned widget is being removed. You will need to also
        remove it from the UE2 command list to complete this task.
      </Alert>
    </div>
  ) : null;
};

const AdditionalReviewDetails = props => {
  if (props.selectedApp === 'caf') {
    return <CafDetails {...props} />;
  } else {
    return null;
  }
};

export default connect(state => {
  const { selectedApp } = state.configSelection;
  const { configSchema, deletedConfig, modifiedConfig } = state.configData;

  return {
    selectedApp,
    configSchema,
    deletedConfig,
    modifiedConfig,
  };
})(AdditionalReviewDetails);
