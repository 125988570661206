import {
  getContext,
  takeEvery,
  all,
  call,
  put,
  select,
  fork,
} from 'redux-saga/effects';
import { isNil, sortBy, union, get } from 'lodash';
import configSingleton from '../_ducks/configSingleton';

import {
  configSelectionActions,
  SELECT_COMPANY,
  SET_ENVIRONMENT,
  SET_APP,
  REQUEST_COMPANY_LIST,
  LOAD_COMPANY_AND_GROUP,
  LOAD_COMPANY_AND_USER,
} from '../_ducks/configSelection';

import { configAppActions } from '../_ducks/app';

import {
  UPLOAD_FILE,
  setIressAssetsS3Objects,
  SET_SELECTED_BUCKET,
  UPLOAD_MULTIPLE_FILES,
  setCurrentlyUploadingFile,
} from '../_ducks/uploadFile';
import { CHANGE_FILE_TYPE } from '../_ducks/changeFileType';
import { DELETE_FILE } from '../_ducks/deleteFile';

import {
  configDataActions,
  APPLY_CONFIG,
  REQUEST_CONFIG,
  REQUEST_SCHEMA,
  SUBMIT_CONFIG_SELECTION,
} from '../_ducks/configData';
import { AUTH_RESULT } from '../_ducks/auth';
import {
  applyConfig,
  adminCompanyGetList,
  adminGroupDeptGetList,
  adminUserGetList,
  adminUserGetNames,
  fetchConfig,
  fetchSchema,
  fetchRegionalConfig,
  uploadToIressAssetsS3,
  deleteFileIressAssetsS3,
  listObjectsIressAssetsS3,
  fetchAllPipelineStatus,
} from '../api';
import * as pipelineStatus from '../_ducks/pipelineStatus';
import applyConfigSelector from '../_selectors/applyConfigSelector';
import getTemplateSettingValue from '../_selectors/getTemplateSettingValue';
import schemaSelector from '../_selectors/schemaSelector';

import customLogicSagas from './customLogicSagas';
import configDiff from '../components/Common/ConfigDiff';
import { apps, getAppProperty } from '../utils/apps';

const configSelector = state => state.configSelection;

export default function* rootSaga() {
  yield all([
    takeEvery(APPLY_CONFIG, applyConfigSaga),
    takeEvery(REQUEST_CONFIG, requestConfigSaga),
    takeEvery(REQUEST_COMPANY_LIST, requestCompanyListSaga),
    takeEvery(AUTH_RESULT, setEnvironmentSaga),
    takeEvery(SELECT_COMPANY, onSelectCompany),
    takeEvery(SUBMIT_CONFIG_SELECTION, configSelectionSubmitedSaga),
    takeEvery(REQUEST_SCHEMA, requestSchemaSaga),
    takeEvery(UPLOAD_FILE, uploadFileSaga),
    takeEvery(UPLOAD_MULTIPLE_FILES, multiUploadFileSaga),
    takeEvery(DELETE_FILE, deleteFileSaga),
    takeEvery(SET_ENVIRONMENT, doInitialRequests),
    takeEvery(SET_APP, setAppSaga),
    takeEvery(LOAD_COMPANY_AND_GROUP, requestCompanyAndGroupSaga),
    takeEvery(LOAD_COMPANY_AND_USER, requestCompanyAndUserSaga),
    takeEvery(SET_SELECTED_BUCKET, listObjectsSaga),
    takeEvery(CHANGE_FILE_TYPE, listObjectsSaga),
    takeEvery(pipelineStatus.FETCH_RECORDS, fetchStatusRecords),
    takeEvery(pipelineStatus.REAPPLY_CHANGE, reapplyConfigChange),
    ...customLogicSagas(),
  ]);
}

function* setEnvironmentSaga() {
  const environments = yield select(state => state.auth.environments);
  if (!environments || !environments.length) return;
  const environment = environments[0];
  yield put(configSelectionActions.setEnvironment(environment));
}

function* setAppSaga() {
  if (configSingleton.getErrorMessage() != null) {
    return; // Don't proceed if the reducer set an error message
  }

  const {
    environment,
    selectedApp,
    selectedCompanyId,
    selectedCompanyName,
    selectedGroupId,
    selectedGroupName,
    selectedUserId,
    selectedUserName,
    configureState,
  } = yield select(configSelector);

  yield put(configAppActions.initialRequestsCompleted(false));
  yield put(
    pipelineStatus.actions.updateFilter(
      getAppProperty(selectedApp, 'filter', pipelineStatus.FILTER.MINE)
    )
  );
  yield all([
    call(requestSchemaSaga),
    call(requestDefaultConfigSaga),
    call(requestRegionalConfigSaga),
    configureState === 'user'
      ? call(requestUserConfig, {
          env: environment.key,
          app: selectedApp,
          companyId: selectedCompanyId,
          companyName: selectedCompanyName,
          userId: selectedUserId,
          userName: selectedUserName,
        })
      : call(requestGroupConfig, {
          env: environment.key,
          app: selectedApp,
          companyId: selectedCompanyId,
          companyName: selectedCompanyName,
          groupId: selectedGroupId,
          groupName: selectedGroupName,
        }),
  ]);
  yield put(configAppActions.initialRequestsCompleted(true));
}

function* doInitialRequests() {
  yield all([
    call(requestCompanyListSaga),
    call(requestSchemaSaga),
    call(requestDefaultConfigSaga),
    call(requestRegionalConfigSaga),
    call(listObjectsSaga),
  ]);
  yield put(configAppActions.initialRequestsCompleted(true));
}

function* requestCompanyAndGroupSaga(action) {
  const { environment, selectedApp } = yield select(configSelector);
  const { companyName, companyId, groupName, groupId } = action.payload;

  yield call(requestGroupListSaga, {
    payload: { selectedCompanyId: companyId },
  });
  yield call(requestGroupConfig, {
    companyId,
    companyName,
    groupId,
    groupName,
    env: environment.key,
    app: selectedApp,
  });

  yield put(configSelectionActions.setCompanyAndGroup(action.payload));
}

function* requestCompanyAndUserSaga(action) {
  const { environment, selectedApp } = yield select(configSelector);
  const { companyName, companyId, userName, userId } = action.payload;

  yield call(requestUserListSaga, {
    payload: { selectedCompanyId: companyId },
  });
  yield call(requestUserConfig, {
    companyId,
    companyName,
    userId,
    userName,
    env: environment.key,
    app: selectedApp,
  });

  yield put(configSelectionActions.setCompanyAndUser(action.payload));
}

function* applyConfigSaga() {
  const { modifiedConfig, sideEffectConfig, postProcessConfig } = yield select(
    state => state.configData
  );
  const {
    selectedApp,
    environment,
    selectedCompanyId,
    selectedCompanyName,
    selectedGroupId,
    selectedGroupName,
    selectedUserId,
    selectedUserName,
  } = yield select(configSelector);

  // Upload File
  const { cachedImages } = yield select(state => state.uploadFile);
  const bucketName = yield select(state => state.uploadFile.selectedBucket);
  const filesToUpload = [];
  const fileIDs = [];

  Object.keys(cachedImages).forEach(setting => {
    if (modifiedConfig[setting]) {
      const { file, fileAsBase64, id } = cachedImages[setting];
      const newFileName = `CompanyLogos/${
        environment.key
      }/${selectedCompanyId}/${selectedGroupId ? selectedGroupId + '/' : ''}${
        file.name
      }`;
      const fileDataToUpload = {
        fileName: newFileName,
        fileAsBase64,
        bucketName,
      };
      fileIDs.push(id);
      filesToUpload.push(call(uploadToIressAssetsS3, fileDataToUpload));
    }
  });
  const fileResponses = yield all(filesToUpload);

  const errorFileConfigs = [];
  // Change config to new URL
  const s3UrlPrefix = configApp.configuration.assetsBucketUrl;
  fileResponses.forEach((fileResponse, index) => {
    try {
      const response = JSON.parse(fileResponse);
      const { Key } = response.result;
      const imageUrl = `${s3UrlPrefix}${Key}`;
      const id = fileIDs[index];
      modifiedConfig[id] = imageUrl;
    } catch (e) {
      const id = fileIDs[index];
      delete modifiedConfig[id];
      errorFileConfigs.push(id);
    }
  });

  if (errorFileConfigs.length > 0) {
    const ids = errorFileConfigs.join(', ');
    alert(
      `${ids} was not correctly uploaded and was not applied. The remaining config will still apply.`
    );
  }

  // convert company/group details into a nested JSON object
  // [{id: 1, name: 'IRESS', groups: [{id: 200, name:'staff'}]}]
  let companies = yield select(applyConfigSelector);

  // Generate diff. This is used to display the actual old, new values
  // in the log and can eventually be used to undo a config change if needed.
  const { deletedConfig, defaultValues } = yield select(
    state => state.configData
  );
  const { flatSchema } = yield select(schemaSelector);
  const diff = configDiff({
    modifiedConfig,
    deletedConfig,
    defaultValues,
    flatSchema,
  });

  if (Object.keys(postProcessConfig).length > 0 && companies.length > 0) {
    const {
      defaultConfig,
      companyConfig,
      groupConfig,
      userConfig,
    } = yield select(state => state.configData);

    if (
      companies.length === 1 &&
      companies[0].users &&
      companies[0].users.length > 0
    ) {
      const userRequests = [];
      const userRequestMap = [];
      companies[0].users.forEach(({ id, name }) => {
        if (id !== selectedUserId) {
          userRequests.push(
            call(fetchConfig, {
              companyId: companies[0].id,
              companyName: companies[0].name,
              userId: id,
              userName: name,
              env: environment.key,
              app: selectedApp,
            })
          );
          userRequestMap.push({ id, name });
        }
      });
      const userConfigs = yield all(userRequests);
      userRequestMap.push({
        id: selectedUserId,
        name: selectedUserName,
      });
      const gConfigs = [...userConfigs, userConfig].map(gConfig => {
        let userConfig = {};
        if (!gConfig.errors) {
          userConfig = gConfig;
        }
        return { ...defaultConfig, ...companyConfig, ...userConfig };
      });

      const companyRequest = {
        id: companies[0].id,
        name: companies[0].name,
        users: [],
      };
      gConfigs.forEach((gConfig, index) => {
        const postProcessedConfig = {};
        Object.keys(postProcessConfig).forEach(ppConfigKey => {
          const configValue = getTemplateSettingValue(
            gConfig[ppConfigKey],
            gConfig
          );
          const newPPConfig = union(
            configValue,
            postProcessConfig[ppConfigKey]
          );
          postProcessedConfig[ppConfigKey] = newPPConfig;
        });
        const userChanges = {
          ...userRequestMap[index],
          add: {
            ...modifiedConfig,
            ...sideEffectConfig,
            ...postProcessedConfig,
          },
        };
        companyRequest.users.push(userChanges);
      });

      companies = [companyRequest];
    } else if (
      companies.length === 1 &&
      companies[0].groups &&
      companies[0].groups.length > 0
    ) {
      const groupRequests = [];
      const groupRequestMap = [];
      companies[0].groups.forEach(({ id, name }) => {
        if (id !== selectedGroupId) {
          groupRequests.push(
            call(fetchConfig, {
              companyId: companies[0].id,
              companyName: companies[0].name,
              groupId: id,
              groupName: name,
              env: environment.key,
              app: selectedApp,
            })
          );
          groupRequestMap.push({ id, name });
        }
      });
      const groupConfigs = yield all(groupRequests);
      groupRequestMap.push({
        id: selectedGroupId,
        name: selectedGroupName,
      });
      const gConfigs = [...groupConfigs, groupConfig].map(gConfig => {
        let groupConfig = {};
        if (!gConfig.errors) {
          groupConfig = gConfig;
        }
        return { ...defaultConfig, ...companyConfig, ...groupConfig };
      });

      const companyRequest = {
        id: companies[0].id,
        name: companies[0].name,
        groups: [],
      };
      gConfigs.forEach((gConfig, index) => {
        const postProcessedConfig = {};
        Object.keys(postProcessConfig).forEach(ppConfigKey => {
          const configValue = getTemplateSettingValue(
            gConfig[ppConfigKey],
            gConfig
          );
          const newPPConfig = union(
            configValue,
            postProcessConfig[ppConfigKey]
          );
          postProcessedConfig[ppConfigKey] = newPPConfig;
        });
        const groupChanges = {
          ...groupRequestMap[index],
          add: {
            ...modifiedConfig,
            ...sideEffectConfig,
            ...postProcessedConfig,
          },
        };
        companyRequest.groups.push(groupChanges);
      });

      companies = [companyRequest];
    } else {
      const companyRequests = [];
      const companyRequestMap = [];
      companies.forEach(({ id, name }) => {
        if (id !== selectedCompanyId) {
          companyRequests.push(
            call(fetchConfig, {
              companyId: id,
              companyName: name,
              env: environment.key,
              app: selectedApp,
            })
          );
          companyRequestMap.push({ id, name });
        }
      });
      const companyConfigs = yield all(companyRequests);
      companyRequestMap.push({
        id: selectedCompanyId,
        name: selectedCompanyName,
      });
      const compConfigs = [...companyConfigs, companyConfig].map(cConfig => {
        let compConfig = {};
        if (!cConfig.errors) {
          compConfig = cConfig;
        }
        return { ...defaultConfig, ...compConfig };
      });

      const companyChangeRequests = [];
      compConfigs.forEach((compConfig, index) => {
        const postProcessedConfig = {};
        Object.keys(postProcessConfig).forEach(ppConfigKey => {
          const configValue = getTemplateSettingValue(
            compConfig[ppConfigKey],
            compConfig
          );
          const newPPConfig = union(
            configValue,
            postProcessConfig[ppConfigKey]
          );
          postProcessedConfig[ppConfigKey] = newPPConfig;
        });
        const company = {
          ...companyRequestMap[index],
          add: {
            ...modifiedConfig,
            ...sideEffectConfig,
            ...postProcessedConfig,
          },
          groups: [],
        };
        companyChangeRequests.push(company);
      });

      companies = companyChangeRequests;
    }
  }

  yield call(applyConfig, {
    app: selectedApp,
    env: environment.key,
    companies,
    diff,
  });
}

function* requestDefaultConfigSaga() {
  const { environment, selectedApp } = yield select(configSelector);
  const response = yield call(fetchConfig, {
    env: environment.key,
    app: selectedApp,
  });
  yield put(configDataActions.setDefaultConfig(response));
}

function* requestCompanyListSaga() {
  const { environment } = yield select(configSelector);
  let response;
  try {
    response = yield call(adminCompanyGetList, { env: environment.key });
  } catch (error) {
    response = { companies: [] };
    console.error(error);
  }
  yield put(configSelectionActions.setCompanyList(response.companies));
}

function* onSelectCompany(action) {
  if (
    action.payload.selectedCompanyId === null ||
    action.payload.selectedCompanyId === undefined ||
    action.payload.selectedCompanyId === ''
  ) {
    return;
  }
  const configureState = yield select(
    state => state.configSelection.configureState
  );
  if (configureState === 'group') {
    yield call(requestGroupListSaga, action);
  } else if (configureState === 'user') {
    yield call(requestUserListSaga, action);
  }
}

function* requestGroupListSaga(action) {
  const { environment } = yield select(configSelector);
  const response = yield call(adminGroupDeptGetList, {
    env: environment.key,
    companyId: action.payload.selectedCompanyId,
  });
  yield put(configSelectionActions.setGroupList(response.groups));
}

function* requestUserListSaga(action) {
  if (
    action.payload.selectedCompanyId === null ||
    action.payload.selectedCompanyId === undefined ||
    action.payload.selectedCompanyId === ''
  ) {
    return;
  }
  const { environment } = yield select(configSelector);
  let response;
  try {
    response = yield call(adminUserGetList, {
      env: environment.key,
      companyId: action.payload.selectedCompanyId,
      loginTypes: 10, // login types Pro and NetIress Pro users - all defined login types https://github.com/oneiress/iress-headers/blob/master/src/dfstype.h#L479
    });
  } catch (error) {
    response = { users: [] };
    console.error(error);
  }
  yield put(configSelectionActions.setUserList(response.users));
}

function createRoute({
  route,
  companyName,
  companyId,
  groupName,
  groupId,
  userName,
  userId,
}) {
  let newRoute = route;
  if (companyName && !isNil(companyId)) {
    newRoute += `/${companyId}/${encodeURIComponent(companyName)}`;
  }

  if (groupName && !isNil(groupId)) {
    newRoute += `/group/${groupId}/${encodeURIComponent(groupName)}`;
  } else if (userName && !isNil(userId)) {
    newRoute += `/user/${userId}/${encodeURIComponent(userName)}`;
  }
  return newRoute;
}

function* requestGroupConfig({
  companyName,
  companyId,
  groupName,
  groupId,
  app,
  env,
}) {
  const { companyList, groupList } = yield select(configSelector);
  const companyExists = !!companyList.find(
    company => company.companyId === Number(companyId)
  );
  const groupExists =
    !groupId || !!groupList.find(group => group.groupId === Number(groupId));

  if (!companyExists || !groupExists) {
    configSingleton.setErrorMessage(
      `Could not retrieve ${companyName} ${groupId &&
        ` - ${groupName}`}. Company ID ${groupId &&
        ` and/or Group ID`} provided in URL could not be found`
    );
  } else {
    const requests = [];

    if (companyName && !isNil(companyId)) {
      requests.push(call(fetchConfig, { env, companyName, companyId, app }));
    }

    if (groupName && !isNil(groupId)) {
      requests.push(
        call(fetchConfig, {
          env,
          companyName,
          companyId,
          groupName,
          groupId,
          app,
        })
      );
    }

    const [company, group] = yield all(requests);
    yield put(configDataActions.clearConfig());
    configSingleton.clearErrorMessage();
    if (company) {
      yield put(configDataActions.setCompanyConfig(company));
    }
    if (group) {
      yield put(configDataActions.setGroupConfig(group));
    }
  }
  const route = createRoute({
    route: '/edit',
    companyName,
    companyId,
    groupName,
    groupId,
  });
  return route;
}

function* requestUserConfig({
  companyName,
  companyId,
  userName,
  userId,
  app,
  env,
}) {
  const { companyList, userList } = yield select(configSelector);
  const companyExists = !!companyList.find(
    company => company.companyId === Number(companyId)
  );
  const userExists =
    !userId || !!userList.find(user => user.userId === Number(userId));

  if (!companyExists || !userExists) {
    configSingleton.setErrorMessage(
      `Could not retrieve ${companyName} ${userId &&
        ` - ${userName}`}. Company ID ${userId &&
        ` and/or User ID`} provided in URL could not be found`
    );
  } else {
    const requests = [];

    if (companyName && !isNil(companyId)) {
      requests.push(call(fetchConfig, { env, companyName, companyId, app }));
    }

    if (userName && !isNil(userId)) {
      requests.push(
        call(fetchConfig, {
          env,
          companyName,
          companyId,
          userName,
          userId,
          app,
        })
      );
    }

    const [company, user] = yield all(requests);
    yield put(configDataActions.clearConfig());
    configSingleton.clearErrorMessage();
    if (company) {
      yield put(configDataActions.setCompanyConfig(company));
    }
    if (user) {
      yield put(configDataActions.setUserConfig(user));
    }
  }
  const route = createRoute({
    route: '/edit',
    companyName,
    companyId,
    userName,
    userId,
  });
  return route;
}

function* requestRegionalConfigSaga() {
  const { environment, selectedApp } = yield select(configSelector);
  let response;
  try {
    response = yield call(fetchRegionalConfig, {
      app: selectedApp,
      env: environment.key,
    });
  } catch (error) {
    response = {};
    if (error) {
      console.error(error);
    } else {
      console.error(
        'Regional config request did not work. This means displayed config will not display regional settings!'
      );
    }
  }
  yield put(configDataActions.setRegionalConfig(response));
}

function* requestConfigSaga(action) {
  const {
    selectedCompanyId,
    selectedCompanyName,
    selectedGroupId,
    selectedGroupName,
    selectedUserId,
    selectedUserName,
    environment,
    selectedApp,
  } = yield select(configSelector);
  yield put(configSelectionActions.showLoadingDots(true));
  yield fork(listObjectsSaga);
  let route = null;
  if (!isNil(selectedUserId) && selectedUserId !== '') {
    route = yield call(requestUserConfig, {
      companyId: selectedCompanyId,
      companyName: selectedCompanyName,
      userId: selectedUserId,
      userName: selectedUserName,
      env: environment.key,
      app: selectedApp,
    });
  } else {
    route = yield call(requestGroupConfig, {
      companyId: selectedCompanyId,
      companyName: selectedCompanyName,
      groupId: selectedGroupId,
      groupName: selectedGroupName,
      env: environment.key,
      app: selectedApp,
    });
  }
  if (!route) {
    return;
  }
  yield put(configSelectionActions.showLoadingDots(false));
  yield put(configSelectionActions.clearAdditionalCompaniesGroupsOrUsers());

  const history = yield getContext('history');
  history.push(route);
}

function getProcessedSchema(response, selectedApp) {
  const mainIndex = get(apps, [selectedApp, 'ui', 'mainIndex'], 'Index');
  const index = response.definitions[mainIndex];
  const replaceReferences = get(
    apps,
    [selectedApp, 'ui', 'replaceReferences'],
    []
  );

  if (replaceReferences.length === 0) {
    return index;
  }
  // For each ref in replaceReferences, replace '$ref: "#/definitions/${ref}"' with
  // response.definitions[ref].
  // This is a perf optimisation which maintains consistency between the
  // properties of the type used in the config UI and the actual reference type
  // which validates the JSON files
  // For usage, check caf in products.json
  Object.keys(index.properties || {}).forEach(prop => {
    replaceReferences.forEach(ref => {
      const reference = `#/definitions/${ref}`;
      const definition = response.definitions[ref];

      if (index.properties[prop]['$ref'] === reference) {
        index.properties[prop] = {
          ...definition,
          ...index.properties[prop],
        };
        delete index.properties[prop]['$ref'];
      }
    });
  });

  return index;
}

function* requestSchemaSaga() {
  const { environment, selectedApp } = yield select(configSelector);
  const response = yield call(fetchSchema, {
    env: environment.key,
    app: selectedApp,
  });

  yield put(
    configDataActions.setConfigSchema(getProcessedSchema(response, selectedApp))
  );
  yield put(configDataActions.setExpandedCategories({ app: selectedApp }));
}

function* configSelectionSubmitedSaga() {
  const history = yield getContext('history');
  const {
    selectedCompanyId,
    selectedCompanyName,
    selectedGroupId,
    selectedGroupName,
    selectedUserId,
    selectedUserName,
  } = yield select(configSelector);

  const reviewRoute = createRoute({
    route: '/review',
    companyName: selectedCompanyName,
    companyId: selectedCompanyId,
    groupName: selectedGroupName,
    groupId: selectedGroupId,
    userName: selectedUserName,
    userId: selectedUserId,
  });

  history.push(reviewRoute);
}

function* multiUploadFileSaga() {
  const multiUploadFormData = yield select(
    state => state.uploadFile.multiUploadFormData
  );
  const bucketName = yield select(state => state.uploadFile.selectedBucket);
  for (const file of multiUploadFormData) {
    file.bucketName = bucketName;
    yield put(setCurrentlyUploadingFile(file.fileName));
    yield call(uploadToIressAssetsS3, file);
  }
  yield put(setCurrentlyUploadingFile(''));
  yield fork(listObjectsSaga);
}

function* uploadFileSaga() {
  const uploadFileFormData = yield select(
    state => state.uploadFile.uploadFileFormData
  );
  if (!uploadFileFormData) {
    throw new Error(
      'uploadFileFormData is not defined. Value is: ' + uploadFileFormData
    );
  }
  const bucketName = yield select(state => state.uploadFile.selectedBucket);
  uploadFileFormData.bucketName = bucketName;
  yield put(setCurrentlyUploadingFile(uploadFileFormData.fileName));
  yield call(uploadToIressAssetsS3, uploadFileFormData);
  yield put(setCurrentlyUploadingFile(''));
  yield fork(listObjectsSaga);
}

function* deleteFileSaga(action) {
  const fileKey = action.payload;
  const bucketName = yield select(state => state.uploadFile.selectedBucket);
  yield call(deleteFileIressAssetsS3, { fileKey, bucketName });
  yield fork(listObjectsSaga);
}

function* listObjectsSaga() {
  const bucketName = yield select(state => state.uploadFile.selectedBucket);
  try {
    const listObjectsResponse = yield call(listObjectsIressAssetsS3, {
      bucketName,
    });

    yield put(setIressAssetsS3Objects(listObjectsResponse.result.Contents));
  } catch (e) {
    console.error('Unable to list bucket objects');
    yield put(setIressAssetsS3Objects([]));
  }
}

function* fetchStatusRecords() {
  try {
    const configAuthor = yield select(
      state => (state.auth.user ? state.auth.user.email : '')
    );
    const filter = yield select(state => state.pipelineStatus.filter);
    const { environment, selectedApp } = yield select(configSelector);
    const records = yield call(fetchAllPipelineStatus, {
      configAuthor: filter === pipelineStatus.FILTER.MINE ? configAuthor : '',
      configEnv: getAppProperty(selectedApp, 'filterEnvironment', false)
        ? environment.key
        : null,
      configApp: selectedApp,
    });

    // We need to request the usernames for any user records since they are not stored in the diff
    yield fetchUserNames(records);

    const sortedRecords = sortBy(records, r => r.lastModified).reverse();
    yield put(pipelineStatus.actions.updateRecords(sortedRecords));
  } catch (err) {
    console.log('Could not fetch pipeline status', err);
  }
}

function* reapplyConfigChange({ payload }) {
  try {
    const content = JSON.parse(payload);
    delete content['CONFIG_PIPELINE_ID'];
    yield call(applyConfig, content);
  } catch (err) {
    console.log('Could not reapply config change', err);
  }
}

async function fetchUserNames(records) {
  const userIds = new Map();
  processUserRecords(records, true, (record, user) =>
    storeUserIds(userIds, record, user)
  );

  const userNames = new Map();
  for (const [env, envUserIds] of userIds) {
    try {
      const envUserNames = (await adminUserGetNames({
        env,
        userIds: Array.from(envUserIds),
      })).userNames;
      if (!envUserNames) {
        console.error(
          `fetchUserNames() retrieved an invalid username set for environment ${env}`
        );
      }
      userNames.set(env, envUserNames);
    } catch (error) {
      console.error(
        `fetchUserNames() encountered an error calling adminUserGetNames(): ${error}`
      );
    }
  }

  processUserRecords(records, false, (record, user) =>
    setUserNames(userNames, record, user)
  );
}

function processUserRecords(records, extract, procFn) {
  records.forEach(record => {
    if (!record) {
      console.log('processUserRecords() got an invalid record.');
      return record;
    }

    if (extract) {
      if (!record.changeContent) {
        console.log(
          'processUserRecords() got a record without any changeContent field.'
        );
        return record;
      }

      try {
        record.extra = JSON.parse(record.changeContent);
      } catch (error) {
        console.error(
          `processUserRecords() encountered an error decoding the changeContent for a record: ${error}`
        );
        return record;
      }
    }

    if (!record.extra) {
      console.error(
        'processUserRecords() encountered a record with no .extra field (containing decoded changeContent).'
      );
      return record;
    }

    if (!Array.isArray(record.extra.companies)) {
      return record;
    }

    record.extra.companies.forEach(company => {
      (company.users || []).forEach(user => {
        if (user.id) {
          procFn(record, user);
        } else {
          console.warn(
            `There is a record with pipelineId: ${
              record.pipelineId
            } that has a the property record.extra.companies[x].user with no valid id`
          );
        }
      });
    });
  });
}

function storeUserIds(userIds, record, user) {
  const env = record.extra.env;
  let envUserIds = userIds.get(env);
  if (!envUserIds) {
    envUserIds = new Set();
    userIds.set(env, envUserIds);
  }
  envUserIds.add(user.id);
}

function setUserNames(userNames, record, user) {
  const env = record.extra.env;
  const envUserNames = userNames.get(env);
  if (!envUserNames) {
    console.error(
      `setUserNames() could not find a user name set for environment ${env}`
    );
  }

  for (const userName of envUserNames) {
    if (userName.id === user.id) {
      user.name = userName.name;
      return;
    }
  }
  console.error(
    `setUserNames() could not find a user name for user id ${user.id}`
  );
}
