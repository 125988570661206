import { Card, Collapse, TextareaAutosize } from '@material-ui/core';
import './styles.css';
import React, { useState } from 'react';
import { debounce } from '@material-ui/core';

type Props = {
  title: string; //this is a title of a widget or shared entity like Error
  translationsElement: { [key: string]: string }; // this could be a widget or shared entity
  englishTranslations: { [key: string]: string };
  translationsRequestElement: { [key: string]: string }; // this is user edits for one widget or shared entity
  setTranslationsRequestElement: (
    title: string,
    translationKey: string,
    value: string
  ) => void;
};

export const formUserUpdatedStructure = (
  translationsElement,
  translationsRequestElement
) => {
  const userUpdatedObject = { ...translationsElement };
  if (translationsRequestElement && Object.keys(translationsRequestElement)) {
    Object.keys(translationsRequestElement).forEach(translationKey => {
      userUpdatedObject[translationKey] =
        translationsRequestElement[translationKey];
    });
  }
  return userUpdatedObject;
};

function TranslationBlock({
  title,
  translationsElement,
  englishTranslations,
  translationsRequestElement,
  setTranslationsRequestElement,
}: Props) {
  const [open, setOpen] = useState(false);
  const [translationValues, setTranslationValues] = React.useState({});

  React.useEffect(
    () => {
      setTranslationValues(
        translationsRequestElement
          ? formUserUpdatedStructure(
              translationsElement,
              translationsRequestElement
            )
          : translationsElement
      ); // set initial values after mount
    },
    [translationsElement]
  );

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    translationKey: string
  ) => {
    const { value } = event.target;
    setTranslationValues(prev => ({ ...prev, [translationKey]: value })); // update state with the new value

    const processChange = debounce(() => {
      setTranslationsRequestElement(title, translationKey, value);
    });

    processChange();
  };

  return (
    <Card key={title} className="translation__mfe-section">
      <p className="translation__mfe-heading" onClick={() => setOpen(!open)}>
        {title}
      </p>
      <Collapse in={open}>
        {Object.keys(translationsElement).map((translationKey, index) => {
          return (
            <div key={index} className="translation__mfe-property">
              <div>{translationKey}</div>
              <TextareaAutosize
                value={translationValues[translationKey] || ''}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleOnChange(event, translationKey)
                }
                placeholder={'No translation provided'}
              />

              {englishTranslations &&
                englishTranslations.hasOwnProperty(translationKey) && (
                  <div style={{ gridColumn: 2 }}>
                    {englishTranslations[translationKey]}
                  </div>
                )}
            </div>
          );
        })}
      </Collapse>
    </Card>
  );
}
export default TranslationBlock;
