import { Auth } from '@okta/okta-react';

let auth;

export function initAuth({ history }) {
  const oktaConfig = configApp.configuration.okta;
  const authOpts = {
    issuer: oktaConfig.issuer,
    client_id: oktaConfig.clientId,
    redirect_uri: window.location.origin + '/implicit/callback',
    history,
    scope: ['openid', 'groups', 'profile', 'email'],
  };
  auth = new Auth(authOpts);

  // TODO: Remove this after this Alfred is complete: RITM0020633
  Object.defineProperty(auth._oktaAuth.options, 'ignoreSignature', {
    value: true,
    configurable: false,
    writable: false,
    enumerable: true,
  });

  return auth;
}

export function getAuth() {
  return auth;
}
