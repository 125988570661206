import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

import { Environment } from '../../store/AppState';
import { configSelectionActions } from '../../_ducks/configSelection';
import useComponentVisible from '../UseComponentVisible';

const logos = {
  'au-semiprod': require('svg-country-flags/svg/au.svg'),
  'au-production': require('svg-country-flags/svg/au.svg'),
  'za-production': require('svg-country-flags/svg/za.svg'),
  'ca-production': require('svg-country-flags/svg/ca.svg'),
  'uk-production': require('svg-country-flags/svg/gb.svg'),
  'au-uat': require('svg-country-flags/svg/au.svg'),
  'za-uat': require('svg-country-flags/svg/za.svg'),
  'ca-uat': require('svg-country-flags/svg/ca.svg'),
  'uk-uat': require('svg-country-flags/svg/gb.svg'),
  'ca-semiprod': require('svg-country-flags/svg/ca.svg'),
  'uk-semiprod': require('svg-country-flags/svg/gb.svg'),
  'za-semiprod': require('svg-country-flags/svg/za.svg'),
};

export interface HeaderLayoutProps {
  userName: string;
  environments: Environment[];
  selectedEnvironment: Environment;
  onEnvironmentChange: Function;
  auth: any;
  isTechUser: boolean;
  apps: object;
  selectedApp: string;
  onAppChange: Function;
}

function AppList(props): JSX.Element {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const className = `dropdown ${isComponentVisible ? 'open' : 'closed'}`;

  return (
    <li className={className} ref={ref}>
      <a
        href="#"
        className="dropdown-toggle"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        title="Change product"
        onClick={e => {
          e.preventDefault();
          setIsComponentVisible(true);
        }}>
        {props.apps[props.selectedApp].name}
        <span className="caret" />
      </a>
      <ul className="dropdown-menu">
        {Object.keys(props.apps).map(app => (
          <li key={app}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                props.onAppChange(app);
                setIsComponentVisible(false);
              }}>
              {props.apps[app].name}
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
}

class HeaderLayoutClass extends React.Component<HeaderLayoutProps> {
  private switchEnvironment(environment: Environment): any {
    this.props.onEnvironmentChange(environment);
  }

  private getEnvironmentMenu(): JSX.Element {
    const { environments } = this.props;
    const currentEnvironment = this.props.selectedEnvironment;
    if (!currentEnvironment) {
      return <span>Loading...</span>;
    }

    return (
      <span className="navbar-text" style={{ float: 'right' }}>
        {currentEnvironment.name}
        {environments.map(({ key, name, ...env }) => (
          <img
            key={key}
            title={name}
            onClick={
              currentEnvironment.key !== key
                ? () => this.switchEnvironment({ key, name, ...env })
                : undefined
            }
            className={currentEnvironment.key === key ? 'active' : ''}
            style={{
              height: 20,
              marginRight: 10,
              marginLeft: 10,
              ...(currentEnvironment.key === key
                ? {}
                : { cursor: 'pointer', opacity: 0.3 }),
            }}
            src={logos[key]}
          />
        ))}
      </span>
    );
  }

  render() {
    const { userName } = this.props;
    const environmentMenu = this.getEnvironmentMenu();
    const termsOfUseURL = getTermsOfUseURL();
    return (
      <nav
        className="navbar navbar-inverse"
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: 1,
          borderRadius: 0,
        }}>
        <div className="container-fluid">
          <div className="navbar-header">
            <Link to="/" className="navbar-brand">
              <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faCogs} />
              WebApp Configurator
            </Link>
          </div>
          <div className="navbar-collapse" id="main-navbar">
            <ul className="nav navbar-nav">
              <AppList
                apps={this.props.apps}
                selectedApp={this.props.selectedApp}
                onAppChange={this.props.onAppChange}
              />
              <li>
                <Link to="/track-progress">My Config Requests</Link>
              </li>
              <li>
                <a href={termsOfUseURL} target="_blank">
                  Configure Terms of Use
                </a>
              </li>
              {this.props.isTechUser && (
                <li>
                  <Link
                    to="/upload-company-image"
                    title="This is only visible to developers and testers">
                    Upload Files
                  </Link>
                </li>
              )}
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <span
                className="navbar-text alignRight"
                style={{ float: 'right' }}>
                {userName}
              </span>
              {environmentMenu}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const styles = theme => ({
  alignRight: { float: 'right' },
  table: {
    minWidth: 700,
    fontSize: 55,
  },
  progressbar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 1,
  },
  tableCell: {
    position: 'relative',
    fontSize: 12,
  },
  tableCellNoWrap: {
    position: 'relative',
    fontSize: 12,
    'white-space': 'nowrap',
  },
  tableHeader: {
    fontWeight: 700,
    fontSize: 14,
  },
});
export const HeaderLayout = connect(
  (state: any) => ({
    userName: state.auth.user ? state.auth.user.name : '',
    selectedEnvironment: state.configSelection.environment,
    environments: state.auth.environments || [],
    logout: state.auth.logout,
    isTechUser: state.auth.isTechUser,
    apps: state.configSelection.apps,
    selectedApp: state.configSelection.selectedApp,
  }),

  {
    onEnvironmentChange: configSelectionActions.setEnvironment,
    onAppChange: configSelectionActions.setApp,
  }
)(withAuth(HeaderLayoutClass));

function getTermsOfUseURL() {
  const host = window.location.host;
  const domain = host.slice(host.indexOf('.'));
  const termsOfUseURL = `https://termsofuse-assets${domain}/index.html`;
  return termsOfUseURL;
}
