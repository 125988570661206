import * as _ from 'lodash';

const TYPE_ORDER = {
  boolean: 0,
  number: 1,
  string: 2,
  array: 3,
  file: 4,
  textarea: 5,
};

export function sortConfig(config, schema, shouldSort = true) {
  if (!shouldSort) {
    return Object.keys(config);
  }
  const configOrder = Object.keys(config).sort((keyA, keyB) => {
    let configA = config[keyA];
    let configB = config[keyB];
    let typeOrderA = TYPE_ORDER[configA.baseType];
    let typeOrderB = TYPE_ORDER[configB.baseType];

    if (configB.conditionallyShow) {
      const equivalentKey = Object.keys(configB.conditionallyShow)[0];
      if (equivalentKey === keyA) {
        return -1;
      }
      configB = schema[equivalentKey];
      typeOrderB = TYPE_ORDER[configB.baseType];
    }

    if (configA.conditionallyShow) {
      const equivalentKey = Object.keys(configA.conditionallyShow)[0];
      if (equivalentKey === keyB) {
        return 1;
      }
      configA = schema[equivalentKey];
      typeOrderA = TYPE_ORDER[configA.baseType];
    }

    if (typeOrderA < typeOrderB) {
      return -1;
    } else if (typeOrderA > typeOrderB) {
      return 1;
    } else if (typeOrderA === undefined && typeOrderB !== undefined) {
      return 1;
    } else if (typeOrderA !== undefined && typeOrderB === undefined) {
      return -1;
    } else {
      return (configA.description + '').toLowerCase() <
        (configB.description + '').toLowerCase()
        ? -1
        : 1;
    }
  });
  return configOrder;
}

export function transform(transformation, value) {
  const fn = _[transformation];
  if (typeof fn === 'function') {
    return fn(value);
  }

  return value;
}
