import React from 'react';
import Chip from './Chip';
import { DragSource, DropTarget } from 'react-dnd';

let beginDragIndex = -1;
const CHIP = 'CHIP';
const chipSource = {
  beginDrag(props) {
    beginDragIndex = props.index;
    return {};
  },
  canDrag(props) {
    return props.reorderable;
  },
};

function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function defaultChip(props) {
  const { connectDragSource, isDragging, reorderable } = props;
  return connectDragSource(
    <div>
      <Chip
        {...props}
        data={props.data}
        index={props.index}
        style={{ cursor: reorderable ? 'pointer' : 'grab' }}
        key={`chip-${String(props.data)
          .replace(/[\s\/\\]/g, '')
          .toLowerCase()}`}
      />
    </div>
  );
}

const squareTarget = {
  canDrop(props) {
    return (
      Array.isArray(props.list) &&
      beginDragIndex > -1 &&
      beginDragIndex <= props.list.length &&
      beginDragIndex !== props.index &&
      props.index - beginDragIndex !== 1
    );
  },
  drop(props) {
    if (
      props.handleMove &&
      Array.isArray(props.list) &&
      beginDragIndex > -1 &&
      beginDragIndex <= props.list.length &&
      beginDragIndex !== props.index &&
      props.index - beginDragIndex !== 1
    ) {
      props.handleMove(beginDragIndex, props.index);
    }
  },
};

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

function defaultSquare(props) {
  const { connectDropTarget, isOver, canDrop, index } = props;
  return connectDropTarget(
    <div
      style={{
        borderWidth: 3,
        borderLeftStyle: 'solid',
        borderLeftColor: isOver && canDrop ? 'orange' : 'transparent',
        minWidth: 15,
        display: 'inline-block',
      }}
      key={`square-${index}`}>
      {props.DragSourceChip && (
        <props.DragSourceChip
          {...props}
          data={props.data}
          index={props.index}
        />
      )}
    </div>
  );
}

export default function(props) {
  const DragSourceChip = DragSource(
    props.dndType || CHIP,
    chipSource,
    dragCollect
  )(defaultChip);
  const DropTargetSquare = DropTarget(
    props.dndType || CHIP,
    squareTarget,
    dropCollect
  )(defaultSquare);

  return (
    <>
      {props.list.map((data, index) => {
        return props.customChip ? (
          props.customChip(data, index)
        ) : (
          <DropTargetSquare
            {...props}
            key={index}
            data={data}
            index={index}
            DragSourceChip={DragSourceChip}
          />
        );
      })}
      {props.customChip || (
        <DropTargetSquare {...props} index={props.list.length} />
      )}
    </>
  );
}
