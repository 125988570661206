import React, { ReactInstance } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';

import { sortConfig } from '../../common';
import schemaSelector from '../../_selectors/schemaSelector';
import { configDataActions } from '../../_ducks/configData';
import { configModalActions } from '../../_ducks/configModal';
import ColorConfigRow from '../ColorConfig/ColorConfigRow';
import ConfigRow from './ConfigRow';
import Boolean from './Boolean';
import NumberComponent from './Number';
import TimeComponent from './Time';
import FileUpload from './FileUpload';
import ExternalContent from './ExternalContent';
import SelectBoxForStringSettings from './SelectBoxForStringSettings';
import DefaultLayoutConfig from './DefaultLayoutConfig';
import getArrayComponent from './getArrayComponent';
import getStringComponent from './getStringComponent';
import TemplateConfigSection from '../TemplateConfig';
import IOSPlusFilterRow from '../IOSPlusFilter/IOSPlusFilterRow';
import IOSPlusFillDestinationRow from '../IOSPlusFillDestination/IOSPlusFillDestinationRow';

const StyledCategory = styled.div`
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 10px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
  background-color: white;
  margin-bottom: 20px;
`;

const StyledCategoryHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #eee;
  }
`;

export function Category(props) {
  const {
    flatSchema,
    properties,
    title,
    expanded,
    mergedConfig,
    apps,
    selectedApp,
  } = props;

  // TODO: properties is intermittently null, even without the
  // product switcher. This is a temporary fix, but the root cause
  // has to be identified
  if (!properties) {
    return null;
  }

  const keys = sortConfig(
    properties,
    flatSchema,
    get(apps, [selectedApp, 'sortConfig'], true)
  );
  let content: null | JSX.Element | JSX.Element[] = null;
  if (title === 'External Content') {
    content = (
      <ExternalContent {...props} toggleConfigModal={props.toggleConfigModal} />
    );
  } else {
    content = keys.map(key => {
      let ConfigComponent: any = null;
      if (key === 'Default/availableColorSets') {
        ConfigComponent = (
          <ColorConfigRow
            {...props}
            propKey={key}
            toggleConfigModal={props.toggleConfigModal}
          />
        );
      } else if (key === 'Default/availableLayoutTemplates') {
        ConfigComponent = (
          <TemplateConfigSection
            {...props}
            propKey={key}
            toggleConfigModal={props.toggleConfigModal}
          />
        );
      } else if (key === 'PcFull/viewStates|C:masterFrame') {
        ConfigComponent = <DefaultLayoutConfig propKey={key} {...props} />;
      } else if (/IOSPlus\/Filters\/Available.*FiltersSet/.test(key)) {
        ConfigComponent = (
          <IOSPlusFilterRow
            {...props}
            propKey={key}
            toggleConfigModal={props.toggleConfigModal}
          />
        );
      } else if (key === 'IOSPlus/Settings/OperatorPadFillManagementDefaultDestinations') {
        ConfigComponent = (
          <IOSPlusFillDestinationRow
            {...props}
            propKey={key}
            toggleConfigModal={props.toggleConfigModal}
          />
        );
      } else if (
        properties[key].baseType === 'boolean' &&
        properties[key].type === 'number'
      ) {
        let { onConfigChanged, ...rest } = props;
        ConfigComponent = (
          <Boolean
            propKey={key}
            onConfigChanged={config => {
              if (config[key] !== undefined) config[key] = Number(config[key]);
              onConfigChanged(config);
            }}
            {...rest}
          />
        );
      } else if (properties[key].baseType === 'boolean') {
        let { onConfigChanged, ...rest } = props;
        ConfigComponent = (
          <Boolean propKey={key} onConfigChanged={onConfigChanged} {...rest} />
        );
      } else if (
        properties[key].type === 'string' &&
        properties[key].hasOwnProperty('format') &&
        properties[key].format === 'time'
      ) {
        ConfigComponent = <TimeComponent propKey={key} {...props} />;
      } else if (properties[key].baseType === 'string') {
        ConfigComponent = getStringComponent(key, props);
      } else if (properties[key].baseType === 'number') {
        ConfigComponent = (
          <NumberComponent
            propKey={key}
            addError={props.addError}
            clearError={props.clearError}
            {...props}
          />
        );
      } else if (properties[key].baseType === 'array') {
        ConfigComponent = getArrayComponent(
          key,
          props.defaultConfig,
          props,
          props.properties[key]
        );
      } else if (properties[key].baseType === 'file') {
        ConfigComponent = (
          <FileUpload
            key={key}
            name={props.mergedConfig[key]}
            configName={key}
          />
        );
      } else if (properties[key].baseType === 'select') {
        ConfigComponent = (
          <SelectBoxForStringSettings
            propKey={key}
            schemaDefinition={properties[key]}
            menuItems={properties[key].items}
            currentValue={mergedConfig[key]}
            onConfigChanged={props.onConfigChanged}
          />
        );
      }

      return (
        <ConfigRow propKey={key} key={key} {...props}>
          {ConfigComponent}
        </ConfigRow>
      );
    });
  }
  return (
    <StyledCategory>
      <StyledCategoryHeader onClick={() => props.expandCollapse(title)}>
        {title}
      </StyledCategoryHeader>
      {expanded && content}
    </StyledCategory>
  );
}

const mapStateToProps = state => {
  const { mergedConfig, flatSchema } = schemaSelector(state);
  const {
    defaultValues,
    deletedConfig,
    modifiedConfig,
    sideEffectConfig,
    sideEffectConfigMap,
    postProcessConfig,
    defaultConfig,
  } = state.configData;
  const { apps, selectedApp } = state.configSelection;
  return {
    defaultValues,
    deletedConfig,
    flatSchema,
    modifiedConfig,
    postProcessConfig,
    sideEffectConfig,
    sideEffectConfigMap,
    mergedConfig,
    defaultConfig,
    apps,
    selectedApp,
  };
};

const mapDispatchToProps = {
  onConfigChanged: configDataActions.modifyConfig,
  onConfigDeleted: configDataActions.deleteConfig,
  expandCollapse: configDataActions.expandCollapse,
  addColorConfig: configDataActions.addColorConfig,
  editColorConfig: configDataActions.editColorConfig,
  onSideEffectConfigChanged: configDataActions.onSideEffectConfigChanged,
  onPostProcessConfig: configDataActions.postProcessConfig,

  toggleConfigModal: configModalActions.toggleConfigModal,
  onShowConfigModal: configModalActions.onShowConfigModal,
  onHideConfigModal: configModalActions.onHideConfigModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Category);
