import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from './Select';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';

const styles = theme =>
  createStyles({
    paper: {
      width: '100%',
      maxWidth: 400,
      display: 'flex',
      justifyContent: 'flex-end',
      flexFlow: 'column',
      padding: theme.spacing(0.5),
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  });

interface Props extends WithStyles<typeof styles> {
  propKey: any;
  mergedConfig: any;
  onConfigChanged: (any) => any;
  properties: any;
  collection: any;
}

export class ArrayComponent extends Component<Props, any> {
  constructor(props) {
    super(props);

    this.onKeyPress = this.onKeyPress.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.state = {
      selectedValue: '',
      inputValue: '',
    };
  }

  handleDelete(index) {
    const { propKey, mergedConfig, onConfigChanged } = this.props;
    const config = mergedConfig[propKey].slice(0);
    if (index > -1 && index < config.length) {
      config.splice(index, 1);
      onConfigChanged({ [propKey]: config });
    }
  }

  handleAdd(data) {
    if (!_.isEmpty(data)) {
      const { propKey, mergedConfig, onConfigChanged } = this.props;
      const config = mergedConfig[propKey].slice(0);
      config.push(data);
      onConfigChanged({ [propKey]: config });
      this.setState({ inputValue: '' });
    }
  }

  handleMove(index, indexBefore) {
    const { propKey, mergedConfig, onConfigChanged } = this.props;
    const config = mergedConfig[propKey].slice(0);
    index >= indexBefore
      ? config.splice(indexBefore, 0, config.splice(index, 1)[0])
      : config.splice(indexBefore - 1, 0, config.splice(index, 1)[0]);
    onConfigChanged({ [propKey]: config });
  }

  onChange(inputValue) {
    this.setState({ inputValue });
  }

  onKeyPress = event => {
    const inputTextValue = event.target.value.trim();
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleAdd(inputTextValue);
    }
  };

  onSelectChange = event => {
    const selectedValue = event.target.value.trim();
    const { propKey, mergedConfig } = this.props;
    if (selectedValue && !_.includes(mergedConfig[propKey], selectedValue)) {
      this.handleAdd(selectedValue);
    }
    this.setState({ selectedValue: null });
  };

  render() {
    const {
      classes,
      mergedConfig,
      propKey,
      properties,
      collection,
    } = this.props;
    const config = mergedConfig[propKey];

    if (!Array.isArray(config)) {
      console.error(`Setting ${propKey} is not an array`);
      return null;
    }
    const selectId = propKey.replace(/[\s\\\/]/g, '').toLowerCase();
    let menuItems;
    if (collection) {
      menuItems = collection
        // filter out already selected items
        .filter(item => config.indexOf(item) === -1)
        .map(item => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        });
      menuItems.unshift(
        <MenuItem key="select_to_add" value="">
          <em>Select to add</em>
        </MenuItem>
      );
    }

    const SelectionList = collection ? (
      <ListItem style={{ marginLeft: 150, paddingTop: 0, paddingBottom: 0 }}>
        <Select
          inputLabel={{
            htmlFor: selectId,
            text: 'Select to add',
            inputProps: { name: 'selectedValue', id: selectId },
          }}
          menuItems={menuItems}
          onChange={this.onSelectChange}
          value={this.state.selectedValue}
        />
      </ListItem>
    ) : null;

    const ListWrapperComponent = collection ? (
      <List style={{ padding: 0 }}>{SelectionList}</List>
    ) : (
      <>{SelectionList}</>
    );

    return ListWrapperComponent;
  }
}

export default withStyles(styles)(ArrayComponent);
