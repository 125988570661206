import React from 'react';
import Fab from '@material-ui/core/Fab';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateConfigSchema } from '../Common/ValidateConfigSchema';

const ImportDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiTypography-body1': {
    'font-size': '1.5rem',
  },
  '& .MuiTypography-h6': {
    'font-size': '2rem',
  },
  '& .MuiButton-text': {
    'font-size': '1.5rem',
  },
}));

const MaxNumberOfErrorMessages = 5;

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = res => {
      resolve(res.target.result);
    };
    reader.onerror = err => reject(err);

    reader.readAsText(file);
  });
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

class ImportButton extends React.Component {
  constructor(props) {
    super(props);
    this.inputFile = React.createRef();
    this.state = {
      dialogOpened: false,
      importButtonDisabled: true,
      importJsonContent: null,
      dialogMessages: ['Select a Json file to "Import" configuration.'],
    };
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpened: false,
      importButtonDisabled: true,
      importJsonContent: null,
      dialogMessages: ['Select a Json file to "Import" configuration.'],
    });
  };

  handleDialogOpen = () => {
    this.setState({
      dialogOpened: true,
    });
  };

  tryParseJSONObject = jsonString => {
    try {
      var josnObject = JSON.parse(jsonString);

      if (josnObject && typeof josnObject === 'object') {
        this.setState({ importJsonContent: josnObject });
        return true;
      }
    } catch (e) {
      this.setState({
        importButtonDisabled: true,
        dialogMessages: ['The selected file is NOT a valid Json file!'],
      });
      console.warn('content is not a valid json:' + e);
    }

    return false;
  };

  validateImport = () => {
    const { configData } = this.props;
    var validImport = true;
    var importErroMessages = [];

    const providedApp = this.state.importJsonContent['selectedApp'];
    if (providedApp !== configData.selectedApp) {
      importErroMessages.push(
        `The config entry 'selectedApp' is expecting '${
          configData.selectedApp
        }' but found '${providedApp}'`
      );
      validImport = false;
    }

    const providedLevel = this.state.importJsonContent['selectedLevel'];
    if (providedLevel !== configData.selectedLevel) {
      importErroMessages.push(
        `The config entry 'selectedLevel' is expecting '${
          configData.selectedLevel
        }' but found '${providedLevel}'`
      );
      validImport = false;
    }

    for (const property in this.state.importJsonContent) {
      if (property === 'selectedApp' || property === 'selectedLevel') {
        continue;
      }

      if (!configData.mergedConfig.hasOwnProperty(property)) {
        importErroMessages.push(
          `This config entry '${property}' is NOT a valid entry`
        );
        validImport = false;
        continue;
      }

      if (!configData.flatSchema.hasOwnProperty(property)) {
        importErroMessages.push(
          `This config entry '${property}' is NOT a valid entry`
        );
        validImport = false;
        continue;
      }

      const configValue = this.state.importJsonContent[property];
      const schema = configData.flatSchema[property];

      if (!validateConfigSchema(configValue, schema)) {
        if (schema.baseType === 'array' || schema.baseType === 'select') {
          importErroMessages.push(
            `This configuration entry '${property}' value '${configValue}' cannot be found within the scheme array items/enums`
          );
        } else {
          importErroMessages.push(
            `This configuration entry '${property}'is expecting ${
              schema.type
            } but found ${typeof configValue}`
          );
        }

        validImport = false;
      }
    }

    if (importErroMessages.length > MaxNumberOfErrorMessages) {
      importErroMessages = importErroMessages.slice(
        0,
        MaxNumberOfErrorMessages
      );
      importErroMessages.push(
        `The selected file contains more than ${MaxNumberOfErrorMessages} errors`
      );
    }

    if (!validImport) {
      this.setState({
        importButtonDisabled: true,
        dialogMessages: importErroMessages,
      });
    }

    return validImport;
  };

  async handleFileInput(event) {
    this.setState({
      dialogMessages: [],
    });

    const file = event.target.files[0];
    const jsonString = await readFile(file);

    //parse the json file
    if (!this.tryParseJSONObject(jsonString)) return;

    if (!this.validateImport()) return;

    this.setState({
      importButtonDisabled: false,
      dialogMessages: [
        `The selected file constains ${Object.keys(this.state.importJsonContent)
          .length - 2} configuration entries`,
      ],
    });
  }

  handleImport = () => {
    const { configData } = this.props;
    for (const property in this.state.importJsonContent) {
      if (property === 'selectedApp' || property === 'selectedLevel') {
        continue;
      }

      if (
        Array.isArray(configData.mergedConfig[property]) &&
        Array.isArray(this.state.importJsonContent[property]) &&
        arraysEqual(
          configData.mergedConfig[property],
          this.state.importJsonContent[property]
        )
      ) {
        continue;
      }
      configData.onConfigChanged({
        [property]: this.state.importJsonContent[property],
      });
    }

    this.handleDialogClose();
  };

  render() {
    const messaagesContent = this.state.dialogMessages.map(message => {
      return <DialogContentText>{message}</DialogContentText>;
    });
    return (
      <div>
        <Fab
          style={this.props.style}
          size="small"
          color="primary"
          aria-label="ImportConfiugrations"
          onClick={this.handleDialogOpen.bind(this)}
          title="Import Configurations">
          <CloudUploadIcon />
        </Fab>
        <ImportDialog
          open={this.state.dialogOpened}
          onClose={this.handleDialogClose.bind(this)}
          fullWidth={true}
          maxWidth="md">
          <DialogTitle>
            Import Configuration
            <FontAwesomeIcon
              onClick={this.handleDialogClose.bind(this)}
              icon={faTimes}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            />
          </DialogTitle>
          <DialogContent>
            <>{messaagesContent}</>
            <input
              name="importFile"
              type="file"
              style={{ width: '100%' }}
              ref={this.inputFile}
              onChange={this.handleFileInput.bind(this)}
              onClick={event => {
                event.target.value = '';
              }}
              accept=".json,application/json"
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.state.importButtonDisabled}
              onClick={this.handleImport.bind(this)}>
              Import
            </Button>
          </DialogActions>
        </ImportDialog>
      </div>
    );
  }
}

export default ImportButton;
