export const INITIAL_REQUEST_COMPLETED = 'app/INITIAL_REQUEST_COMPLETED';

export const configAppActions = {
  initialRequestsCompleted: payload => ({
    type: INITIAL_REQUEST_COMPLETED,
    payload,
  }),
};

const initialState = {
  initialRequestsCompleted: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INITIAL_REQUEST_COMPLETED: {
      return { ...state, initialRequestsCompleted: action.payload };
    }
    default:
      return state;
  }
}
