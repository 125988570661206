import React from 'react';
import Fab from '@material-ui/core/Fab';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileSaver from 'file-saver';

class ExportButton extends React.Component<any, any> {
  mapToObject = (inputMap: Map<String, any>) => {
    let obj = {};

    inputMap.forEach(function(value: string, key: any) {
      obj[key] = value;
    });

    return obj;
  };

  export = () => {
    const { configData } = this.props;
    const configToBeExportedMap = new Map();

    //Each properties within the configData contains the high level feature
    for (const eachKey in configData.schema.properties) {
      //and high level feature contians multiple sub level,
      //while the mergedConfig store the actual value
      for (const subLevelKey in configData.schema.properties[eachKey]
        .properties) {
        // only exported the level that is matching and with a actual value
        if (
          configData.schema.properties[eachKey].properties[subLevelKey]
            .configDefinedIn !== configData.selectedLevel
        ) {
          continue;
        }

        if (typeof configData.mergedConfig[subLevelKey] === 'undefined') {
          continue;
        }

        configToBeExportedMap.set(
          subLevelKey,
          configData.mergedConfig[subLevelKey]
        );
      }
    }

    // write out the following additional properties.
    configToBeExportedMap.set('selectedLevel', configData.selectedLevel);
    configToBeExportedMap.set('selectedApp', configData.selectedApp);

    // construct the json using configToBeExportedMap and write this out
    var output = JSON.stringify(
      this.mapToObject(configToBeExportedMap),
      null,
      4
    );
    const blob = new Blob([output], { type: 'application/json' });

    const fileName = [
      configData.selectedApp.toString(),
      configData.selectedCompanyName.toString(),
      configData.selectedChildEntityName != undefined &&
        configData.selectedChildEntityName.toString(),
      new Date().toISOString().split('.')[0],
    ]
      .filter(str => str)
      .join('-');

    FileSaver.saveAs(blob, fileName + '.json');
  };

  render() {
    return (
      <Fab
        style={this.props.style}
        size="small"
        color="primary"
        aria-label="ExportConfigurations"
        onClick={this.export.bind(this)}
        title="Export Configurations">
        <CloudDownloadIcon />
      </Fab>
    );
  }
}

export default ExportButton;
