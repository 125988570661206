import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

export class SelectBoxForStringSettings extends Component {
  constructor(props) {
    super(props);

    this.ref = null;
  }

  onSelectChange(value) {
    const { propKey, schemaDefinition, onConfigChanged } = this.props;
    onConfigChanged({
      [propKey]: schemaDefinition.type === 'number' ? Number(value) : value,
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { currentValue, menuItems } = nextProps;
    const sanitizedValue = this.getSanitizedValue(currentValue, menuItems);
    if (sanitizedValue !== currentValue) {
      this.onSelectChange(sanitizedValue);
    }
  }

  getSanitizedValue(currentValue, menuItems) {
    const value = find(menuItems, item => item.value === currentValue);
    if (value !== undefined) {
      return currentValue;
    } else if (menuItems.length > 0) {
      return menuItems[0].value;
    }
    return undefined;
  }

  render() {
    const { classes, currentValue, menuItems } = this.props;
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <NativeSelect
            inputRef={instance => (this.ref = instance)}
            className={classes.select}
            value={currentValue}
            onChange={event => {
              this.onSelectChange(event.target.value);
            }}
            inputProps={{
              name: 'select',
              id: 'select',
            }}>
            {menuItems.map(({ label, value }) => {
              return (
                <option value={value} key={value}>
                  {label}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      </div>
    );
  }
}

SelectBoxForStringSettings.propTypes = {
  classes: PropTypes.object,
  menuItems: PropTypes.array,
  propKey: PropTypes.string,
  schemaDefinition: PropTypes.object,
  onConfigChanged: PropTypes.func,
  currentValue: PropTypes.any,
};

const styles = theme => ({
  root: {
    display: 'flex',
    width: 250,
    marginLeft: 20,
  },
  formControl: {
    width: '100%',
  },
  select: {
    fontSize: 16,
  },
});

export default withStyles(styles)(SelectBoxForStringSettings);
