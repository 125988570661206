import React from 'react';
import Stepper from 'react-stepper-horizontal';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const pipelineStatusToProgressStep = {
  CONFIG_PIPELINE_STATUS_SENDING_TO_JENKINS: 0,
  CONFIG_PIPELINE_STATUS_RECEIVED_BY_JENKINS: 0,
  CONFIG_PIPELINE_STATUS_FINISHED_PROCESSING_BY_JENKINS: 1,
  CONFIG_PIPELINE_STATUS_JENKINS_PROCESSING_ERROR: -1,
  CONFIG_PIPELINE_STATUS_START_BUILDING: 2,
  CONFIG_PIPELINE_STATUS_START_UPLOADING: 2,
  CONFIG_PIPELINE_STATUS_UPLOADING_FINISHED: 4,
};

const ProgressBar = props => {
  const activeStep = pipelineStatusToProgressStep[props.status];
  return activeStep === -1 ? (
    <span>
      <div style={{ paddingLeft: '3px' }}>
        <FontAwesomeIcon
          style={{ fontSize: '26px', color: 'red' }}
          icon={faExclamationCircle}
        />
      </div>
      <span style={{ color: 'red' }}>Failed</span>
    </span>
  ) : (
    <Stepper
      size={24}
      circleFontSize={12}
      titleFontSize={12}
      circleTop={0}
      completeColor={'yellowgreen'}
      activeStep={pipelineStatusToProgressStep[props.status]}
      steps={[
        { title: 'Preparing' },
        { title: 'Review' },
        { title: 'Deploying' },
        { title: 'Done' },
      ]}
    />
  );
};

ProgressBar.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ProgressBar;
