export function validateConfigSchema(configValue, schema) {
  const type = schema.type;
  const baseType = schema.baseType;

  if (type === 'string' && typeof configValue !== 'string') {
    return false;
  }

  if (type === 'number' && typeof configValue !== 'number') {
    return false;
  }

  if (type === 'boolean' && typeof configValue !== 'boolean') {
    return false;
  }

  if (baseType === 'select') {
    //check if the configvalue is within the enum,
    return (
      schema.hasOwnProperty('enum') &&
      Array.isArray(schema.enum) &&
      schema.enum.includes(configValue)
    );
  }

  if (type === 'array' && baseType === 'array') {
    //check if the itesm is provided, confirm if the configvalue is within the items.
    if (!Array.isArray(configValue)) {
      return false;
    }

    if (schema.hasOwnProperty('items') && Array.isArray(schema.items)) {
      for (var i = 0; i < configValue.length; ++i) {
        if (!schema.items.includes(configValue[i])) return false;
      }
    }
  }

  return true;
}
