import { connect } from 'react-redux';
import ConfigSelectionComponent from './ConfigSelectionComponent';

import { configSelectionActions } from '../../_ducks/configSelection';
import { configDataActions } from '../../_ducks/configData';
import companyListSelector from '../../_selectors/companyListSelector';
import groupListSelector from '../../_selectors/groupListSelector';
import userListSelector from '../../_selectors/userListSelector';
import { get } from 'lodash';

const mapConfigSelectionStateToProps = state => {
  const {
    apps,
    selectedApp,
    selectedCompanyName,
    selectedCompanyId,
    selectedGroupName,
    selectedGroupId,
    selectedUserName,
    selectedUserId,
    showLoadingDots,
    configureState,
    userConfigEnabled,
  } = state.configSelection;

  return {
    selectedApp,
    selectedCompanyName,
    selectedCompanyId,
    selectedGroupName,
    selectedGroupId,
    selectedUserName,
    selectedUserId,
    showLoadingDots,
    configureState,
    companyList: companyListSelector(state),
    groupList: groupListSelector(state),
    userList: userListSelector(state),
    userConfigEnabled,
  };
};

const mapConfigSelectionStateToDispatch = dispatch => {
  return {
    onCompanySelected: args =>
      dispatch(configSelectionActions.selectCompany(args)),
    onGroupSelected: args => dispatch(configSelectionActions.selectGroup(args)),
    onUserSelected: args => dispatch(configSelectionActions.selectUser(args)),
    onLoad: () => dispatch(configDataActions.requestConfig()),
    onClearCompanyAndGroup: () =>
      dispatch(configSelectionActions.onClearCompanyAndGroup()),
    onConfigureState: mode =>
      dispatch(configSelectionActions.selectMode({ configureState: mode })),
  };
};

export default connect(
  mapConfigSelectionStateToProps,
  mapConfigSelectionStateToDispatch
)(ConfigSelectionComponent);
