import React, { Component } from 'react';
import { camelCase, difference, filter, includes, some } from 'lodash';
import { ConfigRow } from '../Form/form-styles';

import Chips from '../Form/Chips';
import Chip from '../Form/Chip';

function handleSideEffectChanged(
  rootConfigKey,
  newSideEffectKey,
  oldSideEffectKey,
  newConfigValue,
  onSideEffectConfigChanged
) {
  const newSideEffectConfig = {
    [newSideEffectKey]: newConfigValue,
  };
  if (newSideEffectKey !== oldSideEffectKey) {
    newSideEffectConfig[oldSideEffectKey] = undefined;
  }
  onSideEffectConfigChanged({
    configId: rootConfigKey,
    sideEffectConfig: newSideEffectConfig,
  });
}

export default class ColorConfigRow extends Component<any, {}> {
  onEditColorConfig(name) {
    event.preventDefault();
    const {
      onConfigChanged,
      onHideConfigModal,
      onSideEffectConfigChanged,
      onShowConfigModal,
      mergedConfig,
      propKey,
    } = this.props;

    let colorConfig = mergedConfig[this.getColorsKey(name)];
    const colorInfo = mergedConfig[this.getColorInfosKey(name)];
    const displayName = colorInfo ? colorInfo.displayName : undefined;
    onShowConfigModal({
      modalType: 'Color Config',
      modalProps: {
        mode: 'Edit',
        formData: {
          name,
          colorConfig: JSON.stringify(colorConfig),
          displayName,
        },
        onApply: newFormData => {
          const newKeySuffix = camelCase(newFormData.name);
          const oldKeySuffix = camelCase(name);
          const newColorDataKey = this.getColorsKey(newKeySuffix);
          const oldColorDataKey = this.getColorsKey(oldKeySuffix);
          const newColorInfoKey = this.getColorInfosKey(newKeySuffix);
          const oldColorInfoKey = this.getColorInfosKey(oldKeySuffix);

          handleSideEffectChanged(
            propKey,
            newColorInfoKey,
            oldColorInfoKey,
            {
              displayName: newFormData.displayName,
            },
            onSideEffectConfigChanged
          );
          handleSideEffectChanged(
            propKey,
            newColorDataKey,
            oldColorDataKey,
            JSON.parse(newFormData.colorConfig),
            onSideEffectConfigChanged
          );

          const newColorConfig = [...mergedConfig[propKey]];
          const oldIndex = newColorConfig.findIndex(
            value => value === oldKeySuffix
          );
          if (oldIndex >= 0) {
            newColorConfig[oldIndex] = newKeySuffix;
          }
          onConfigChanged({ [propKey]: newColorConfig });
          onHideConfigModal();
        },
      },
    });
  }

  handleDelete(data) {
    const {
      mergedConfig,
      propKey,
      onConfigChanged,
      onSideEffectConfigChanged,
    } = this.props;
    const availableColorSets = mergedConfig[propKey];
    const newAvailableColorSets = filter(
      availableColorSets,
      name => name !== data
    );
    const colorDataKey = this.getColorsKey(data);
    const colorInfosKey = this.getColorInfosKey(data);

    onSideEffectConfigChanged({
      configId: propKey,
      sideEffectConfig: { [colorDataKey]: undefined },
    });
    onSideEffectConfigChanged({
      configId: propKey,
      sideEffectConfig: { [colorInfosKey]: undefined },
    });
    onConfigChanged({ [propKey]: newAvailableColorSets });
  }

  getColorInfosKey(id) {
    return `Default/colorInfos/${id}`;
  }

  getColorsKey(id) {
    return `Default/colors/${id}`;
  }

  onAddColorConfig = event => {
    event.preventDefault();
    const {
      onConfigChanged,
      onHideConfigModal,
      onSideEffectConfigChanged,
      onShowConfigModal,
      mergedConfig,
      propKey,
    } = this.props;

    onShowConfigModal({
      modalType: 'Color Config',
      modalProps: {
        mode: 'Add',
        onApply: newFormData => {
          const newId = camelCase(newFormData.name);
          const colorDataKey = this.getColorsKey(newId);
          const colorInfosKey = this.getColorInfosKey(newId);
          onSideEffectConfigChanged({
            configId: propKey,
            sideEffectConfig: {
              [colorDataKey]: JSON.parse(newFormData.colorConfig),
            },
          });
          onSideEffectConfigChanged({
            configId: propKey,
            sideEffectConfig: {
              [colorInfosKey]: { displayName: newFormData.displayName },
            },
          });
          const newColorConfig = [...mergedConfig[propKey]];
          newColorConfig.push(newId);
          onConfigChanged({ [propKey]: newColorConfig });
          onHideConfigModal();
        },
      },
    });
  };

  customChip = (data, index) => {
    return (
      <Chip
        data={data}
        index={index}
        handleDelete={this.handleDelete.bind(this, data)}
        onClick={this.onEditColorConfig.bind(this, data)}
      />
    );
  };

  render() {
    const { mergedConfig, properties, title, propKey } = this.props;
    return (
      <ConfigRow style={{ padding: 20 }}>
        <div>
          <Chips
            customChip={this.customChip}
            list={mergedConfig[propKey] || []}
            reorderable={!!properties[propKey].reorderable}
          />
        </div>
        <div style={{ textAlign: 'right' }}>
          <button
            className="btn btn-primary"
            style={{ marginTop: 5 }}
            onClick={this.onAddColorConfig}>
            Add Color Config
          </button>
        </div>
      </ConfigRow>
    );
  }
}
