import React, { useRef } from 'react';
import { TranslationsRequest, Translations } from '../Common/TranslationTypes';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import './ReviewTranslationPage.css';

import { getAuth } from '../../api/okta-auth';
import { applyTranslation } from '../../api';

interface ReviewTranslationProps {
  location: {
    state: {
      translations: Translations;
      translationsEnglish: {};
      translationsRequest: Translations;
      language: string;
      languageText: string;
    };
  };
}

const createTranslationRequestItem = (
  previousValue: string,
  currentValue: string
): any => {
  return { previous: previousValue, current: currentValue };
};

const formTranslationRequest = (
  translations: Translations,
  translationsRequest: Translations
): TranslationsRequest => {
  const request = {};
  Object.keys(translationsRequest).forEach(widgetName => {
    request[widgetName] = {};
    Object.keys(translationsRequest[widgetName]).forEach(translationKey => {
      request[widgetName][translationKey] = createTranslationRequestItem(
        translations[widgetName][translationKey],
        translationsRequest[widgetName][translationKey]
      );
    });
  });
  return request;
};

export const confirmBackToEditModal = (e, updateButton): void => {
  const isUpdateButtonDisabled =
    updateButton.current && updateButton.current.disabled;

  if (isUpdateButtonDisabled) return;

  const confirmResult = window.confirm(
    'All your changes will be reverted. Do you want to proceed?'
  );

  confirmResult ? window.history.back() : e.preventDefault();
};

export const applyTranslationChanges = async (translationChange, language) => {
  const keysMain = Object.keys(translationChange);
  const obj = keysMain.map(keyMain => {
    const outerObj = {};
    const innerObj = {};
    for (const key in translationChange[keyMain]) {
      innerObj[key] = translationChange[keyMain][key];
      outerObj[keyMain] = innerObj;
    }
    return outerObj;
  });

  const changesInTranslation = {
    app: 'viewpoint-localization',
    env: 'au-semiprod',
    author: 'test',
    language,
    modify: obj,
  };

  applyTranslation(changesInTranslation);
};

const ReviewTranslationPage = (props: ReviewTranslationProps) => {
  const {
    translations,
    translationsEnglish,
    translationsRequest,
    language,
    languageText,
  } = props.location.state;

  const updateButtonRef = useRef<HTMLButtonElement>(null);

  const formedTranslationRequest: TranslationsRequest = formTranslationRequest(
    translations,
    translationsRequest
  );

  const confirmTranslationModal = async (): Promise<void> => {
    const confirmResult = window.confirm(
      'Are you sure you want to apply this translation changes?'
    );

    if (confirmResult) {
      await applyTranslationChanges(translationsRequest, language);
      alert(
        'Your translation changes are being processed, and should be reflected in 30 mins.'
      );

      if (updateButtonRef.current) {
        updateButtonRef.current.disabled = true;
      }
    }
  };

  return (
    <div className="container translation">
      <div
        style={{
          display: 'grid',
          margin: '1em 0 1em 0',
          padding: '0',
        }}>
        <h1>{languageText}</h1>
        {Object.keys(formedTranslationRequest).map(key => (
          <div key={key}>
            <h3>{key}</h3>
            <table className="table table-striped table-review">
              <thead>
                <tr>
                  <th scope="col">Translation Name</th>
                  <th scope="col">Previous translation value</th>
                  <th scope="col">New translation value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(formedTranslationRequest[key]).map(tag => (
                  <tr key={tag}>
                    <td scope="col">{tag}</td>
                    <td scope="col">
                      {formedTranslationRequest[key][tag]['previous']}
                    </td>
                    <td scope="col">
                      {formedTranslationRequest[key][tag]['current']}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Link
            to={{
              pathname: `/translation/`,
              state: {
                translations,
                translationsEnglish,
                formedTranslationRequest,
                language,
              },
            }}>
            <button
              className="btn btn-basic"
              data-testid="back-to-edit-button"
              onClick={e => {
                confirmBackToEditModal(e, updateButtonRef);
              }}>
              Back to Edit
            </button>
          </Link>
          <button
            ref={updateButtonRef}
            className="btn btn-primary"
            data-testid="update-button"
            style={{ marginLeft: 10 }}
            onClick={async () => {
              await confirmTranslationModal();
            }}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewTranslationPage;
