import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

export const ModalBackdrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 999;
`;

export const ModalContent = styled.div`
  width: 60%;
  height: 60%
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: #fdcb6e;
  }
`;

export const CenteredFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function IconButton({ onClick, icon }) {
  return (
    <StyledFontAwesomeIcon
      style={{ marginRight: 15, cursor: 'pointer' }}
      icon={icon || faPencilAlt}
      onClick={onClick || noop}
    />
  );
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.object,
};

export const Label = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;
