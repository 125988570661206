import lunr from 'lunr';

interface SearchConfig {
  id: string;
  fields: string[];
  builderOverride?: Function;
  customSort?: Function;
}

interface LunrResult {
  ref: any;
  score: number;
  matchData: any;
}

export default class OfflineSearch {
  config: SearchConfig;
  index: any;
  cache: { [key: string]: any };

  constructor(config: SearchConfig, entries?: any[]) {
    this.config = config;
    if (Array.isArray(entries)) {
      this.rebuild(entries);
    }
  }

  // init builder
  init = () => {
    const config = this.config;
    const builder = new lunr.Builder();

    if (config.builderOverride) {
      config.builderOverride(lunr, builder);
    }

    builder.ref(config.id);
    this.config.fields.forEach(field => builder.field(field), this);
    builder.metadataWhitelist = ['position'];

    return builder;
  };

  rebuild = (entries: any[]) => {
    const config = this.config;
    // lunr indexes are immutable and cannot be changed once builder.build() is called
    const builder = this.init();

    entries.forEach(entry => builder.add(entry), this);
    this.index = builder.build();

    // cache [{id: obj}] to return results
    this.cache = entries.reduce((acc, entry) => {
      acc[entry[config.id]] = entry;
      return acc;
    }, {});
  };

  search = (text: string) => {
    // FMA-1197: Cleanup invalid characters from the search text
    // Certain chars like : and ^ are used in the search string syntax
    // Now includes '-' as valid character as per request VPC-2482
    let results: LunrResult[] = this.index.search(sanitise(text));
    if (this.config.customSort) {
      results = this.config.customSort(text, results, this.config);
    }

    return results.map(result => this.cache[result.ref]);
  };
}

export function sanitise(text) {
  return text.replace(/[^\w\d\*\.\+\(\)\- ]/g, '');
}
