import configSingleton from './configSingleton';
import { apps, getAppProperty, initiallySelectedAppName } from '../utils/apps';

export const SET_COMPANY_LIST = 'ConfigSelection/SET_COMPANY_LIST';
export const SET_GROUP_LIST = 'ConfigSelection/SET_GROUP_LIST';
export const SET_USER_LIST = 'ConfigSelection/SET_USER_LIST';
export const SELECT_COMPANY = 'ConfigSelection/SELECT_COMPANY';
export const SELECT_GROUP = 'ConfigSelection/SELECT_GROUP';
export const SELECT_USER = 'ConfigSelection/SELECT_USER';
export const SELECT_MODE = 'ConfigSelection/SELECT_MODE';
export const REQUEST_COMPANY_LIST = 'ConfigSelection/REQUEST_COMPANY_LIST';
export const REQUEST_GROUP_LIST = 'ConfigSelection/REQUEST_GROUP_LIST';
export const REQUEST_USER_LIST = 'ConfigSelection/REQUEST_USER_LIST';
export const SHOW_LOADING_DOTS = 'ConfigSelection/SHOW_LOADING_DOTS';
export const SET_ENVIRONMENT = 'ConfigSelection/SET_ENVIRONMENT';
export const LOAD_COMPANY_AND_GROUP =
  'ConfigSelection/REQUEST_COMPANY_AND_GROUP';
export const LOAD_COMPANY_AND_USER = 'ConfigSelection/REQUEST_COMPANY_AND_USER';
export const SET_COMPANY_AND_GROUP = 'ConfigSelection/SET_COMPANY_AND_GROUP';
export const SET_COMPANY_AND_USER = 'ConfigSelection/SET_COMPANY_AND_USER';
export const INITIAL_REQUEST_COMPLETED =
  'ConfigSelection/INITIAL_REQUEST_COMPLETED';
export const ADD_COMPANIES_OR_GROUPS =
  'ConfigSelection/ADD_COMPANIES_OR_GROUPS';
export const CLEAR_ADDITIONAL_COMPANIES_OR_GROUPS =
  'ConfigSelection/CLEAR_ADDITIONAL_COMPANIES_OR_GROUPS';
export const CLEAR_COMPANY_AND_GROUP =
  'ConfigSelection/CLEAR_COMPANY_AND_GROUP';
export const SET_APP = 'ConfigSelection/SET_APP';

export const configSelectionActions = {
  loadCompanyAndGroup: payload => ({ type: LOAD_COMPANY_AND_GROUP, payload }),
  loadCompanyAndUser: payload => ({ type: LOAD_COMPANY_AND_USER, payload }),
  requestCompanyList: payload => ({ type: REQUEST_COMPANY_LIST, payload }),
  requestGroupList: payload => ({ type: REQUEST_GROUP_LIST, payload }),
  requestUserList: payload => ({ type: REQUEST_USER_LIST, payload }),
  onClearCompanyAndGroup: payload => ({
    type: CLEAR_COMPANY_AND_GROUP,
    payload,
  }),
  setEnvironment: payload => ({ type: SET_ENVIRONMENT, payload }),
  setCompanyList: payload => ({ type: SET_COMPANY_LIST, payload }),
  setGroupList: payload => ({ type: SET_GROUP_LIST, payload }),
  setUserList: payload => ({ type: SET_USER_LIST, payload }),
  selectCompany: payload => ({ type: SELECT_COMPANY, payload }),
  selectGroup: payload => ({ type: SELECT_GROUP, payload }),
  selectUser: payload => ({ type: SELECT_USER, payload }),
  selectMode: payload => ({ type: SELECT_MODE, payload }),
  setCompanyAndGroup: payload => ({ type: SET_COMPANY_AND_GROUP, payload }),
  setCompanyAndUser: payload => ({ type: SET_COMPANY_AND_USER, payload }),
  showLoadingDots: payload => ({ type: SHOW_LOADING_DOTS, payload }),
  addCompaniesOrGroups: value => ({
    type: ADD_COMPANIES_OR_GROUPS,
    payload: value,
  }),
  clearAdditionalCompaniesGroupsOrUsers: () => ({
    type: CLEAR_ADDITIONAL_COMPANIES_OR_GROUPS,
  }),
  setApp: payload => ({ type: SET_APP, payload }),
};

export const initialState = {
  environment: null,
  environmentList: [],
  selectedCompanyId: '',
  selectedCompanyName: '',
  selectedGroupId: '',
  selectedGroupName: '',
  selectedUserName: '',
  apps,
  selectedApp: initiallySelectedAppName,
  showLoadingDots: false,
  companyList: [],
  groupList: [],
  userList: [],
  // additional companies/groups to apply this config
  // change to. [{key, value}]
  additionalCompaniesGroupsOrUsers: [],
  configureState: null,
  userConfigEnabled: getAppProperty(
    initiallySelectedAppName,
    'userConfig',
    false
  ),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_COMPANY_AND_GROUP: {
      return {
        ...state,
        groupList: [],
        userList: [],
        selectedCompanyId: '',
        selectedCompanyName: '',
        selectedGroupId: '',
        selectedGroupName: '',
        selectedUserId: '',
        selectedUserName: '',
      };
    }
    case SET_COMPANY_AND_GROUP: {
      const { companyId, companyName, groupId, groupName } = action.payload;
      return {
        ...state,
        selectedCompanyId: companyId,
        selectedCompanyName: companyName,
        selectedGroupId: groupId,
        selectedGroupName: groupName,
        selectedUserId: '',
        selectedUserName: '',
      };
    }
    case SET_COMPANY_AND_USER: {
      const { companyId, companyName, userId, userName } = action.payload;
      return {
        ...state,
        selectedCompanyId: companyId,
        selectedCompanyName: companyName,
        selectedUserId: userId,
        selectedUserName: userName,
        selectedGroupId: '',
        selectedGroupName: '',
      };
    }
    case SELECT_COMPANY: {
      return {
        ...state,
        ...action.payload,
        // clear out group and user data so that any previously-selected
        // input isn't shown
        groupList: [],
        selectedGroupId: '',
        selectedGroupName: '',
        userList: [],
        selectedUserId: '',
        selectedUserName: '',
        additionalCompaniesGroupsOrUsers: [],
      };
    }
    case SELECT_GROUP: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SELECT_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SELECT_MODE: {
      return { ...state, ...action.payload };
    }
    case SET_COMPANY_LIST: {
      return { ...state, companyList: action.payload };
    }
    case SET_ENVIRONMENT: {
      return {
        ...state,
        environment: action.payload,
        companyList: [],
        groupList: [],
        selectedCompanyId: '',
        selectedCompanyName: '',
        selectedGroupId: '',
        selectedGroupName: '',
        selectedUserName: '',
        additionalCompaniesGroupsOrUsers: [],
      };
    }
    case SET_GROUP_LIST: {
      return { ...state, groupList: action.payload };
    }
    case SET_USER_LIST: {
      return { ...state, userList: action.payload };
    }
    case SHOW_LOADING_DOTS: {
      return { ...state, showLoadingDots: action.payload };
    }
    case ADD_COMPANIES_OR_GROUPS: {
      return { ...state, additionalCompaniesGroupsOrUsers: action.payload };
    }
    case CLEAR_ADDITIONAL_COMPANIES_OR_GROUPS: {
      return { ...state, additionalCompaniesGroupsOrUsers: [] };
    }
    case SET_APP: {
      const selectedApp = action.payload;
      // Persist the selected app across sessions/refreshes
      window.localStorage.setItem('previouslySelectedApp', selectedApp);

      const userConfigEnabled = getAppProperty(
        selectedApp,
        'userConfig',
        false
      );

      configSingleton.clearErrorMessage();
      if (state.configureState === 'user' && !userConfigEnabled) {
        configSingleton.setErrorMessage(
          `User-level configuration is not currently supported for the selected app '${selectedApp}'.`
        );
      }
      return { ...state, userConfigEnabled, selectedApp };
    }
    default:
      return state;
  }
}
