import React from 'react';
import Fab from '@material-ui/core/Fab';
import RestoreIcon from '@material-ui/icons/Restore';

class ResetAllButton extends React.Component<any, {}> {
  render() {
    return (
      <Fab
        style={this.props.style}
        size="small"
        color="secondary"
        aria-label="ResetAll"
        onClick={this.props.onResetAll}
        title="Undo All Changes">
        <RestoreIcon />
      </Fab>
    );
  }
}

export default ResetAllButton;
