class Globals {
  constructor() {
    if (!Globals.instance) {
      this._errorMessage = null;
      Globals.instance = this;
    }
    return Globals.instance;
  }

  getErrorMessage() {
    return this._errorMessage;
  }

  setErrorMessage(errorMessage) {
    this._errorMessage = errorMessage;
  }

  clearErrorMessage() {
    this._errorMessage = null;
  }
}

const instance = new Globals();

export default instance;
