import React from 'react';
import { ImplicitCallback, SecureRoute } from '@okta/okta-react';
import { HeaderLayout } from './Layout/Header';
import { Route } from 'react-router-dom';

import SelectPage from './SelectAndEdit/SelectPage';
import EditPage from './SelectAndEdit/EditPage';
import ReviewPageContainer from './Review/ReviewPageContainer';
import LoggedOutPage from './LoggedOutPage';
import HandleAuth from './HandleAuth';
import UploadCompanyImagePage from './UploadCompanyImage';
import ConfigModal from './ConfigModal';
import ProgressPage from './ProgressPage';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TranslationConfigPage from './TranslationConfig';
import ReviewTranslationPage from './Review/ReviewTranslationPage';

export default props => {
  const DynamicRoute = window['__disableSecurity'] ? Route : SecureRoute;

  return (
    <>
      <DragDropContextProvider backend={HTML5Backend}>
        <div style={{ height: '100%', padding: '70px 15px 20px' }}>
          <DynamicRoute exact path="/" component={SelectPage} />

          <DynamicRoute
            path="/edit/:companyId/:companyName"
            component={EditPage}
            exact
          />
          <DynamicRoute
            path="/edit/:companyId/:companyName/group/:groupId/:groupName"
            component={EditPage}
          />
          <DynamicRoute
            path="/edit/:companyId/:companyName/user/:userId/:userName"
            component={EditPage}
          />
          <DynamicRoute
            path="/"
            component={window['__disableSecurity'] ? () => null : HandleAuth}
          />
          <DynamicRoute
            path="/review/:companyId/:companyName"
            component={ReviewPageContainer}
            exact
          />
          <DynamicRoute
            path="/reviewTranslation/:language"
            component={ReviewTranslationPage}
            exact
          />
          <DynamicRoute
            path="/review/:companyId/:companyName/group/:groupId/:groupName"
            component={ReviewPageContainer}
          />
          <DynamicRoute
            path="/review/:companyId/:companyName/user/:userId/:userName"
            component={ReviewPageContainer}
          />

          <Route exact path="/implicit/callback" component={ImplicitCallback} />
          <Route exact path="/logged-out" component={LoggedOutPage} />
          <Route exact path="/track-progress" component={ProgressPage} />
          <Route
            exact
            path="/upload-company-image"
            component={UploadCompanyImagePage}
          />
          <Route exact path="/translation" component={TranslationConfigPage} />
        </div>
        <HeaderLayout />
        <ConfigModal />
      </DragDropContextProvider>
    </>
  );
};
