import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { remove } from 'lodash';
import { CenteredFlex, IconButton } from '../common-styles';
import { alphaSort } from '../common';
import { ConfigRow } from '../Form/form-styles';

export class ExternalWebPageModal extends Component {
  constructor(props) {
    super(props);
    const { items, value, mode } = props;
    this.state = { items, value, mode };
  }

  removeInput(itemKey) {
    const newValue = this.state.value.slice(0);
    remove(newValue, val => val === itemKey);
    this.setState({ value: newValue });
  }

  addInput(itemKey) {
    const newValue = this.state.value.slice(0);
    newValue.push(itemKey);
    this.setState({ value: newValue });
  }

  onOk() {
    this.props.onApply(this.state.value);
  }

  render() {
    const { items, value, hovered } = this.state;
    const { sideEffectConfig, identifier } = this.props;
    const names = [];
    const nameMap = {};
    Object.keys(items).forEach(itemKey => {
      const { name } = items[itemKey];
      names.push(name);
      nameMap[name] = itemKey;
    });

    return (
      <div style={{ padding: '10px 0' }}>
        <div style={{ maxHeight: 500, overflowY: 'scroll' }}>
          {names.sort(alphaSort).map((name, index) => {
            const isModified = sideEffectConfig[identifier + nameMap[name]];
            const isChecked = value.includes(nameMap[name]);
            return (
              <ConfigRow
                key={index}
                style={{ display: 'flex' }}
                onMouseEnter={() => this.setState({ hovered: name })}
                onMouseLeave={() => this.setState({ hovered: null })}
                isModified={isModified}>
                <div style={{ flex: 1 }}>
                  <label htmlFor={name} style={{ cursor: 'pointer' }}>
                    <input
                      onChange={() =>
                        isChecked
                          ? this.removeInput(nameMap[name])
                          : this.addInput(nameMap[name])
                      }
                      checked={isChecked}
                      style={{ marginRight: 15 }}
                      id={name}
                      type="checkbox"
                    />
                    {name} {isModified && ' (edited)'}
                  </label>
                </div>
                {hovered === name &&
                  isChecked && (
                    <CenteredFlex>
                      <IconButton
                        onClick={() => this.props.onEditClicked(nameMap[name])}
                      />
                    </CenteredFlex>
                  )}
              </ConfigRow>
            );
          })}
        </div>
        <div style={{ textAlign: 'right', marginTop: 10 }}>
          <button className="apply_button btn" onClick={() => this.onOk()}>
            Apply
          </button>
        </div>
      </div>
    );
  }
}

ExternalWebPageModal.propTypes = {
  items: PropTypes.object,
  mode: PropTypes.string,
  sideEffectConfig: PropTypes.object,
  identifier: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),

  onApply: PropTypes.func,
  onEditClicked: PropTypes.func,
};

const mapStateToProps = state => {
  const { sideEffectConfig } = state.configData;
  return { sideEffectConfig };
};

export default connect(
  mapStateToProps,
  undefined
)(ExternalWebPageModal);
