import configSelection from './configSelection';
import configModal from './configModal';
import configData from './configData';
import { reducer as auth } from './auth';
import { combineReducers } from 'redux';
import uploadFile from './uploadFile';
import pipelineStatus from './pipelineStatus';
import app from './app';

export default combineReducers({
  app,
  configData,
  configSelection,
  configModal,
  auth,
  uploadFile,
  pipelineStatus,
});
