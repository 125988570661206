export const AUTH_RESULT = 'auth/AUTH_RESULT';

function getEnvs(claims) {
  try {
    /* [
      'APPS-WebAppConfig-Prod-RW-AU',
      'APPS-WebAppConfig-Prod-RW-UK',
      'APPS-WebAppConfig-Prod-RW-ZA',
      'APPS-WebAppConfig-Prod-RW-CA',
      'APPS-WebAppConfig-SP-RW-AU',
    ] */

    const availableEnvironments = configApp.configuration.environments.filter(
      env => claims.groups_apps.includes(env.readWriteGroup)
    );
    return availableEnvironments;
  } catch (e) {
    console.warn('invalid OKTA locale config');
    return [];
  }
}

export const reducer = (state = { isTechUser: false }, { type, payload }) => {
  switch (type) {
    case 'SET_ISSUER':
      return { ...state, issuer: payload.issuer, clientId: payload.clientId };
    case AUTH_RESULT:
      return {
        ...state,
        environments: getEnvs(payload.idToken.claims),
        user: {
          name: payload.idToken.claims.name,
          email: payload.idToken.claims.email,
        },
        isTechUser: (payload.idToken.claims.groups_apps || []).includes(
          'APPS-TechUser-APAC'
        ),
      };
    default:
      return state;
  }
};

export const actions = {
  setIssuer: payload => ({ type: 'SET_ISSUER', payload }),
  authResult: payload => ({ type: AUTH_RESULT, payload }),
};
