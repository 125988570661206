import React, { Component, Fragment } from 'react';
import Form from 'react-jsonschema-form';

export default class IOSPlusOrderFillDestinationModal extends Component<
  any,
  any
> {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        serverName: '',
        destination: '',
        ...this.props.formData,
      },
    };
  }

  onFormDataChanged = ({ formData }) => {
    this.setState({ formData });
  };

  onSubmit({ formData }) {
    this.props.onApply(formData);
  }

  schema = {
    properties: {
      serverName: {
        title: 'Server Name',
        type: 'string',
      },
      destination: {
        title: 'Order Fill Destination',
        type: 'string',
      },
    },
    required: ['serverName', 'destination'],
    type: 'object',
  } as any;

  render() {
    return (
      <Form
        schema={this.schema}
        formData={this.state.formData}
        onChange={this.onFormDataChanged.bind(this)}
        onSubmit={this.onSubmit.bind(this)}>
        <div style={{ display: 'flex' }}>
          <button className="btn btn-primary pull-right" type="submit">
            {this.props.mode}
          </button>
        </div>
      </Form>
    );
  }
}
