import { connect } from 'react-redux';

import schemaSelector from '../../_selectors/schemaSelector';
import ReviewSettingsTableComponent from './ReviewSettingsTableComponent';

const mapStateToProps = state => {
  const {
    defaultValues,
    deletedConfig,
    configSchema,
    modifiedConfig,
  } = state.configData;
  return {
    ...schemaSelector(state),
    defaultValues,
    configSchema,
    deletedConfig,
    modifiedConfig,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(ReviewSettingsTableComponent);
