import React, { Component } from 'react';
import { ConfigRow } from '../Form/form-styles';
import ChipArray from '../Form/ArrayWithoutChips';

import Chips from '../Form/Chips';
import Chip from '../Form/Chip';

function handleSideEffectChanged(
  rootConfigKey,
  newSideEffectKey,
  oldSideEffectKey,
  newConfigValue,
  onSideEffectConfigChanged
) {
  const newSideEffectConfig = {
    [newSideEffectKey]: newConfigValue,
  };
  if (newSideEffectKey !== oldSideEffectKey) {
    newSideEffectConfig[oldSideEffectKey] = undefined;
  }
  onSideEffectConfigChanged({
    configId: rootConfigKey,
    sideEffectConfig: newSideEffectConfig,
  });
}

export default class TemplateConfigSection extends Component<any, {}> {
  onEditLayoutTemplate(title) {
    event.preventDefault();
    const {
      onConfigChanged,
      onHideConfigModal,
      onSideEffectConfigChanged,
      onShowConfigModal,
      mergedConfig,
      propKey,
    } = this.props;
    let layoutTemplate = mergedConfig[this.getLayoutTemplateKey(title)];

    onShowConfigModal({
      modalType: 'Template',
      modalProps: {
        mode: 'Edit',
        formData: {
          title: layoutTemplate.title,
          data: JSON.stringify(layoutTemplate.data),
          groupId: layoutTemplate.groupId,
        },
        onApply: newFormData => {
          const newKeySuffix = `standard/${newFormData.title.replace(
            /\s/g,
            ''
          )}`;
          const newLayoutTemplateKey = this.getLayoutTemplateKey(newKeySuffix);
          const oldLayoutTemplateKey = this.getLayoutTemplateKey(title);
          handleSideEffectChanged(
            'Default/layoutTemplates/',
            newLayoutTemplateKey,
            oldLayoutTemplateKey,
            {
              title: newFormData.title,
              data: JSON.parse(newFormData.data),
              groupId: newFormData.groupId,
            },
            onSideEffectConfigChanged
          );

          const newLayoutTemplate = [...mergedConfig[propKey]];
          const oldIndex = newLayoutTemplate.findIndex(
            value => value === title
          );
          if (oldIndex >= 0) {
            newLayoutTemplate[oldIndex] = newKeySuffix;
          }
          onConfigChanged({
            [propKey]: newLayoutTemplate,
          });
          onHideConfigModal();
        },
      },
    });
  }

  handleDelete(data) {
    const {
      mergedConfig,
      propKey,
      onConfigChanged,
      onSideEffectConfigChanged,
    } = this.props;
    const availableLayoutTemplates = mergedConfig[propKey];
    const newAvailableLayoutTemplates = Object.values(
      availableLayoutTemplates
    ).filter(key => key !== data);

    const templateDataKey = this.getLayoutTemplateKey(data);

    onSideEffectConfigChanged({
      configId: propKey,
      sideEffectConfig: { [templateDataKey]: undefined },
    });

    onConfigChanged({ [propKey]: newAvailableLayoutTemplates });
  }

  getLayoutTemplateKey(id: string) {
    return `Default/layoutTemplates/${id}`;
  }

  createStandardLayoutTemplateKey(title: string) {
    return `Default/layoutTemplates/standard/${title.replace(/\s/g, '')}`;
  }

  onAddTemplateConfig = event => {
    event.preventDefault();
    const {
      onConfigChanged,
      onHideConfigModal,
      onSideEffectConfigChanged,
      onShowConfigModal,
      mergedConfig,
      propKey,
    } = this.props;
    onShowConfigModal({
      modalType: 'Template',
      modalProps: {
        mode: 'Add',
        onApply: newFormData => {
          const newKey = `standard/${newFormData.title.replace(/\s/g, '')}`;
          const layoutTemplateKey = this.getLayoutTemplateKey(newKey);
          onSideEffectConfigChanged({
            configId: propKey,
            sideEffectConfig: {
              [layoutTemplateKey]: {
                title: newFormData.title,
                data: JSON.parse(newFormData.data),
                groupId: newFormData.groupId,
              },
            },
          });
          const newLayoutTemplate = [...mergedConfig[propKey]];
          newLayoutTemplate.push(newKey);
          onConfigChanged({
            [propKey]: newLayoutTemplate,
          });
          onHideConfigModal();
        },
      },
    });
  };

  customChip = (data, index) => {
    return (
      <Chip
        data={data}
        index={index}
        handleDelete={this.handleDelete.bind(this, data)}
        onClick={this.onEditLayoutTemplate.bind(this, data)}
      />
    );
  };

  render() {
    const { mergedConfig, properties, propKey } = this.props;

    const companyTemplates = Object.keys(mergedConfig)
      .filter(key => key.match(/Default\/layoutTemplates\/standard\//i))
      .map(key => key.replace(/Default\/layoutTemplates\//i, ''));

    return (
      <div>
        <ConfigRow style={{ padding: 20 }}>
          <div>
            <Chips
              customChip={this.customChip}
              list={mergedConfig[propKey] || []}
              reorderable={false}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ChipArray
              propKey={propKey}
              collection={companyTemplates}
              mergedConfig={mergedConfig}
              onConfigChanged={this.props.onConfigChanged}
              properties={properties}
            />
            <button
              className="btn btn-primary"
              style={{ marginTop: 5, zIndex: 1 }}
              onClick={this.onAddTemplateConfig}>
              Add New Template
            </button>
          </div>
        </ConfigRow>
      </div>
    );
  }
}
