import React, { Component } from 'react';
import { camelCase, upperFirst, filter } from 'lodash';
import { ConfigRow } from '../Form/form-styles';

import Chips from '../Form/Chips';
import Chip from '../Form/Chip';

function handleSideEffectChanged(
  rootConfigKey,
  newSideEffectKey,
  oldSideEffectKey,
  newConfigValue,
  onSideEffectConfigChanged
) {
  const newSideEffectConfig = {
    [newSideEffectKey]: newConfigValue,
  };
  if (newSideEffectKey !== oldSideEffectKey) {
    newSideEffectConfig[oldSideEffectKey] = undefined;
  }
  onSideEffectConfigChanged({
    configId: rootConfigKey,
    sideEffectConfig: newSideEffectConfig,
  });
}

export default class IOSPlusFilterRow extends Component<any, {}> {
  onEditFilterConfig(name) {
    event.preventDefault();
    const {
      onConfigChanged,
      onHideConfigModal,
      onSideEffectConfigChanged,
      onShowConfigModal,
      mergedConfig,
      propKey,
    } = this.props;

    let filterConfig = mergedConfig[this.getFilterKey(propKey, name)];
    onShowConfigModal({
      modalType: 'IOSPlus Filter',
      modalProps: {
        mode: 'Edit',
        formData: {
          name,
          filterConfig: JSON.stringify(filterConfig),
        },
        onApply: newFormData => {
          const newKeySuffix = upperFirst(camelCase(newFormData.name));
          const oldKeySuffix = name;
          const newFilterDataKey = this.getFilterKey(propKey, newKeySuffix);
          const oldFilterDataKey = this.getFilterKey(propKey, oldKeySuffix);

          handleSideEffectChanged(
            propKey,
            newFilterDataKey,
            oldFilterDataKey,
            JSON.parse(newFormData.filterConfig),
            onSideEffectConfigChanged
          );

          const newFilterConfig = [...mergedConfig[propKey]];
          const oldIndex = newFilterConfig.findIndex(
            value => value === oldKeySuffix
          );
          if (oldIndex >= 0) {
            newFilterConfig[oldIndex] = newKeySuffix;
          }
          onConfigChanged({ [propKey]: newFilterConfig });
          onHideConfigModal();
        },
      },
    });
  }

  handleDelete(data) {
    const {
      mergedConfig,
      propKey,
      onConfigChanged,
      onSideEffectConfigChanged,
    } = this.props;
    const availableFilterSets = mergedConfig[propKey];
    const newAvailableFilterSets = filter(
      availableFilterSets,
      name => name !== data
    );
    const filterDataKey = this.getFilterKey(propKey, data);

    onSideEffectConfigChanged({
      configId: propKey,
      sideEffectConfig: { [filterDataKey]: undefined },
    });
    onConfigChanged({ [propKey]: newAvailableFilterSets });
  }

  getFilterKey(propKey, id) {
    const regexFilterType = /IOSPlus\/Filters\/Available(.*)FiltersSet/;
    let filterType = propKey.match(regexFilterType)[1];

    if (filterType !== undefined) {
      return `IOSPlus/Filters/${filterType}Filters/${id}`;
    } else {
      return `IOSPlus/Filters/OrderFilters/${id}`;
    }
  }

  onAddIosFilter = event => {
    event.preventDefault();
    const {
      onConfigChanged,
      onHideConfigModal,
      onSideEffectConfigChanged,
      onShowConfigModal,
      mergedConfig,
      propKey,
    } = this.props;

    onShowConfigModal({
      modalType: 'IOSPlus Filter',
      modalProps: {
        mode: 'Add',
        onApply: newFormData => {
          const newId = upperFirst(camelCase(newFormData.name));
          const filterDataKey = this.getFilterKey(propKey, newId);
          onSideEffectConfigChanged({
            configId: propKey,
            sideEffectConfig: {
              [filterDataKey]: JSON.parse(newFormData.filterConfig),
            },
          });
          const newFilterConfig = [...mergedConfig[propKey]];
          newFilterConfig.push(newId);
          onConfigChanged({ [propKey]: newFilterConfig });
          onHideConfigModal();
        },
      },
    });
  };

  customChip = (data, index) => {
    return (
      <Chip
        data={data}
        index={index}
        handleDelete={this.handleDelete.bind(this, data)}
        onClick={this.onEditFilterConfig.bind(this, data)}
      />
    );
  };

  render() {
    const { mergedConfig, properties, title, propKey } = this.props;
    return (
      <ConfigRow style={{ padding: 20 }}>
        <div>
          <Chips
            customChip={this.customChip}
            list={mergedConfig[propKey] || []}
            reorderable={!!properties[propKey].reorderable}
          />
        </div>
        <div style={{ textAlign: 'right' }}>
          <button
            className="btn btn-primary"
            style={{ marginTop: 5 }}
            onClick={this.onAddIosFilter}>
            Add IOSPlus Filter
          </button>
        </div>
      </ConfigRow>
    );
  }
}
