import React from 'react';
import Chip from '@material-ui/core/Chip';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = theme =>
  createStyles({
    chip: {
      height: 25,
      fontSize: 12,
      margin: 2,
    },
    modified: {
      backgroundColor: '#ffeaa7',
    },
    added: {
      backgroundColor: '#A3CB38',
    },
  });

const ChipComponent = function(props) {
  const { data, index, mode } = props;
  let classes = `${props.classes.chip}`;
  if (props.classes[mode]) {
    classes += ` ${props.classes[mode]}`;
  }
  return (
    <Chip
      variant="outlined"
      className={classes}
      key={index}
      label={data}
      onDelete={
        props.handleDelete
          ? event => props.handleDelete && props.handleDelete(index)
          : undefined
      }
      onClick={event => props.onClick && props.onClick(event, data)}
    />
  );
};

export default withStyles(styles)(ChipComponent);
